import { ADD_LOYALTY_CARD_TO_WALLET_MUTATION } from "../../../graphql/wallet";
import { client } from "../../utilities/config";

export const addLoyaltyCardToWallet = async (platform) => {
    const promise = client.mutate({
        mutation: ADD_LOYALTY_CARD_TO_WALLET_MUTATION,
        variables: {
            platform
        }
    });

    return promise;
};