/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./Input.module.css";
import GlassSVG from "./imgs/glass.svg";
import clearSearchSVG from "./imgs/clearSearch.svg";
import { connect } from "react-redux";
import captchaAction from "../../shared/constants/captchaAction";
import * as elementsActions from "../../redux/actions/elements";
import * as locationsRepo from "../../shared/repos/graphql/locations";
import _ from "underscore";
import { setCaptchaToken } from "../../shared/utilities/captcha";
import httpStatusConstant from "../../shared/constants/httpStatus";
import Loader from "../Loader/Loader";
import elementConstants from "../../shared/constants/element";
import ReCAPTCHAForm from "../ReCaptcha/ReCaptcha";

class LocationSearchInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchAddressString: "",
      locationSearchError: null,
      hasCaptchaError: false,
      showCaptchaChallenge: false,
      skipGenerateNewToken: false,
      isLoading: false,
    };

    this.recaptchaComponentRef = React.createRef();
  }

  componentDidMount() {
    const { lat, lng } = this.props;
    if (lat !== null && lng !== null) {
      this.handleSearch('', lat, lng, false);
    }
  }

  onClearSearch = () => {
    const { onChange } = this.props;
    this.setState({
      searchAddressString: "",
    });
    onChange([]);
  }

  onAddressSearch = (e) => {
    const { value } = e.target;
    this.setState({
      searchAddressString: value,
    });
    this.handleSearch(value, null, null, true);
  }

  onVerifyCaptcha = (token) => {
    if (token) {
      const { lat, lng } = this.props;
      this.setState({
        showCaptchaChallenge: false,
        skipGenerateNewToken: true
      });

      const { setVerificationToken, setVerificationProvider } = this.props;
      const { searchAddressString } = this.state;
      setVerificationToken(token);
      setVerificationProvider(elementConstants.challengeRecaptcha);

      this.handleSearch(searchAddressString, lat, lng, true);
    }
  }

  renderCaptchaChallenge = () => {
    const { showCaptchaChallenge } = this.state;
    const { useRecaptcha } = this.props;
    return (
      (showCaptchaChallenge && useRecaptcha) ? (
        <div className={styles.errorContainer}>
          <ReCAPTCHAForm
            ref={this.recaptchaComponentRef}
            setToken={this.onVerifyCaptcha}
          />
        </div>
      ) : <div className="testting" />
    )
  };

  handleSearch = _.debounce(async (value, lat = null, lng = null, isShowDorms = true) => {
    const { useRecaptcha, setVerificationToken, setVerificationAction, setVerificationProvider, onChange } = this.props;
    const { skipGenerateNewToken } = this.state;
    const isSmallSearchString = lat === null && lng === null && value.length < 3;
    if (isSmallSearchString) {
      return;
    }
    if (useRecaptcha === 1) {
      await setCaptchaToken(setVerificationToken, setVerificationAction, captchaAction.addressSearching, skipGenerateNewToken);
    }

    try {
      this.setState({
        isLoading: true,
      });
      const result = await locationsRepo.getLocations(
        value,
        lat,
        lng,
        true,
        isShowDorms,
        null,
      );
      onChange(result.data.locationSearch);
      setVerificationProvider('');
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      this.setState({
        locationSearchError: e.message,
      });
      const error = e.message;
      const hasUnauthorized = (error) ? error.indexOf(httpStatusConstant.unauthorised) !== -1 : false;
      if (e.message) {
        this.setState({
          hasCaptchaError: hasUnauthorized,
          showCaptchaChallenge: hasUnauthorized,
          isLoading: false,
        });
      } else {
        this.setState({
          showCaptchaChallenge: false,
          hasCaptchaError: false,
          skipGenerateNewToken: false,
          isLoading: false,
        });
      }
    }
  }, 500);

  render() {
    const { className, placeholder } = this.props;
    const { searchAddressString, isLoading, hasCaptchaError } = this.state;
    return (
      <div className={className}>
        <div className={[styles.searchInputBlock].join(' ')}>
          { isLoading ? (<Loader className={styles.searchIcon} height={20} />) : (<img className={styles.searchIcon} src={GlassSVG} alt="" />) }
          <input type="text" placeholder={placeholder} value={searchAddressString} onChange={this.onAddressSearch} />
          { searchAddressString ? (<img className={styles.clearSearch} onClick={this.onClearSearch} src={clearSearchSVG} alt="X" />) : null }
        </div>
        { hasCaptchaError ? this.renderCaptchaChallenge() : null }
      </div>
    );
  }
}

LocationSearchInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  lng: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  placeholder: PropTypes.string,
  useRecaptcha: PropTypes.number,
  setVerificationToken: PropTypes.func,
  setVerificationAction: PropTypes.func,
  setVerificationProvider: PropTypes.func,
};

LocationSearchInput.defaultProps = {
  className: "",
  lat: null,
  lng: null,
  placeholder: "Search for an address",
  onChange: () => {},
  useRecaptcha: 0,
  setVerificationToken: () => {},
  setVerificationAction: () => {},
  setVerificationProvider: () => {},
};

export const mapStateToProps = state => {
  const { useRecaptcha } = state.elements;
  return {
    useRecaptcha
  };
};

export const mapDispatchToProps = dispatch => ({
  setVerificationToken: value =>
    dispatch(elementsActions.setVerificationToken(value)),
  setVerificationAction: value =>
    dispatch(elementsActions.setVerificationAction(value)),
  setVerificationProvider: value =>
    dispatch(elementsActions.setVerificationProvider(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationSearchInput);
