export const showBigOrderWarning = () => ({
  type: 'SHOW_BIG_ORDER_WARNING'
});

export const hideBigOrderWarning = () => ({
  type: 'HIDE_BIG_ORDER_WARNING'
});

export const setPreviousBigOrderWarningId = value => ({
  type: 'SET_PREVIOUS_BIG_ORDER_WARNING_ID',
  value
});
