/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./LocationLabelSelector.module.css";
import { getLocationLabels } from "../../shared/repos/graphql/user";
import _ from "underscore";
import Loader from "../Loader/Loader";

class LocationLabelSelector extends Component {

  constructor(props) {
    super(props);
    this.state = {
      label: '',
      id: '',
      customerLabels: [],
      isCustomerLabelsVisible: false,
      isProcessing: false,
    };
  }

  componentDidMount() {
    const { value } = this.props;
    document.addEventListener('click', this.handleClickOutside);
    if (value) {
      this.setState({
        label: value.label,
        id: value.id,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = () => {
    this.setState({
      isCustomerLabelsVisible: false,
    });
  }

  tryToFoundLabel = (label, customerLabels) => {
    const found = customerLabels.find((customerLabel) => {
      return customerLabel.label && label && customerLabel.label.toLowerCase().trim() === label.toLowerCase().trim();
    });
    return found ? found : null;
  }

  locationLabels = _.debounce(async (label) => {
    const { onChange } = this.props;
    let results = {data: {me: {locationLabels: []}}};
    if (label) {
      results = await getLocationLabels(true, true, label, 5);
    }
    const { data } = results;
    const cl = data.me.locationLabels;
    const founded = this.tryToFoundLabel(label, cl);
    const labelResultData = {
      label: founded ? founded.label : label,
      id: founded ? founded.id : null,
    };
    this.setState({
      customerLabels: cl,
      isCustomerLabelsVisible: true,
      isProcessing: false,
      id: founded ? founded.id : null,
    });
    onChange(label ? labelResultData : null);
  }, 500);

  onInputChange = async (e) => {
    const { value } = e.target;
    this.setState({
      label: value,
      isProcessing: true,
    });
    this.locationLabels(value);
  }

  onSelectCustomerLabel = (label) => {
    const { onChange } = this.props;
    this.setState({
      label: label.label,
      id: label.id,
      isCustomerLabelsVisible: false,
    });
    onChange(label);
  }

  renderCustomerLabels() {
    const { customerLabels, id } = this.state;
    return (
      <div className={styles.labelsContainer}>
        { customerLabels.map((label) => (
          <div key={label.id} className={[styles.labelItem, 'dark:text-dark', id === label.id ? styles.current : null].join(' ')} onClick={() => this.onSelectCustomerLabel(label)}>
            {label.label}
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { className, inputClassName, placeholder } = this.props;
    const { customerLabels, isCustomerLabelsVisible, isProcessing } = this.state;
    const { label } = this.state;
    const isCustomerLabelsAvailable = isCustomerLabelsVisible && customerLabels.length > 0;
    return (
      <div className={[styles.labelSelectorContainer, className].join(' ')}>
        <input type="text" placeholder={placeholder} className={[inputClassName].join(' ')} value={label} onChange={this.onInputChange} />
        { isProcessing ? (<Loader height={20} className={styles.loader} />) : null }
        { isCustomerLabelsAvailable ? this.renderCustomerLabels() : null }
      </div>
    );
  }
}

LocationLabelSelector.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }), PropTypes.oneOf([null])]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

LocationLabelSelector.defaultProps = {
  className: "",
  inputClassName: "",
  value: null,
  onChange: () => {},
  placeholder: "",
};

export default LocationLabelSelector;
