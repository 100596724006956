import gql from 'graphql-tag';

export const ADD_LOYALTY_CARD_TO_WALLET_MUTATION = gql`
  mutation ADD_LOYALTY_CARD_TO_WALLET_MUTATION(
    $platform: String
  ) {
    addLoyaltyCardToWallet(platform: $platform) {
      success
      responseType
      message
      responseData
    }
  }
`;