import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  propTypeStorePaymentMethod,
  propTypeCustomerSavedCards,
  propTypesCmsContent
} from '../shared.propTypes';
import PaymentMethodGroupExpander from '../PaymentMethodGroupExpander/PaymentMethodGroupExpander';
import Loader from '../../Loader/Loader';
import styles from '../shared.module.css';
import { PaymentInputs } from '../PaymentInputs';
import { keyDownA11y, uiComponentTypes, paymentMethodIds } from '../shared';
import log from '../../../shared/utilities/datadog/log';

const getPaymentSummary = (paymentCredentialSubmitted, storePaymentMethodSelected, orderTotal) => {
  if(orderTotal === 0){
      return '';
  }
  if (!paymentCredentialSubmitted || (paymentCredentialSubmitted.storePaymentMethod.id !== storePaymentMethodSelected.id)) {
    return 'Payment Information Required';
  }
  return paymentCredentialSubmitted.summary || '';
};

const PaymentMethodGroup = ({
  paymentMethodComponentGroup: group,
  isSavePaymentMethodCheckedAndDisabled,
  isSavePaymentMethodAllowed,
  paymentMethodsCustomerSaved,
  updatePaymentCredentials,
  isDoughProMember,
  isLoggedIn,
  cmsContent,
  updateEmbeddedPaymentMethod,
  orderTotal,
  defaultPaymentCredential
}) => {
  const isSingleItem =
    group.storePaymentMethods.length === 1 &&
    group.storePaymentMethods[0].paymentMethodId === paymentMethodIds.giftCard;
  const [storePaymentMethodSelected, setStorePaymentMethodSelected] = useState(
    isSingleItem ? group.storePaymentMethods[0] : null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [showPaymentErrors, setShowPaymentErrors] = useState([]);

  const views = {
    showingSelector: 'showingSelector',
    detailView: 'detailView'
  };

  const [view, setView] = useState(isSingleItem ? views.detailView : views.showingSelector); // collapsed, expanded
  const [paymentCredentialSubmitted, setPaymentCredentialSubmitted] = useState(null);

  const displayError = (errors) => {
    if (Array.isArray(errors)) {
      setShowPaymentErrors(errors);
      setIsExpanded(true);
      setTimeout(() => {
        setShowPaymentErrors([]);
      }, 10000)
    }
  }

  const onSubmitPaymentCredentials = pcSubmitted => {

    log.logInfo(
      "[PaymentMethodGroup][onSubmitPaymentCredentials]",
      {
        "pcSubmitted": pcSubmitted,
      }
    );
    const later = delay => {
      return new Promise(resolve => {
        setTimeout(resolve, delay);
      });
    };

    let updateCredentialPromise = later(2000);
    if (updatePaymentCredentials) {
      updateCredentialPromise = updatePaymentCredentials(pcSubmitted.paymentCredential);
    }
    setStorePaymentMethodSelected(pcSubmitted.storePaymentMethod);
    updateCredentialPromise.then(() => {
      setPaymentCredentialSubmitted(pcSubmitted);

      setView(isSingleItem ? views.detailView : views.showingSelector);
      setIsExpanded(false);
      setIsLoading(false);
    });

    setIsLoading(true);
  };

  const getOnClickSubmitPaymentCredentials = pmSubmitted => () => {
    setTimeout(() => {
      onSubmitPaymentCredentials(pmSubmitted);
      updateEmbeddedPaymentMethod(pmSubmitted);
    }, 100);
  };

  const onCancel = pmSubmitted => {
    setView(isSingleItem ? views.detailView : views.showingSelector);
    if (pmSubmitted) {
      setStorePaymentMethodSelected(pmSubmitted.storePaymentMethod);
      updateEmbeddedPaymentMethod(pmSubmitted);
    } else {
      setStorePaymentMethodSelected(null);
      updateEmbeddedPaymentMethod(null);
    }
  };

  const getOnClickEdit = x => () => {
    updateEmbeddedPaymentMethod(x);
    setStorePaymentMethodSelected(x);
    if (x?.uiComponentType === uiComponentTypes.detailView) {
      setView(views.detailView);
    } else {
      setView(views.showingSelector);
    }
  };

  useEffect(() => {
    // Exit early if payment credentials are already submitted
    if (paymentCredentialSubmitted) {
      if (!isExpanded) {
        setView(isSingleItem ? views.detailView : views.showingSelector);
      }
      return;
    }
  
    // Handle default payment method logic
    if (defaultPaymentCredential) {
      const matchedPaymentMethod = group.storePaymentMethods.find(
        (method) => Number(method.paymentMethodId) === Number(defaultPaymentCredential.paymentMethodId)
      );
  
      if (matchedPaymentMethod) {  
        // Prepare the payment credentials object
        const paymentCredential = {
          storePaymentMethod: matchedPaymentMethod,
          summary: defaultPaymentCredential.summary? defaultPaymentCredential.summary 
            : matchedPaymentMethod.paymentMethod.title,
          paymentCredential: defaultPaymentCredential,
        };
  
        // Call the payment credential submission handler
        getOnClickSubmitPaymentCredentials(paymentCredential)();
      }
    }
  
    // Default fallback view if no payment method is selected
    if (!isExpanded) {
      setView(isSingleItem ? views.detailView : views.showingSelector);
    }
  }, [
    isExpanded,
    paymentCredentialSubmitted,
    isSingleItem,
    defaultPaymentCredential,
    group.storePaymentMethods,
  ]);
  
  
  const isDetailViewWithErrors = storePaymentMethodSelected?.uiComponentType === uiComponentTypes.detailView && showPaymentErrors.length > 0;
  return (
    <>
      <PaymentMethodGroupExpander
        isAlert={(!paymentCredentialSubmitted || (paymentCredentialSubmitted.storePaymentMethod.id !== storePaymentMethodSelected.id)) && orderTotal>0}
        header={
          storePaymentMethodSelected
            ? storePaymentMethodSelected.paymentMethod.title
            : group.groupTitle
        }
        subHeader={getPaymentSummary(paymentCredentialSubmitted, storePaymentMethodSelected, orderTotal)}
        subHeaderAsset={storePaymentMethodSelected ? storePaymentMethodSelected.iconUrlPath : null}
        isExpanded={isSingleItem || isExpanded}
        setIsExpanded={setIsExpanded}
        isSingleItem={isSingleItem}
      >
        {isLoading ? (
          <div className={styles.paymentsLoaderWrapper}>
            <Loader />
          </div>
        ) : null}
        <div>
          {isDetailViewWithErrors && showPaymentErrors.map((error) => (
            <p key={error} className={styles.errorMessage}>
              {error}
            </p>
          ))}
        </div>
        {!isSingleItem && (
          <div hidden={view === views.detailView}>
            <div className={styles.optionsContainer2}>
              {group.storePaymentMethods.map(x => {
                const isChosen =
                  storePaymentMethodSelected &&
                  // eslint-disable-next-line eqeqeq
                  x.paymentMethodId == storePaymentMethodSelected.paymentMethodId; // todo add optionSelected check
                const urlImg = x.iconUrlPath;
                if (x.uiComponentType === uiComponentTypes.button) {
                  //   Display payment input as a BUTTON (for example, Cash payment method)
                  const pmSubmitted = {
                    storePaymentMethod: x,
                    summary: x.paymentMethod.title,
                    paymentCredential: {
                      paymentMethodId: x.paymentMethodId,
                      credential: null,
                      securityCode: null,
                      saveCredentials: false
                    }
                  };
                  //   Display a BUTTON that will send user to the detailed view for futher input
                  return (
                    <div
                      key={x.paymentMethodId}
                      id={`payment-method-edit-${x.paymentMethodId}`}
                      onClick={getOnClickSubmitPaymentCredentials(pmSubmitted)}
                      onKeyDown={keyDownA11y(getOnClickSubmitPaymentCredentials(pmSubmitted))}
                      role="button"
                      tabIndex={0}
                      style={x.isEmbeddedHtml ? { justifyContent: 'center' } : null}
                      className={[
                        styles.option,
                        isChosen ? `${styles.chosen} bg-darkDry dark:bg-dryPink text-white` : null,
                        `uiComponentType${uiComponentTypes.button}`,
                        "h-11 border-[1px] rounded-[40px] border-lightDry",
                      ].join(' ')}
                    >
                      {!urlImg ? null : <img alt={x.paymentMethod.title} src={urlImg} />}
                      {
                        !x.isEmbeddedHtml ? (
                          <label htmlFor={`payment-method-edit-${x.paymentMethodId}`}>
                            {`${x.paymentMethod.title}`}
                            {' '}
                            {isChosen ? <span className={styles.edit}>Edit</span> : null}
                            {' '}
                          </label>
                      )
                        : null
                      }
                    </div>
                  );
                }
                return (
                  <div
                    key={x.paymentMethodId}
                    id={`payment-method-edit-${x.paymentMethodId}`}
                    onClick={getOnClickEdit(x)}
                    onKeyDown={getOnClickEdit(x)}
                    role="button"
                    tabIndex={0}
                    className={[
                      styles.option,
                      isChosen ? `${styles.chosen} bg-darkDry dark:bg-dryPink text-white` : null,
                      `uiComponentType${uiComponentTypes.detailView}`,
                      "h-11 border-[1px] rounded-[40px] border-lightDry"
                    ].join(' ')}
                  >
                    {!urlImg ? null : <img alt={x.paymentMethod.title} src={urlImg} />}
                    <label htmlFor={`payment-method-edit-${x.paymentMethodId}`}>
                      {`${x.paymentMethod.title}`}
                      {' '}
                      {isChosen ? <span className={styles.edit}>Edit</span> : null}
                      {' '}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {isSingleItem? (
          <p className={`${styles.paymentsSubTitle} text-dark dark:text-white font-filsonProBold`}>{group.groupTitle}</p>
        ) : null}

        <PaymentInputs
          storePaymentMethods={group.storePaymentMethods.filter(
            x => x.uiComponentType === uiComponentTypes.detailView
          )}
          storePaymentMethodSelected={storePaymentMethodSelected}
          paymentCredentialSubmitted={paymentCredentialSubmitted}
          hidden={view === views.showingSelector}
          onSubmitPaymentCredentials={onSubmitPaymentCredentials}
          isSavePaymentMethodCheckedAndDisabled={isSavePaymentMethodCheckedAndDisabled}
          isSavePaymentMethodAllowed={isSavePaymentMethodAllowed}
          paymentMethodsCustomerSaved={paymentMethodsCustomerSaved}
          isDoughProMember={isDoughProMember}
          isLoggedIn={isLoggedIn}
          cmsContent={cmsContent}
          onCancel={() => {
            onCancel(paymentCredentialSubmitted);
          }}
          displayError={displayError}
        />
      </PaymentMethodGroupExpander>
    </>
  );
};

PaymentMethodGroup.propTypes = {
  paymentMethodComponentGroup: PropTypes.shape({
    groupTitle: PropTypes.string,
    storePaymentMethods: PropTypes.arrayOf(PropTypes.shape(propTypeStorePaymentMethod))
  }).isRequired,
  updatePaymentCredentials: PropTypes.func.isRequired,
  isSavePaymentMethodCheckedAndDisabled: PropTypes.bool,
  isSavePaymentMethodAllowed: PropTypes.bool,
  paymentMethodsCustomerSaved: propTypeCustomerSavedCards,
  isDoughProMember: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  cmsContent: propTypesCmsContent,
  updateEmbeddedPaymentMethod: PropTypes.func.isRequired,
  orderTotal: PropTypes.number.isRequired,
  defaultPaymentCredential: PropTypes.oneOfType([
    PropTypes.shape({
      saveCredentials: PropTypes.bool,
      paymentMethodId: PropTypes.number.isRequired,
      credential: PropTypes.string,
      securityCode: PropTypes.string,
      paymentProcessorId: PropTypes.number,
      sessionKey: PropTypes.string,
      summary: PropTypes.string,
    }),
    PropTypes.oneOf([null]),
  ]),
};

PaymentMethodGroup.defaultProps = {
  isSavePaymentMethodCheckedAndDisabled: false,
  isSavePaymentMethodAllowed: false,
  paymentMethodsCustomerSaved: [],
  isDoughProMember: false,
  isLoggedIn: false,
  cmsContent: [],
  defaultPaymentCredential: null,
};

export default PaymentMethodGroup;
