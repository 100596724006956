/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TagSelector.module.css';

class TagSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
    }
  }

  componentDidMount() {
    const { selected } = this.props;
    this.setState({
      selected,
    });
  }

  componentDidUpdate(prevProps) {
    const { selected } = this.props;
    if (prevProps.selected.sort((a, b) => a - b) !== selected.sort((a, b) => a - b)) {
      this.setState({
        selected,
      });
    }
  }

  toggleMultipleMetaTag = (id) => {
    const { selected } = this.state;
    const { onChange } = this.props;
    const index = selected.indexOf(id);
    if (index === -1) {
      selected.push(id);
    } else {
      selected.splice(index, 1);
    }
    this.setState({
      selected,
    });
    onChange(selected);
  }

  toggleSingleMetaTag = (id) => {
    const { selected } = this.state;
    const { onChange } = this.props;
    const index = selected.indexOf(id);
    const result = [];
    if (index === -1) {
      result.push(id);
    }
    this.setState({
      selected: result,
    });
    onChange(result);
  }

  toggleMetatag = (metatag) => {
    const { isMultiple } = this.props;
    if (isMultiple) {
      this.toggleMultipleMetaTag(+metatag.id);
    } else {
      this.toggleSingleMetaTag(+metatag.id);
    }
  }

  renderMetatags = (metatags) => {
    const { selected } = this.state;

    return metatags.map((metatag) => {
      const isSelected = selected.indexOf(+metatag.id) !== -1 ? 'selected' : ''
      return (
        <div role="button" key={metatag.id} onClick={() => {this.toggleMetatag(metatag)}} className={[
          styles.tagBlock,
          'tagBlock text-dark dark:text-white',
          isSelected,
        ].join(' ')}>
          { metatag.title }
        </div>
      );
    });
  }


  render() {
    const { className, metatags } = this.props;
    return metatags.length > 0 ? (
      <div className={[styles.tagSelectorContainer, className, 'tagSelectorContainer'].join(' ')}>
        { this.renderMetatags(metatags) }
      </div>
    ) : null;
  }
}

TagSelector.propTypes = {
  metatags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    title: PropTypes.string,
  })),
  selected: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.arrayOf(PropTypes.string), PropTypes.arrayOf(PropTypes.bool)]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  isMultiple: PropTypes.bool,
};

TagSelector.defaultProps = {
  selected: [],
  onChange: () => {},
  className: '',
  metatags: [],
  isMultiple: true,
};

export default TagSelector;
