import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// REDUX
import { connect } from "react-redux";

// ROUTER
import { withRouter } from 'react-router-dom';

import EasyMDE from "easymde";
import styles from './CmsContentEditModal.module.css';
import * as elementsActions from "../../redux/actions/elements";
import { updateCmsData, uploadCmsFile, createCmsData, deleteCmsData } from "../../shared/repos/graphql/cms";
import Input from "../Input/Input";

export async function uploadImage(file, onSuccess, onError) {
  const result = await uploadCmsFile({
    data: {
      file,
    }
  });

  if (!result.data.uploadCmsFile || result.data.uploadCmsFile.indexOf('http') === -1) {
    onError(result.data.uploadCmsFile);

    return;
  }

  onSuccess(result.data.uploadCmsFile);
}

function CmsContentEditModal({ cmsNode, showChilds, showContent, setModalObject, onUpdate, showParent, createNode, onCreateNode, applyMarkdownOnEdit }) {
  const [updating, setUpdating] = useState();
  const [editor, setEditor] = useState(null);
  const [childNode, setChildNode] = useState(null);
  const [newNode, setNewNode] = useState(null);
  const [cmsKeyField, setCmsKeyField] = useState(cmsNode.key);
  const [deleting, setDeleting] = useState(null);
  const isCreateScreen = createNode || cmsNode.cmsKeyId === -1;

  useEffect(() => {
    const editorEl = document.getElementById('cms-edit-content');

    const instance = new EasyMDE({
      element: editorEl,
      minHeight: '180px',
      uploadImage: true,
      imageMaxSize: (1024 * 1024), // 1 MB
      toolbar: [
        'bold',
        'italic',
        'heading',
        'heading-3',
        'link',
        'unordered-list',
        'ordered-list',
        'code',
        'quote',
        'strikethrough',
        'upload-image',
        'preview',
      ],
      imagePathAbsolute: true,
      imageUploadFunction: uploadImage,
    });

    setEditor(instance);
  }, []);

  const getParentCmsKeyId = () => {
    if (cmsNode.key === undefined) {
      if (cmsNode.children.length > 0) {
        return cmsNode.children[0].parentCmsKeyId;
      }
    }

    return cmsNode.cmsKeyId;
  };

  const updateCmsContent = async () => {
    setUpdating(true);

    if (cmsNode.cmsKeyId === -1) {
      const result = await createCmsData({
        data: {
          parentCmsKeyId: cmsNode.parentCmsKeyId,
          key: cmsKeyField,
          value: editor.value(),
        }
      });

      setUpdating(false);

      onCreateNode(result.data.createCmsData[0]);

      return;
    }

    await updateCmsData({
      data: {
        cmsKeyValueId: cmsNode.cmsKeyValueId,
        value: editor.value(),
      }
    });

    setUpdating(false);

    onUpdate(editor.value());
    if (showChilds || applyMarkdownOnEdit === false) {
      window.location.reload();
      return;
    }
    setModalObject(null);
  };

  const discardModal = () => {
    if (isCreateScreen) {
      window.location.reload();
      return;
    }

    if (showParent) {
      showParent();
    }
    setUpdating(false);
    if (setModalObject) {
      setModalObject(null);
    }
  };

  const onEditChild = (c) => {
    setChildNode(c);
  };

  const onDeleteChild = async (c) => {
    setDeleting(c);

    await deleteCmsData({
      data: {
        cmsKeyId: c.cmsKeyId,
      }
    });

    window.location.reload();
  };

  const onCreateNodeSuccess = (node) => {
    setNewNode(node);
  };

  const onAddChild = () => {
    setNewNode({
      cmsKeyId: -1,
      parentCmsKeyId: getParentCmsKeyId(),
      key: '',
      value: '',
      children: [],
    });
  };

  const goBack = () => {
    setChildNode(null);
    setNewNode(null);
  };

  if (newNode) {
    return (
      <CmsContentEditModal
        cmsNode={newNode}
        showChilds={newNode.cmsKeyId !== -1}
        showParent={goBack}
        onCreateNode={onCreateNodeSuccess}
        onUpdate={(v) => onUpdate(v)}
        showContent
        createNode
      />
    );
  }

  const  handleCmsKeyInput = (e) => {
    setCmsKeyField(e?.target?.value);
  }

  if (childNode) {
    return (
      <CmsContentEditModal
        cmsNode={childNode}
        showChilds
        onUpdate={(v) => onUpdate(v)}
        showParent={goBack}
        showContent
      />
    );
  }

  return (
    <div className={styles.cmsContentEditModalContent}>
      <div className="flex absolute">
        {
          showParent &&
          !createNode &&
          (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
            <p className={`mb-0 mr-4 font-medium cursor-pointer ${styles.goBackBtn}`} onClick={() => showParent()}>
              &lt;&lt; Back
            </p>
          )
        }
      </div>
      <h3 className="mb-4 text-center">{isCreateScreen? 'Create Cms content' : 'Update Cms Content'}</h3>
      {
        showContent && createNode &&
        (
          <div className="mb-4">
            <Input value={cmsKeyField} label="Key" handleInput={(e) => handleCmsKeyInput(e)} />
          </div>
        )
      }
      {
        showContent &&
        (
          <div>
            <textarea id="cms-edit-content">{cmsNode.value}</textarea>
          </div>
        )
      }
      {
        showChilds  &&
        (
          <div className={styles.cmsContentEditModalChildren}>
            {
              cmsNode?.children?.length > 0 &&
              (
              <div>
                <p className="mb-2"><strong>Children</strong></p>
                <div className={styles.cmsContentEditModalContentChildrenItems}>
                  {cmsNode.children.map(c => (
                    <div key={c.key} className={`${styles.cmsContentEditModalContentChildrenItem} flex justify-between items-center px-2 py-2 mb-2 rounded-xl border border-borderLight border-solid`}>
                      <div>{c.key}</div>
                      <div className="flex items-center">
                        <button type="button" onClick={() => onEditChild(c)} className="editCmsChildBtn bg-transparent border border-borderLight border-solid mr-2">Edit</button>
                        <button type="button" onClick={() => onDeleteChild(c)} className="deleteCmsChildBtn bg-transparent border border-borderLight border-solid">{deleting && deleting.cmsKeyId === c.cmsKeyId ? 'Deleting...' : 'Delete'}</button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              )
            }
            <button type="button" className="addCmsChildBtn mt-2 z-50 relative" onClick={() => onAddChild()}>Add Child</button>
          </div>
        )
      }
      <div className={styles.cmsContentEditModalActions}>
        <button className="discardBtn" onClick={() => discardModal()} type='button'>Close</button>
        {
          showContent && !isCreateScreen &&
          (
            <button
              className="updateCmsBtn"
              disabled={updating}
              onClick={() => updateCmsContent()}
              type='button'
            >
              { updating ? 'Updating...' : 'Update' }
            </button>
          )
        }
        {
          showContent && isCreateScreen &&
          (
            <button
              className="updateCmsBtn"
              disabled={updating}
              onClick={() => updateCmsContent()}
              type='button'
            >
              { updating ? 'Creating...' : 'Create' }
            </button>
          )
        }
      </div>
    </div>
  );
}

export const mapDispatchToProps = (dispatch) => ({
  setModalObject: value => dispatch(elementsActions.setModalObject(value)),
});

CmsContentEditModal.defaultProps = {
  showChilds: false,
  showContent: true,
  showParent: null,
  createNode: false,
  onCreateNode: null,
  applyMarkdownOnEdit: true,
};

CmsContentEditModal.propTypes = {
  cmsNode: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    cmsKeyValueId: PropTypes.string.isRequired,
    cmsKeyId: PropTypes.string.isRequired,
    parentCmsKeyId: PropTypes.string,
    children: PropTypes.array.isRequired,
  }).isRequired,
  createNode: PropTypes.bool,
  showContent: PropTypes.bool,
  showChilds: PropTypes.bool,
  applyMarkdownOnEdit: PropTypes.bool,
  setModalObject: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  showParent: PropTypes.func,
  onCreateNode: PropTypes.func,
};

export default withRouter(connect(null, mapDispatchToProps)(CmsContentEditModal));
