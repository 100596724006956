import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import styles from "./HeroIntroPromo.module.css";
import elementConstant from "../../../../../shared/constants/element";
import * as elementsActions from "../../../../../redux/actions/elements";

const HeroIntroPromo = props => {
  const { introHeroBanner, contentCardRefreshDelay, setCarouselSpeed } = props;
  const { extras } = introHeroBanner;
  const { desktopVideoUrl, mobileVideoUrl, mime } = extras;
  const propWebKitPlaysInline = { "webkit-playsinline": "webkit-playsinline" };
  const videoElRef = useRef(null);

  const [videoUrl, setVideoUrl] = useState(desktopVideoUrl);
  const [fadeOutClass, setFadeOutClass] = useState(null);
  useEffect(() => {
    const updateVideo = () => {
      const videoFile = window.innerWidth <= elementConstant.introHeroBannerWidthThreshold
      ? mobileVideoUrl
      : desktopVideoUrl;

      setVideoUrl(videoFile);
    };
    updateVideo();
    const timeOut = setTimeout(() => {
      setFadeOutClass(styles.fadeOut);
    }, parseInt(contentCardRefreshDelay, 10) - elementConstant.introBannerReduceTimeoutOffset);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  const handleLoadedMetadata = () => {
    const video = videoElRef.current;
    if (!video) return;
    const carouselSpeed = parseInt(video.duration * 1000, 10)
    setCarouselSpeed(carouselSpeed)
  };

  return (
    <div className={[styles.videoContainer, fadeOutClass].join(" ")}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption,react/jsx-props-no-spreading */}
      <video autoPlay muted playsInline {...propWebKitPlaysInline} ref={videoElRef} onLoadedMetadata={handleLoadedMetadata}>
        <source src={videoUrl} type={mime} />
      </video>
    </div>
  );
};

HeroIntroPromo.propTypes = {
  introHeroBanner: PropTypes.shape({
    url: PropTypes.string,
    extras: PropTypes.shape({
      template: PropTypes.string,
      desktopVideoUrl: PropTypes.string,
      mobileVideoUrl: PropTypes.string,
      mime: PropTypes.string
    })
  }).isRequired,
  contentCardRefreshDelay: PropTypes.number.isRequired,
  setCarouselSpeed: PropTypes.func.isRequired
};

export const mapStateToProps = state => {
  const { introHeroBanner, contentCardRefreshDelay } = state.braze;

 

  return {
    introHeroBanner,
    contentCardRefreshDelay
  };
};

export const mapDispatchToProps = (dispatch) => {
  const setCarouselSpeed = (value) =>
    dispatch(elementsActions.setCarouselSpeed(value));

  return {setCarouselSpeed}
}

export default connect(mapStateToProps, mapDispatchToProps)(HeroIntroPromo);
