/* eslint-disable import/prefer-default-export */

export const loyaltyRedeemables = value => ({
  type: "LOYALTY_REDEEMABLES",
  value
});

export const loyaltyDeals = value => ({
  type: "LOYALTY_DEALS",
  value
});

export const loyaltyPoints = value => ({
  type: "LOYALTY_POINTS",
  value
});


export const setActiveLoyaltySetting = value => ({
  type: 'SET_ACTIVE_LOYALTY',
  value
});

export const setReferralEnabledSetting = value => ({
  type: 'SET_REFERRAL_ENABLED',
  value
});

export const setRedeemableItem = value => ({
  type: 'SET_REDEEMABLES_ITEM',
  value
});

export const setPlatformWalletLoyaltyCardFeature = value => ({
  type: 'SET_PLATFORM_WALLET_LOYALTY_CARD_FEATURE',
  value
});