import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./SavedLocations.module.css";
import SimpleModal from '../../components/Modal/SimpleModal';
import modalStyles from '../../components/Modal/Modal.module.css';
import { getSavedLocationsAndLabels } from "../../shared/repos/graphql/user";
import ddLog from '../../shared/utilities/datadog/log';
import { client } from "../../shared/utilities/config";
import LocationLabelWidget from "../LocationLabelWidget/LocationLabelWidget";
import SavedLocationList from "./SavedLocationList";
import SavedLocationForm from "./SavedLocationForm";

class SavedLocations extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isShowForm: false,
      selectedLocation: null,
      locations: [],
      locationLabels: [],
      locationsPagination: {
        limit: 3,
        skip: 0,
        totalCount: 0,
      },
    };
  }

  componentDidMount() {
    const { paginatedLocations, labels } = this.props;
    const { locationsPagination } = this.state;
    this.setState({
      locations: paginatedLocations?.locations || [],
      locationLabels: labels,
      locationsPagination: paginatedLocations?.pagination || locationsPagination,
    });
  }

  componentDidUpdate(prevProps) {
    this.onComponentUpdated(prevProps);
  }

  onComponentUpdated = (prevProps) => {
    const { paginatedLocations, labels } = this.props;
    const prevLocationaString = JSON.stringify(prevProps.paginatedLocations);
    const currentLocationsString = JSON.stringify(paginatedLocations);
    const prevLabelsString = JSON.stringify(prevProps.labels);
    const currentLabelsString = JSON.stringify(labels);
    const isSateShouldBeUpdated = prevLocationaString !== currentLocationsString || prevLabelsString !== currentLabelsString;
    if (isSateShouldBeUpdated) {
      this.setState({
        locations: paginatedLocations?.locations || [],
        locationLabels: labels,
        locationsPagination: paginatedLocations?.pagination || { limit: 3, skip: 0, totalCount: 0 },
      });
    }
  }

  attachAddressToLabel = (label) => {
    const { onEditModalVisibilityChange } = this.props;
    this.setState({
      isShowForm: true,
      selectedLocation: {
        locationLabel: label,
      },
    });
    onEditModalVisibilityChange(true);
  }

  handleOnEditLocationClick = (location) => {
    const { onEditModalVisibilityChange } = this.props;
    this.setState({
      isShowForm: true,
      selectedLocation: location,
    });
    onEditModalVisibilityChange(true);
  }

  handleOnLabelClick = (label) => {
    const { onLabelClick } = this.props;
    if (onLabelClick) {
      onLabelClick(label);
    } else if (label?.locations?.length > 0) {
      const location = { ...label.locations[0], locationLabel: { id: label.id, label: label.label } };
      this.handleOnEditLocationClick(location);
    } else {
      this.attachAddressToLabel(label);
    }
  }

  onFormSubmited = async () => {
    const { onEditModalVisibilityChange, onComponentUpdated } = this.props;
    try {
      this.setState({
        isShowForm: false,
        selectedLocation: null,
      });
      onEditModalVisibilityChange(false);
      const result = await getSavedLocationsAndLabels({
        savedLocationsPagination: {
          limit: 3,
          offset: 0,
        },
        isWithCommonLabels: true,
        isWithSpecificLabels: false,
      });
      this.setState({
        locations: [],
      });
      const { data } = result;
      this.setState({
        locations: data.me.savedLocations.locations,
        locationLabels: data.me.locationLabels,
        locationsPagination: data.me.savedLocations.pagination,
      });
      client.cache.evict({
          fieldName: 'me',
      });
      client.cache.gc();
      onComponentUpdated(data.me);
    } catch (e) {
      ddLog.logError(e);
      this.setState({
        isShowForm: false,
        selectedLocation: null,
      });
      onEditModalVisibilityChange(false);
    }
  }

  onLocationDelete = async (deleteResult) => {
    const { onEditModalVisibilityChange, onComponentUpdated } = this.props;
    try {
      if (deleteResult.success) {
        this.setState({
          isShowForm: false,
          selectedLocation: null,
        });
        onEditModalVisibilityChange(false);
        const result = await getSavedLocationsAndLabels({
          savedLocationsPagination: {
            limit: 3,
            offset: 0,
          },
          isWithCommonLabels: true,
          isWithSpecificLabels: false,
        });
        this.setState({
          locations: [],
        });
        const { data } = result;
        this.setState({
          locations: data.me.savedLocations.locations,
          locationsPagination: data.me.savedLocations.pagination,
          locationLabels: data.me.locationLabels,
        });
        client.cache.evict({
          fieldName: 'me',
        });
        client.cache.gc();
        onComponentUpdated(data.me);
      }
    } catch (e) {
      ddLog.logError(e);
      this.setState({
        isShowForm: false,
        selectedLocation: null,
      });
      onEditModalVisibilityChange(false);
    }
  }

  handleCloseForm = () => {
    const { onEditModalVisibilityChange } = this.props;
    this.setState({isShowForm: false, selectedLocation: null});
    onEditModalVisibilityChange(false);
  }

  renderForm() {
    const { selectedLocation } = this.state;
    return (
      <SimpleModal>
        <div className={[
          modalStyles.simpleModalContainer,
          styles.savedAddressFormContainer,
          'dark:bg-dark',
        ].join(' ')}>
          <SavedLocationForm location={selectedLocation} onDeleted={this.onLocationDelete} onSubmited={this.onFormSubmited} onClose={this.handleCloseForm} />
        </div>
      </SimpleModal>
    );
  }

  render() {
    const { className, onLocationClick, isDisableLabels, isDisableLocations } = this.props;
    const { isShowForm, locations, locationLabels, locationsPagination } = this.state;
    const isShowLocationLabelsWidget = isDisableLabels === false && !!locationLabels && locationLabels.length > 0;
    const isShowSavedLocationsWidget = isDisableLocations === false && !!locations && locations.length > 0;
    return (
      <div className={[className, styles.savedAddressesContainer].join(' ')}>
        { isShowLocationLabelsWidget
          ? <LocationLabelWidget
              onLabelClick={ this.handleOnLabelClick }
              onSetAddressClick={ this.attachAddressToLabel }
              className={ styles.labelsWidget }
              labels={ locationLabels } />
          : null }
        { isShowSavedLocationsWidget
          ? <SavedLocationList
              onLocationClick={ onLocationClick }
              onEditLocationClick={ this.handleOnEditLocationClick }
              paginatedLocations={ { locations: locations, pagination: locationsPagination, } } />
          : null }
        { isShowForm ? this.renderForm() : null }
      </div>
    );
  }
}

SavedLocations.propTypes = {
  className: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      displayString: PropTypes.string,
    })),
  })),
  paginatedLocations: PropTypes.oneOfType([
    PropTypes.shape({
      locations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        displayString: PropTypes.string,
        sourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
        source: PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        }),
        locationData: PropTypes.shape({
          comment: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
          isContactless: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
        }),
        addressDetails: PropTypes.shape({
          address2: PropTypes.string,
          lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
          lng: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
        }),
        locationLabel: PropTypes.oneOfType([
          PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            label: PropTypes.string,
          }),
          PropTypes.oneOf([null]),
        ]),
      })),
      pagination: PropTypes.shape({
        limit: PropTypes.number,
        skip: PropTypes.number,
        totalCount: PropTypes.number,
      }),
    }),
    PropTypes.oneOf([undefined]),
  ]),
  onLabelClick: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  onEditModalVisibilityChange: PropTypes.func,
  onLocationClick: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  onComponentUpdated: PropTypes.func,
  isDisableLabels: PropTypes.bool,
  isDisableLocations: PropTypes.bool,
};

SavedLocations.defaultProps = {
  className: "",
  onLabelClick: null,
  onLocationClick: null,
  onEditModalVisibilityChange: () => {},
  onComponentUpdated: () => {},
  paginatedLocations: undefined,
  labels: [],
  isDisableLabels: false,
  isDisableLocations: false,
};

export default SavedLocations;
