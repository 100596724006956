import React, {Component} from 'react';
import _ from 'underscore';
import Loader from '../../components/Loader/Loader';
import * as cms from '../../shared/repos/cms/cms';
import cmsKeys from '../../shared/constants/cms';
import {getKeyValue} from '../../shared/utilities/cms';

import styles from './AboutUs.module.css'
import CmsInlineEdit from '../../components/Cms/CmsInlineEdit';

class About extends Component {
    constructor(props) {
        super(props);

      this.state = {
            loading: true,
            content: []
        };
    }

    componentDidMount() {
        cms.getContent([cmsKeys.aboutUsPage], this);
    }

    render() {
        const {content, loading} = this.state;

        if (loading) {
            return (
              <div className={styles.pageWrapper}>
                <div className={styles.pageContainer}>
                  <Loader id="loader" />
                </div>
              </div>
            );
        }
      
        if (!content.length && !loading) {
          return (
            <div className={styles.pageWrapper}>
              <div className={`${styles.pageContainer} text-dark dark:text-white`}>
                <h1>Oops, No data found for this page</h1>
              </div>
            </div>
          );
        }

        return (
          <div className={styles.pageWrapper}>
            {content.map(aboutUsPage => {
                    const header = _.find(aboutUsPage.children, {key: 'header'});
                    const subheader = _.find(aboutUsPage.children, {key: 'subheader'});
                    const title = _.find(aboutUsPage.children, {key: 'title'});
                    const body = _.find(aboutUsPage.children, {key: 'body'});
                    const sections = getKeyValue(aboutUsPage.children, 'sections', true);
                    const history = getKeyValue(aboutUsPage.children, 'history', true);
                    const historyTitle = _.find(history.children, {key: 'title'});
                    const historyInfo = getKeyValue(history.children, 'bodies', true);
                    return (
                      <div className={`${styles.pageContainer} text-dark dark:text-white`}>
                        <div className={styles.pageSection}>
                          <h1><CmsInlineEdit cmsNode={header}>{header?.value}</CmsInlineEdit></h1>
                          <h4><strong><CmsInlineEdit cmsNode={subheader}>{subheader?.value}</CmsInlineEdit></strong></h4>
                          <p>
                            <strong><CmsInlineEdit cmsNode={title}>{title?.value}</CmsInlineEdit></strong>
                            {' '}
                            <CmsInlineEdit cmsNode={body}>{body?.value}</CmsInlineEdit>
                          </p>
                        </div>

                        { (sections.children.length > 0) ? sections.children.map(section => {
                              const sectionBody = _.find(section.children, {key: 'body'});
                              return (
                                <div>
                                  <div className={styles.pageSection}>
                                    <p><CmsInlineEdit cmsNode={sectionBody}>{sectionBody?.value}</CmsInlineEdit></p>
                                  </div>
                                </div>
                              );
                          }) : null }

                        { history.children.length > 0 ? (
                          <div className={styles.pageSection}>
                            <h3><CmsInlineEdit cmsNode={historyTitle}>{historyTitle?.value}</CmsInlineEdit></h3>
                            { historyInfo.children.length > 0 ?
                                    historyInfo.children.map(item => {
                                        const year = _.find(item.children, {key: 'year'});
                                        const info = _.find(item.children, {key: 'info'});
                                        return(
                                          <p>
                                            <strong><CmsInlineEdit cmsNode={year} applyMarkdown={false}>{year?.value}</CmsInlineEdit></strong>
                                            {' '}
                                            <CmsInlineEdit cmsNode={info} applyMarkdown={false}>{info?.value}</CmsInlineEdit>
                                          </p>
                                        )
                                    })
                                    : null }
                          </div>    
                        ) : null }
                      </div>
                    )
                })}
          </div>
        );
    }
}

export default About;
