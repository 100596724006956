/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable camelcase */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from "underscore";
import Link from '../LinkWithCountry/LinkWithCountry';
import * as userActions from '../../redux/actions/user';
import siteSetting from '../../shared/constants/siteSetting';
// REDUX

import styles from './Footer.module.css';

import { routeCountryPath } from '../../shared/utilities/common';
// eslint-disable-next-line import/no-cycle
import {renderSocialLinksSection} from "../../shared/utilities/renderings";

import endpoints from '../../shared/constants/endpoints';

import * as cms from "../../shared/repos/cms/cms";
import cmsKeys from "../../shared/constants/cms";
import CmsInlineEdit from '../Cms/CmsInlineEdit';

const getYear = () => new Date().getFullYear();

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: []
    };
  }

  componentDidMount(){
    cms.getContent(cmsKeys.giftCardSection,this);
  }

  redirectToGiftCards = () => {
      window.location.href = endpoints.giftCardOrderPage;
  };

  footerContainer = () => {
    const {
      history,
      isCountrySelectActive,
      selectedCountry,
      facebookLink,
      instagramLink,
      tiktokLink,
      footerMenuItemsSettings,
      footerMenuItemsLinksSettings,
      isGiftcardDisabled
    } = this.props;
    const renderLink = (link, text, attr = {}) => {
      if (link.trim().indexOf('http') === 0) {
        return (
          <a 
            href={link.trim()}  
            target={attr.target || '_self'}
            rel={attr.rel || ''}
            className='text-dryPink hover:bg-dark hover:bg-opacity-60 px-1 font-filsonProRegular'
            aria-label={text}
          >
            {text}
          </a>
        );
      }

      return (
        <Link
          className="text-dryPink hover:bg-dark hover:bg-opacity-60 px-1"
          to={link}
          aria-label={text}
        >
          {text}
        </Link>
      );
    }
    const {content,loading} = this.state
    const giftCardSectionHeader = _.find(content, {key: cmsKeys.giftCardSection.giftCardSectionHeader });
    const giftCardSectionBody = _.find(content, {key: cmsKeys.giftCardSection.giftCardSectionBody });
    const giftCardSectionLinkText = _.find(content, {key: cmsKeys.giftCardSection.giftCardSectionLinkText });
    const giftCardSectionButtonText = _.find(content, {key: cmsKeys.giftCardSection.giftCardSectionButtonText });
    return (
      <div
        className={`${styles.footerWrapper} bg-dark text-white block font-filsonProRegular`}
      >
        {/* OPTIONS SECTION */}
        <div
          className={`${styles.footerContainer} lg:grid grid-cols-5 flex flex-col gap-8`}
        >
          <div
            className={`${styles.sectionContainer} col-span-2 flex flex-col gap-2 md:text-left text-center`}
          >
            <div
              className={`${styles.linksContainer} ${styles.desktopLinks} w-full md:grid grid-cols-2 gap-6`}
            >
              <div className="col-span-1">
                {footerMenuItemsSettings[
                  siteSetting.IS_FOOTER_MENU_NEWSROOM_ENABLED
                ]
                  ? renderLink(
                      footerMenuItemsLinksSettings[
                        siteSetting.FOOTER_MENU_NEWSROOM_LINK
                      ],
                      "Newsroom"
                    )
                  : null}
                {footerMenuItemsSettings[siteSetting.IS_FOOTER_MENU_FAQ_ENABLED]
                  ? renderLink(
                      footerMenuItemsLinksSettings[
                        siteSetting.FOOTER_MENU_FAQ_LINK
                      ],
                      "FAQ"
                    )
                  : null}
                {footerMenuItemsSettings[
                  siteSetting.IS_FOOTER_MENU_PRIVACY_POLICY_ENABLED
                ]
                  ? renderLink(
                      footerMenuItemsLinksSettings[
                        siteSetting.FOOTER_MENU_PRIVACY_POLICY_LINK
                      ],
                      "Privacy Policy"
                    )
                  : null}
                {footerMenuItemsSettings[
                  siteSetting.IS_FOOTER_MENU_TERMS_ENABLED
                ]
                  ? renderLink(
                      footerMenuItemsLinksSettings[
                        siteSetting.FOOTER_MENU_TERMS_LINK
                      ],
                      "Terms & Conditions"
                    )
                  : null}
                {footerMenuItemsSettings[
                  siteSetting.IS_FOOTER_MENU_EVENT_TERMS_ENABLED
                ]
                  ? renderLink(
                      footerMenuItemsLinksSettings[
                        siteSetting.FOOTER_MENU_EVENT_TERMS_LINK
                      ],
                      "Event Terms & Condition"
                    )
                  : null}
                {footerMenuItemsSettings[
                  siteSetting.IS_FOOTER_MENU_ACCESSIBILITY_ENABLED
                ]
                  ? renderLink(
                      footerMenuItemsLinksSettings[
                        siteSetting.FOOTER_MENU_ACCESSIBILITY_LINK
                      ],
                      "Accessibility"
                    )
                  : null}
              </div>
              <div className="col-span-1">
                {footerMenuItemsSettings[
                  siteSetting.IS_FOOTER_MENU_CONTACT_ENABLED
                ]
                  ? renderLink(
                      footerMenuItemsLinksSettings[
                        siteSetting.FOOTER_MENU_CONTACT_LINK
                      ],
                      "Contact"
                    )
                  : null}
                {footerMenuItemsSettings[
                  siteSetting.IS_FOOTER_MENU_ALLERGEN_ENABLED
                ]
                  ? renderLink(
                      footerMenuItemsLinksSettings[
                        siteSetting.FOOTER_MENU_ALLERGEN_LINK
                      ],
                      "Allergen & Nutrition Info"
                    )
                  : null}
                {footerMenuItemsSettings[
                  siteSetting.IS_FOOTER_MENU_ABOUT_US_ENABLED
                ]
                  ? renderLink(
                      footerMenuItemsLinksSettings[
                        siteSetting.FOOTER_MENU_ABOUT_US_LINK
                      ],
                      "About Us"
                    )
                  : null}
                {footerMenuItemsSettings[
                  siteSetting.IS_FOOTER_MENU_CAREERS_ENABLED
                ]
                  ? renderLink(
                      footerMenuItemsLinksSettings[
                        siteSetting.FOOTER_MENU_CAREERS_LINK
                      ],
                      "Careers"
                    )
                  : null}
                {footerMenuItemsSettings[
                  siteSetting.IS_FOOTER_MENU_CORPORATE_SALES_ENABLED
                ]
                  ? renderLink(
                      footerMenuItemsLinksSettings[
                        siteSetting.FOOTER_MENU_CORPORATE_SALES_LINK
                      ],
                      "Corporate Sales",
                      { target: "_blank", rel: "noopener noreferrer" }
                    )
                  : null}
                {footerMenuItemsSettings[
                  siteSetting.IS_FOOTER_MENU_DONATIONS_ENABLED
                ]
                  ? renderLink(
                      footerMenuItemsLinksSettings[
                        siteSetting.FOOTER_MENU_DONATIONS_LINK
                      ],
                      "Donations"
                    )
                  : null}
                {footerMenuItemsSettings[
                  siteSetting.IS_FOOTER_MENU_MERCH_ENABLED
                ]
                  ? renderLink(
                      footerMenuItemsLinksSettings[
                        siteSetting.FOOTER_MENU_MERCH_LINK
                      ],
                      "Merch",
                      { target: "_blank", rel: "noopener noreferrer" }
                    )
                  : null}

                { footerMenuItemsSettings[siteSetting.IS_FOOTER_MENU_PROMOTIONS_TERMS_AND_CONDITIONS_ENABLED]
                    ? renderLink(
                      footerMenuItemsLinksSettings[siteSetting.FOOTER_MENU_PROMOTIONS_TERMS_AND_CONDITIONS_LINK], 
                      'Promotions Terms & Conditions') : null }
                {isCountrySelectActive ? (
                  <Link
                    to="/countries"
                    title="Country Selection"
                    name="countries_link"
                    className="text-dryPink hover:bg-dark hover:bg-opacity-60 px-1 font-filsonProRegular"
                  >
                    Countries
                    {selectedCountry.short_name
                      ? ` (${selectedCountry.short_name})`
                      : ""}
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {/* NEVER MISS A DEAL SECTION */}
          <div
            className={`${styles.sectionContainer} col-span-1 flex flex-col items-center md:items-start md:justify-start justify-center gap-2 md:gap-8 md:text-left text-center`}
          >
            <h3 className="text-lg leading-[22px] tracking-[-0.1px] font-filsonProMedium">
              Never miss a deal
            </h3>
            <div className={styles.dealsContainer}>
              <button
                onClick={() =>
                  history.push(routeCountryPath("/create-account"))}
                className={`${styles.button} bg-button font-filsonProBold text-lg leading-[22px] tracking-[-0.1px] text-light rounded-[54px]`}
                type="button"
              >
                Sign me up!
              </button>
            </div>
          </div>
          {/* GIFT CARD SECTION */}
          {Number(isGiftcardDisabled) === 1 && !loading ? null : (
            <div
              className={`${styles.sectionContainer} col-span-1 flex flex-col items-center md:items-start md:justify-start justify-center gap-4 md:gap-8 md:text-left text-center`}
            >
              <h3 className="font-filsonProMedium text-lg leading-[22px] tracking-[-0.1px]">
                <CmsInlineEdit cmsNode={giftCardSectionHeader}>
                  {giftCardSectionHeader?.value}
                </CmsInlineEdit>
              </h3>
              <p>
                <CmsInlineEdit cmsNode={giftCardSectionBody}>
                  {giftCardSectionBody?.value}
                </CmsInlineEdit>
                <CmsInlineEdit cmsNode={giftCardSectionLinkText} applyMarkdown={false} applyMarkdownOnEdit={false}>
                  <Link
                    to="/gift-card"
                    className="text-dryPink hover:bg-dark hover:bg-opacity-60 px-1 font-filsonProRegular"
                  >
                    {giftCardSectionLinkText?.value}
                    {' '}
                  </Link>
                </CmsInlineEdit>
              </p>
              <CmsInlineEdit cmsNode={giftCardSectionButtonText} applyMarkdown={false} applyMarkdownOnEdit={false}>
                <button
                  onClick={this.redirectToGiftCards}
                  className={`${styles.button} bg-button font-filsonProBold text-lg leading-[22px] tracking-[-0.1px] text-light h-[54px] rounded-[54px] w-[174px]`}
                  type="button"
                >
                  {giftCardSectionButtonText?.value}
                </button>
              </CmsInlineEdit>
            </div>
          )}
          <div className={`${styles.linksContainer} ${styles.mobileLinks}`}>
            <div>
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_NEWSROOM_ENABLED
              ]
                ? renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_NEWSROOM_LINK
                    ],
                    "Newsroom"
                  )
                : null}
              {footerMenuItemsSettings[siteSetting.IS_FOOTER_MENU_FAQ_ENABLED]
                ? renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_FAQ_LINK
                    ],
                    "FAQ"
                  )
                : null}
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_PRIVACY_POLICY_ENABLED
              ]
                ? renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_PRIVACY_POLICY_LINK
                    ],
                    "Privacy Policy"
                  )
                : null}
              {footerMenuItemsSettings[siteSetting.IS_FOOTER_MENU_TERMS_ENABLED]
                ? renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_TERMS_LINK
                    ],
                    "Terms & Conditions"
                  )
                : null}
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_EVENT_TERMS_ENABLED
              ]
                ? renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_EVENT_TERMS_LINK
                    ],
                    "Event Terms & Condition"
                  )
                : null}
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_ACCESSIBILITY_ENABLED
              ]
                ? renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_ACCESSIBILITY_LINK
                    ],
                    "Accessibility"
                  )
                : null}
            </div>
            <div>
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_CONTACT_ENABLED
              ]
                ? renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_CONTACT_LINK
                    ],
                    "Contact"
                  )
                : null}
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_ALLERGEN_ENABLED
              ]
                ? renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_ALLERGEN_LINK
                    ],
                    "Allergen & Nutrition Info"
                  )
                : null}
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_ABOUT_US_ENABLED
              ]
                ? renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_ABOUT_US_LINK
                    ],
                    "About Us"
                  )
                : null}
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_CAREERS_ENABLED
              ]
                ? renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_CAREERS_LINK
                    ],
                    "Careers"
                  )
                : null}
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_CORPORATE_SALES_ENABLED
              ]
                ? renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_CORPORATE_SALES_LINK
                    ],
                    "Corporate Sales",
                    { target: "_blank", rel: "noopener noreferrer" }
                  )
                : null}
              {footerMenuItemsSettings[
                siteSetting.IS_FOOTER_MENU_DONATIONS_ENABLED
              ]
                ? renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_DONATIONS_LINK
                    ],
                    "Donations"
                  )
                : null}
              { footerMenuItemsSettings[siteSetting.IS_FOOTER_MENU_PROMOTIONS_TERMS_AND_CONDITIONS_ENABLED]
                ? renderLink(footerMenuItemsLinksSettings[siteSetting.FOOTER_MENU_PROMOTIONS_TERMS_AND_CONDITIONS_LINK], 'Promotions Terms & Conditions') : null }
              {footerMenuItemsSettings[siteSetting.IS_FOOTER_MENU_MERCH_ENABLED]
                ? renderLink(
                    footerMenuItemsLinksSettings[
                      siteSetting.FOOTER_MENU_MERCH_LINK
                    ],
                    "Merch",
                    { target: "_blank", rel: "noopener noreferrer" }
                  )
                : null}
            </div>
          </div>
          {/* SOCIAL SECTION */}
          <div className="col-span-1 flex flex-col items-center md:items-end">
            {renderSocialLinksSection(styles.socialContainer, {
              facebookLink,
              instagramLink,
              tiktokLink
            })}
          </div>
        </div>
        <span className={`${styles.copyright} w-full text-center md:text-left`}>
          {`insomnia cookies warm.delicious.delivered. © ${getYear()}`}
        </span>
      </div>
    );
  };

  render() {
    return <this.footerContainer />;
  }
}

export const mapStateToProps = (state) => {
  const { endpoint } = state.user;
  const { isCountrySelectActive, selectedCountry } = state.country;
  const { tiktokLink, instagramLink, facebookLink, footerMenuItemsSettings, footerMenuItemsLinksSettings } = state.footerSettings;
  const { isGiftcardDisabled } = state.giftCard;

  return {
    endpoint,
    isCountrySelectActive,
    selectedCountry,
    tiktokLink,
    instagramLink,
    facebookLink,
    footerMenuItemsSettings,
    footerMenuItemsLinksSettings,
    isGiftcardDisabled
  };
};

export const mapDispatchToProps = (dispatch) => ({
  setEndpoint: (value) => dispatch(userActions.setEndpoint(value)),
  setUserAddress: (value) => dispatch(userActions.setUserAddress(value))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));
