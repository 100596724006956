import gql from "graphql-tag";

const response = `
    id
    title
    image
    isLastOrdered
    optionGroups{
      groupId
      id
      options{
        productId
        productTitle
        quantity
        priority
        groupId
      }
    }
`;

export const PRESETS_QUERY = gql`
  query PRESETS($productId: Int!, $storeId: Int, $includePersonalizedPreset: Boolean!) {
    presets(productId: $productId, storeId: $storeId, includePersonalizedPreset: $includePersonalizedPreset) {
        ${response}
    }
  }
`;
