/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import Loader from '../../components/Loader/Loader';
import * as cms from '../../shared/repos/cms/cms';
import cmsKeys from '../../shared/constants/cms';

import styles from './FAQ.module.css'
import CmsInlineEdit from '../../components/Cms/CmsInlineEdit';

class FAQ extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: []
        };
    }

    componentDidMount() {
        cms.getContent([cmsKeys.faqPage.faqSections], this);
    }

    render() {
        const { content } = this.state;

        if (!content.length) {
            return (
              <div className={styles.pageWrapper}>
                <div className={styles.pageContainer}>
                  <Loader id="loader" />
                </div>
              </div>
            );
        }

        return (
          <div className={styles.pageWrapper}>
            <div className={`${styles.pageContainer} text-dark dark:text-white`}>
              {content.map(faqSection => {
                    return (
                      <div>
                        <div className={styles.pageSection}>
                          <h1><CmsInlineEdit cmsNode={faqSection}>{faqSection.value}</CmsInlineEdit></h1>
                        </div>

                        {faqSection.children.map(faqQuestion => {
                                return (
                                  <div className={styles.pageSection}>
                                    <h3><CmsInlineEdit cmsNode={faqQuestion}>{faqQuestion.value}</CmsInlineEdit></h3>

                                    <div><CmsInlineEdit cmsNode={faqQuestion.children[0]}>{faqQuestion.children[0].value}</CmsInlineEdit></div>
                                  </div>
                                )
                            })}
                      </div>
                    )
                })}
            </div>
          </div>
        );
    }
};

export default FAQ;
