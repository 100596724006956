import { GET_CUSTOMER_NOTIFICATIONS, READ_CUSTOMER_NOTIFICATIONS } from "../../../graphql/customerNotifications";
import { client } from "../../utilities/config";

export const getNotifications = type => {
  return client.query({
    fetchPolicy: "no-cache",
    query: GET_CUSTOMER_NOTIFICATIONS,
    variables: { type }
  });
};

export const readNotifications = ids => {
  return client.mutate({
    fetchPolicy: "no-cache",
    mutation: READ_CUSTOMER_NOTIFICATIONS,
    variables: { ids }
  });
};
