import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import styles from "./LoyaltyReward.module.css";
import { shippingNotAllowToLoyaltyProduct } from "../../shared/utilities/modals";
import * as challengeRepo from "../../shared/repos/graphql/challenge";
import * as loyaltyAction from "../../redux/actions/loyalty";
import * as addressActions from "../../redux/actions/address";
import * as elementsActions from "../../redux/actions/elements";

import Modal from "../../components/Modal/Modal";
import DeliveryPickUpWidget from "../../components/DeliveryPickUpWidget/DeliveryPickUpWidget";

import { orderMethods } from "../../shared/constants/order";
import { PUNCHH_TYPE } from "../../shared/constants/loyaltyType";

const mapPin = require("./imgs/map-pin.svg");

export class CookieChallenge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      challenge: null,
      showLearnMoreModal: false,
      remainingTime: "",
      showDeliveryWidget: false,
      addressType: "Delivery",
    };
    this.timer = null;
  }

  componentDidMount() {
    this.fetchChallenge();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  fetchChallenge = async () => {
    const { onChallengeLoaded } = this.props
    try {
      const response = await challengeRepo.getChallenge();
      const challenge = response?.data?.getChallenge || null;
      this.setState({ challenge }, () => {
        if (onChallengeLoaded && challenge) {
          onChallengeLoaded(true);
        }
        if (challenge?.endDateTime) {
          this.updateRemainingTime();
          this.timer = setInterval(this.updateRemainingTime, 60000);
        }
      });
    } catch (error) {
      console.error("Failed to fetch challenge data", error);
    }
  };

  updateRemainingTime = () => {
    const { challenge } = this.state;
    if (!challenge?.endDateTime) return;

    const endDateTimeUtc = this.convertToUtc(challenge.endDateTime);
    const now = new Date();
    const timeDifference = endDateTimeUtc - now;

    if (timeDifference <= 0) {
      clearInterval(this.timer);
      this.setState({ remainingTime: "" });
      return;
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);

    this.setState({
      remainingTime: days < 7
        ? `${days.toString().padStart(2, "0")}d:${hours.toString().padStart(2, "0")}h:${minutes.toString().padStart(2, "0")}m`
        : "",
    });
  };

  convertToUtc = (dateStr) => {
    const [date, time] = dateStr.split(" ");
    const [year, month, day] = date.split("-");
    const [hours, minutes, seconds] = time.split(":");
    return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
  };

  handleLearnMoreToggle = () => {
    this.setState((prevState) => ({
      showLearnMoreModal: !prevState.showLearnMoreModal,
    }));
  };

  handleRedeem = (link, id) => {
    const { userOrderMethod, setModalObject, userAddress, history, setRedirectUrl, setRedeemableItem, loyaltyDeals } = this.props;
    const matchedDeal = loyaltyDeals.find(
      (product) => product.challengeId === Number(id)
    );
    if (!matchedDeal) {
      console.error("Failed to match deal for this challenge");
      return;
    }
    setRedeemableItem({ redeemableId: matchedDeal.id, redeemableType: PUNCHH_TYPE.DEALS });

    if (userOrderMethod === orderMethods.shipping) {
      setModalObject(
        shippingNotAllowToLoyaltyProduct(() => {
          setRedirectUrl(link);
          this.setState({ showDeliveryWidget: true });
        }, this.closeDeliveryWidget)
      );
    } else if (!userAddress) {
      setRedirectUrl(link);
      this.setState({ showDeliveryWidget: true });
    } else {
      history.push(link);
    }
  };

  closeDeliveryWidget = () => {
    this.setState({ showDeliveryWidget: false });
  };

  renderProgress = (userLevel, totalSteps) => {
    const perStepProgress = totalSteps ? 210 / totalSteps : 0;
    const progressWidth = totalSteps ? perStepProgress * userLevel : 0;
    const userProgress = Math.ceil(userLevel * perStepProgress);

    return (
      <>
        <div className="mb-4 w-[210px] relative">
          <img
            src={mapPin}
            alt={`Level: ${userLevel}`}
            style={userProgress > 0 ? { marginLeft: `${userProgress - 17}px` } : { marginLeft: `${userProgress}px` }}
          />
        </div>
        <div className="w-[210px] border-2 border-disabled rounded-[40px]">
          <div
            className="border-2 border-glow transition-all duration-500 ease-in-out"
            style={{ width: `${progressWidth}px` }}
          />
        </div>
        <div className="flex justify-between items-center relative top-[-20px] w-[210px]">
          {Array.from({ length: totalSteps }).map((_, index) => (
            <p
              className={`border-2 flex justify-center items-center w-7 h-7 rounded-full ml-auto ${index < userLevel ? "text-black bg-glow" : "text-white bg-dark"
                }`}
            >
              {index + 1}
            </p>
          ))}
        </div>
      </>
    );
  };

  render() {
    const { challenge, showLearnMoreModal, remainingTime, addressType, showDeliveryWidget } = this.state;

    if (!challenge) return null;

    const {
      id,
      customerLevel = 0,
      actionCountRequired = 0,
      title,
      description,
      rewardCompleteImage,
      rewardImage,
      learnMoreText,
      explanation,
      rewardProduct,
      customerAward,
    } = challenge;

    const isChallengeComplete = customerLevel === actionCountRequired && customerAward?.id;

    let link;

    if (rewardProduct?.hasAssortments) {
      link = `/products/box/${rewardProduct.id}`;
    } else if (rewardProduct?.options?.length) {
      link = `/products/customize/${rewardProduct.id}`;
    } else {
      link = `/products/single/${rewardProduct.id}`;
    }

    let buttonText;

    if (isChallengeComplete && customerAward?.isRedeemed) {
      buttonText = `${rewardProduct?.title} Redeemed`;
    } else if (isChallengeComplete) {
      buttonText = `Redeem ${rewardProduct?.title}`;
    } else {
      buttonText = "Learn More";
    }

    return (
      <div className="flex flex-col justify-center items-center">
        <div className={`${styles.loyaltyPoints} bg-cover relative`}>
          {remainingTime && (
            <div className="w-full max-w-[170px] bg-glow absolute left-[26%] rounded-b-[10px] px-2 py-[2px] text-base text-dark">
              <p>
                Ends in
                {remainingTime}
              </p>
            </div>
          )}

          <div className={`font-congenialBlack text-[28px] text-white p-5 mt-2 ${isChallengeComplete ? "pb-0" : ""}`}>
            <span>{isChallengeComplete ? "Yay! You’ve earned free cookies!" : title}</span>
            {!isChallengeComplete && (
              <p className="!text-[#B4A5BB] !leading-5 !text-base !font-[425] !font-filsonProRegular">
                {description}
              </p>
            )}
          </div>

          <div className="flex items-center flex-col justify-center px-2 pb-3 mt-0 absolute">
            {this.renderProgress(customerLevel, actionCountRequired)}
          </div>

          <img
            className="absolute right-[20px] bottom-[20px] z-10"
            src={isChallengeComplete ? rewardCompleteImage : rewardImage}
            alt="cookiebox"
            width={88}
            height={88}
          />

          <div className="w-full bg-glow absolute bottom-0 rounded-b-[20px] px-5 py-2 text-sm text-dark">
            <p>
              {!isChallengeComplete ? (
                <>
                  {explanation}
                  <span className="font-medium"> &nbsp;free!</span>
                </>
              ) : (
                <>
                  {'Free'}
                  &nbsp;
                  {rewardProduct?.title}
                  <span className="font-medium">&nbsp;unlocked!</span>
                </>
              )}
            </p>
          </div>
        </div>

        <div className="w-[343px] mt-5 flex justify-center items-center">
          <button
            type="button"
            className="p-3 text-base text-white bg-dark"
            onClick={isChallengeComplete ? () => this.handleRedeem(link, id) : this.handleLearnMoreToggle}
            disabled={isChallengeComplete && customerAward?.isRedeemed}
          >
            {buttonText}
          </button>
        </div>

        {showLearnMoreModal && (
          <Modal
            title="Learn More"
            description={learnMoreText}
            exit={false}
            action1={this.handleLearnMoreToggle}
            action1Label="Close"
          />
        )}

        {showDeliveryWidget && (
          <DeliveryPickUpWidget
            onClose={this.closeDeliveryWidget}
            onAddressType={(address) => this.setState({ addressType: address })}
            addressType={addressType}
          />
        )}
      </div>
    );
  }
}

CookieChallenge.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  userAddress: PropTypes.shape(),
  userOrderMethod: PropTypes.string,
  setRedirectUrl: PropTypes.func.isRequired,
  setModalObject: PropTypes.func.isRequired,
  setRedeemableItem: PropTypes.func.isRequired,
  loyaltyDeals: PropTypes.arrayOf(
    PropTypes.shape({
      challengeId: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
    })
  ),
  onChallengeLoaded: PropTypes.func
};

CookieChallenge.defaultProps = {
  userAddress: null,
  userOrderMethod: '',
  loyaltyDeals: null,
  onChallengeLoaded: null
};

export const mapStateToProps = (state) => {
  const { userAddress, userOrderMethod } = state.user;
  const { loyaltyDeals } = state.loyalty;
  return { userAddress, userOrderMethod, loyaltyDeals };
};

export const mapDispatchToProps = (dispatch) => ({
  setRedirectUrl: (value) => dispatch(addressActions.setRedirectUrl(value)),
  setModalObject: (value) => dispatch(elementsActions.setModalObject(value)),
  setRedeemableItem: (value) => dispatch(loyaltyAction.setRedeemableItem(value)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CookieChallenge));
