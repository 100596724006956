export const METADATA_INFO  = {
    PAGE_TITLES: {
        LOCATION: 'Find a Bakery',
    },
    HEADER_LINK: {
        LOCATION: 'Find a Bakery'
    }
};

export const ERROR_CODES = {
    ORDER_ALREADY_COMPLETED: 'ORDER_ALREADY_COMPLETED',
    CAN_NOT_LOCATE_ORDER: 'CAN_NOT_LOCATE_ORDER',
}

export const PAGINATION = {
    CURRENT: 1,
    TOTAL: 5,
}

export const PROVIDERS = {
    INSOMNIA_DORMS: 72,
    GOOGLE_MAP: 71,
    INSOMNIA_COOKIES: 1,
}

export const LOCATION_LABELS = {
    HOME: 1,
    WORK: 2,
    SCHOOL: 3,
}

export const VIDEO_TYPES = [
    'video/mp4',
    'video/ogg',
    'video/webm'
]

export const PLATFORMS = {
    IPHONE: 'iPhone',
    ANDROID: 'android',
    WEB: 'WEBSITE',
    IOS: 'ios',
}

export const addToWalletResponseTypes = {
    URL: 'URL',
    FILE_BASE64_ENCODED: 'FILE_BASE64_ENCODED',
}
