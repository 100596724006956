import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from 'underscore';
import CollectionSlider from "./CollectionSlider";
import {
  getContentsByKey,
} from "../../../shared/utilities/common";
import CmsInlineEdit from "../../Cms/CmsInlineEdit";

const ShadowLeftIcon = require("./icons/shadow-left.png");
const ShadowRightIcon = require("./icons/shadow-right.png");

function Collections(props) {
  const { contents, openDeliveryWidget } = props;
  const [menuItems, setMenuItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);

  const heading = _.find(contents, {key: "heading"});
  const collections = getContentsByKey(contents, "collections");

  const getMenu = () => {
    const menu = collections?.map(item => {
      const title = _.find(item.children, {key: "title"});
      return {
        title
      };
    });

    return menu;
  };

  const setSelectedIndex = index => {
    setSelectedItem(index);
  };

  const titleCollection = () => {
    return collections?.map(item => {
      return {
        ...item,
        children: [_.find(item.children, {key: "title"})].filter((c) => c),
      };
    });
  }

  useEffect(() => {
    const menu = getMenu();
    setMenuItems(menu);
  }, [contents]);

  return (
    <div className="w-full flex flex-col items-start lg:items-center gap-4 mt-9 mb-9 border-0">
      <div className="md:max-w-screen-desktop w-full lg:px-0 px-4">
        <h1 className="text-dark dark:text-white font-congenialBlack text-4xl leading-9 tracking-tighter">
          {
            heading &&
            (
              <CmsInlineEdit cmsNode={heading}>
                {heading.value}
              </CmsInlineEdit>
            )
          }
        </h1>
      </div>
      <div className="md:max-w-screen-desktop w-full lg:px-0 px-4 text-dark dark:text-white">
        <CmsInlineEdit type="list" cmsNode={{children: titleCollection()}} showContent={false} showChilds>
          <div className="flex md:gap-10 gap-4 mt-4 font-filsonProRegular  overflow-x-auto no-scrollbar ease-in">
            {menuItems &&
              menuItems.map((item, index) => (
                <div
                  id="titleKey"
                  role="button"
                  tabIndex={index}
                  key={item.title?.value}
                  onClick={() => setSelectedIndex(index)}
                  onKeyDown={() => setSelectedIndex(index)}
                  className={`
                ${
                  index === selectedItem
                    ? " border-dark dark:border-white opacity-100"
                    : " border-transparent opacity-50 "
                }
                h-[52px] w-auto cursor-pointer hover:opacity-100 
                hover:font-filsonProBold border-b-4 hover:border-dark
                dark:hover:border-white transition duration-300 ease-in text-lg
                `}
                >
                  <nobr>{item.title?.value}</nobr>
                </div>
              ))}
          </div>
        </CmsInlineEdit>
      </div>

      <div className="flex justify-between w-full relative">
        <div className="w-full">
          <CollectionSlider
            data={collections}
            onChange={setSelectedIndex}
            selectedIndex={selectedItem}
            openDeliveryWidget={openDeliveryWidget}
          />
        </div>

        <div className="lg:block hidden absolute lg:top-0 -translate-x-0 traslate-y-[-50%] left-0">
          <img src={ShadowLeftIcon} alt="shodow-left" className="opacity-50" />
        </div>

        <div className="lg:block hidden absolute lg:top-0 -translate-x-0 traslate-y-[-50%] right-0">
          <img
            src={ShadowRightIcon}
            alt="shodow-right"
            className="opacity-50"
          />
        </div>
      </div>
    </div>
  );
}

Collections.propTypes = {
  contents: PropTypes.shape({}),
  openDeliveryWidget: PropTypes.func
};

Collections.defaultProps = {
  contents: null,
  openDeliveryWidget: null
};

export default Collections;
