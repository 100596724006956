/* eslint-disable react/prop-types */

import React, { Component }from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Input from '../../../components/Input/Input';
import styles from '../ReviewOrder.module.css';
import CheckBox from '../../../components/CheckBox/CheckBox';
import * as elementActions from '../../../redux/actions/elements';
import { recipientMessageLength, orderTypeIds, instructionsLength, CONTACTLESS_TEXT, HAND_IT_TO_ME_TEXT } from '../../../shared/constants/order';
import {clearCart} from "../../../shared/utilities/orders";
import endpoints from "../../../shared/constants/endpoints";
import CheckoutContainer from "../../../components/CheckOutContainer/CheckoutContainer";
import * as checkoutActions from "../../../redux/actions/checkout";
import { routeCountryPath } from '../../../shared/utilities/common';
import TagSelector from '../../../components/OrderExperience/TagSelector';

export class Instructions extends Component {

    isContactLessTagSelectorValues = [
      {
        id: 0,
        title: HAND_IT_TO_ME_TEXT,
      },
      {
        id: 1,
        title: CONTACTLESS_TEXT,
      },
    ];

    constructor(props) {
        super(props);

        const { order } = this.props;

        this.state = {
            orderInstructions: order.comment,
            includeSpoon: order.includeSpoon,
            deliveryNotesOpen: true,
            isContactlessDelivery: true
        }
    }

    componentDidMount() {
      this.loadInstructions();
    }

    getOrderComment = (checkout, orderInstructions, userAddress) => {
      const legacyComment = checkout.orderInstructions || orderInstructions;
      const commentFromSavedAddress = userAddress?.savedLocation?.locationData?.comment;
      if (commentFromSavedAddress) {
        return commentFromSavedAddress;
      }
      return legacyComment;
    }

    loadInstructions = () => {
      const { checkout, checkoutUpdated, component } = this.props;
      const { orderInstructions, isContactlessDelivery } = this.state;
      const { userAddress } = component.props;
      
      const orderComment = this.getOrderComment(checkout, orderInstructions, userAddress);
      const isContactless = checkout?.isContactlessDelivery ?? userAddress?.savedLocation?.locationData?.isContactless ?? isContactlessDelivery;
  
      this.setState({ orderInstructions: orderComment, isContactlessDelivery: isContactless }, () => {
          checkoutUpdated({ ...checkout, orderComment, isContactlessDelivery: isContactless });
      });
    }

    handleInput = (maxLength) => (e) => {
      const { value } = e.target;
      if (value.length > maxLength) return;
      const {checkout,checkoutUpdated} = this.props;
  
      this.setState({ orderInstructions: value }, () => {
        checkoutUpdated({ ...checkout, orderInstructions: value });
      });
    };

    setFailedOrder = () => {
      const { component } = this.props;
      clearCart();
      component.props.history.push(routeCountryPath(endpoints.getMenuUrl(null)))
    }

    onExpandDeliveryNotes = (expand) => {
        this.setState({ deliveryNotesOpen: expand });
    }


    setOrderComments = async () => {
        const { orderInstructions } = this.state;
        const { checkout, checkoutUpdated } = this.props;

        checkout.orderComment = orderInstructions;
        checkoutUpdated(checkout);
    }

    setContactlessDelivery = async (value) => {
        const { checkout, checkoutUpdated } = this.props;
        const {isContactlessDelivery} = this.state;
        
        const selected = value.length > 0 ? value[0] : +isContactlessDelivery;
        this.setState({
            isContactlessDelivery: !!selected
        })
        checkout.isContactlessDelivery = !!selected;
        checkoutUpdated(checkout);
    }

    toggleIncludeSpoon = () => {
        const { includeSpoon } = this.state;
        const { checkout, checkoutUpdated } = this.props;

        this.setState({
            includeSpoon: !includeSpoon
        });

        checkout.includeSpoon = !includeSpoon;
        checkoutUpdated(checkout);
    }

    render () {
        const { orderInstructions, deliveryNotesOpen, includeSpoon, isContactlessDelivery } = this.state;
        const { order, checkout, selectedStore } = this.props;
        const instructionLength = orderInstructions ? orderInstructions.length : 0;
        const isDelivery = parseInt(order.orderTypeId, 10) === orderTypeIds.delivery;
        const maxInstructLength = isDelivery? recipientMessageLength.anotherOrder : instructionsLength.defaultLength;

        const isContactlessVisible = selectedStore?.showContactless;

        if (!isDelivery) return null;
  
        return (
          <CheckoutContainer
            header="Delivery notes"
            subHeader="Enter delivery notes (e.g. security code, building details)"
            subHeaderClass={styles.deliveryNotesSubTitle}
            onExpand={val => this.onExpandDeliveryNotes(val)}
            expanded={deliveryNotesOpen}
            className={styles.minHeight}
          >
            <p
              className={[
                styles.checkoutSectionHeader,
                styles.sectionHeader,
                "text-dark dark:text-white"
              ].join(" ")}
            >
              Delivery Preferences
            </p>

            { isContactlessVisible ? (
              <div className="flex flex-col gap-2">
                <TagSelector
                  isMultiple={false}
                  className={[].join(' ')}
                  selected={[+isContactlessDelivery]}
                  metatags={this.isContactLessTagSelectorValues}
                  onChange={this.setContactlessDelivery} />
              </div>
            ) : null }
            <Input
              isTextArea
              value={checkout.orderInstructions || orderInstructions}
              handleInput={this.handleInput(maxInstructLength)}
              handleOnBlur={this.setOrderComments}
              customContainerStyles={styles.inputContainer}
              placeholder=" "
              label="Add instructions for your order"
            />
            <span className={styles.selectionMinor}>
              {`${instructionLength}/${maxInstructLength} characters`}
            </span>
            <div
              className={`${styles.floatRight} w-full flex items-end justify-end`}
            >
              <CheckBox
                onClick={() => this.toggleIncludeSpoon()}
                isChecked={checkout.includeSpoon || includeSpoon}
                label="Include Spoon"
              />
            </div>
          </CheckoutContainer>
        );
    };
}

Instructions.defaultProps = {
    checkout: {},
};

Instructions.propTypes = {
    order: PropTypes.shape().isRequired,
    checkout: PropTypes.shape(),
    checkoutUpdated: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  const { selectedStore } = state.user;
    return {
        order: state.order,
        checkout: state.checkout,
        selectedStore
    }
}

export const mapDispatchToProps = (dispatch) => ({
    checkoutUpdated: (value) => dispatch(checkoutActions.checkoutUpdated(value)),
    setModalObject: (value) => dispatch(elementActions.setModalObject(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Instructions);