export const LABELS = {
  ORDERS: "Orders",
  SIGN_IN: "Sign in / Join Rewards",
  SIGN_IN_MOBILE: "Sign in",
  COOKIE_MAGIC: "CookieMagic",
  REWARDS: "Rewards",
  GUEST_REWARDS: "Insomnia Rewards",
  SIGN_IN_TAB: "Sign In",
  SIGN_UP_TAB: "Join Rewards",
  SHIPPING: "Shipping",
};
