import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./LocationLabelWidget.module.css";
import LocationLabelItem from "./LocationLabelItem";

class LocationLabelWidget extends Component {

  constructor(props) {
    super(props);
    this.state = {
        
    };
  }

  render() {
    const { className, labels, onLabelClick, onSetAddressClick } = this.props;
    const isLabelsExists = labels && labels.length > 0;
    return (
      <div className={[className, styles.labelsContainer].join(' ')}>
        { isLabelsExists ? labels.map(label => <LocationLabelItem
            key={label.id}
            label={label}
            onLabelClick={onLabelClick}
            onSetAddressClick={onSetAddressClick} />) : null }
      </div>
    );
  }
}

LocationLabelWidget.propTypes = {
  className: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      displayString: PropTypes.string,
    })),
  })).isRequired,
  onLabelClick: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  onSetAddressClick: PropTypes.func,
};

LocationLabelWidget.defaultProps = {
  className: "",
  onLabelClick: null,
  onSetAddressClick: () => {},
};

export default LocationLabelWidget;
