const initialState = {
  isFeedbackFormsEnabled: false,
  dataCollectionDelay: 0,
};

export default (state = initialState, { type, value }) => {
  switch (type) {
    case "FEEDBACK_FORMS_ENABLED":
      return {
        ...state,
        isFeedbackFormsEnabled: value
      };
    case "DATA_COLLECTION_DELAY":
      return {
        ...state,
        dataCollectionDelay: value
      };
    default:
      return state;
  }
};
