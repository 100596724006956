import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink } from "@apollo/client";
import { createUploadLink } from 'apollo-upload-client';
import Appboy from "@braze/web-sdk";
import fetch from 'cross-fetch';
import { setContext } from "@apollo/client/link/context";
import { onError } from '@apollo/client/link/error';
import { RetryLink } from "@apollo/client/link/retry";
import * as userActions from '../../redux/actions/user';
import * as elementActions from '../../redux/actions/elements';
import * as orderActions from '../../redux/actions/order';
import { persistor, store } from "../../redux/store";
import { REACT_APP_ENV } from "../constants/environments";
import windowNames from "../constants/windowNames";
import customHeaders from "../constants/customHeaders";
import logger from './logger';
import ddLog from './datadog/log';
import { redirectToOnError } from "./common"; // eslint-disable-line
import { defaultLabels as loginDefaultLabels } from "../constants/login";
import { ERROR_CODES } from "../constants/common";

export const bootstrapEnvironment = () => {

  window.environment = window.environment || {};

  if (window.environment.initialized !== "true") {

      for (let key of Object.keys(window.environment)) { // eslint-disable-line

      window.environment[key] = process.env[key];
    }

    window.environment.initialized = true;
  }
}

const isLogAllRequests = () => {
  return window.environment.REACT_APP_CONSOLE_LOG_ALL_REQUESTS === "true"
    && window.environment.REACT_APP_ENV !== REACT_APP_ENV.production;
}

bootstrapEnvironment();

const cache = new InMemoryCache({
  addTypename: false
});
const uploadLink = createUploadLink({
  uri: window.environment.REACT_APP_GRAPHQL_URI,
  fetch
});

const httpLink = createHttpLink({
  uri: window.environment.REACT_APP_GRAPHQL_URI,
  fetch
});

export const headersLinkCallback = async(graphQLRequest, { headers }) => {
  const state = store.getState();
  const token = state.user.userToken;
  const orderCode = state.user.userCartId;
  const { location } = state.user;
  const { autocompleteSessionToken, verificationToken, verificationAction, verificationProvider } = state.elements;

  const result = {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'Order-Code': orderCode ?? '',
      'App-Version': window.environment.REACT_APP_BUILD_ID,
      'location': (location.lat && location.lng) ? `${location.lat}, ${location.lng}` : '',
      'Consumer-Platform': 'WEBSITE',
      'autocomplete-token': autocompleteSessionToken,
      'verification-token': verificationToken,
      'verification-action': verificationAction,
      'verification-provider': verificationProvider,
      [customHeaders.MARKETING_CONTENT_CARD_SESSION]: 0
    }
  };

  if (state.country?.selectedCountry?.id) {
    result.headers['selected-country'] = state.country?.selectedCountry?.id;
  }

  const inContentCardSession = (
    state.braze.contentCardSession ||
        window.name === windowNames.CONTENT_CARD_SESSION
  ) ? 1 : 0;

  return new Promise((resolve) => {
    try {
      Appboy.getDeviceId((brazeId) => {
        const isLoggedIn = state?.user?.userId;
        if (isLoggedIn) {
          Appboy.changeUser(state.user.userId);
          result.headers[customHeaders.MARKETING_USER_ID] = state.user.userId;
        } else {
          Appboy.getUser().addAlias(customHeaders.BRAZE_ALIAS, brazeId)
        }

        result.headers[customHeaders.BRAZE_ALIAS] = brazeId; 
        result.headers[customHeaders.MARKETING_CONTENT_CARD_SESSION] = inContentCardSession;
        resolve(result);

        window.localStorage.setItem('brazeAlias', result.headers[customHeaders.BRAZE_ALIAS]);
        window.localStorage.setItem('marketingUserId', result.headers[customHeaders.MARKETING_USER_ID]);
      });
    } catch (e) {
      resolve(result);
    }
  });
}

const retryOperations = {
  'SITE_SETTINGS': 'SITE_SETTINGS',
};

const retryOperationsCount = {
  SITE_SETTINGS: 0,
};
const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    if (Object.keys(retryOperations).indexOf(operation.operationName) === -1) {
      return false;
    }

    const maxCount = Number(window.environment.REACT_APP_RESEND_REQUEST_COUNT) - 1;
    if (count > maxCount) {
      return false;
    }

    retryOperationsCount[operation.operationName] = count;

    return !!error;
  },
  delay: (count) => {
    return count * Number(window.environment.REACT_APP_RESEND_REQUEST_TIMEOUT) * 1000 * Math.random();
  },
});

export const errorsLinkCallback = (error) => {
  const { graphQLErrors, networkError, operation } = error;
  logger.logApiError(error, store.getState());
  ddLog.logApiError(error, store.getState());

  const maxCount = Number(window.environment.REACT_APP_RESEND_REQUEST_COUNT) - 1;

  if (
    Object.keys(retryOperations).indexOf(operation.operationName) >= 0 &&
    retryOperationsCount[operation.operationName] !== undefined &&
    retryOperationsCount[operation.operationName] < maxCount
  ) {
    return;
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(err => {
      if(err.extensions?.category === 'authentication') {
        store.dispatch(userActions.setUserToken(null))
        persistor.purge().then(async () => {
          store.dispatch(userActions.resetUserData())
        })
        window.location.href = loginDefaultLabels.LOGIN_ROUTE;
      }
      const isWrongOrder = err.extensions?.reason === ERROR_CODES.ORDER_ALREADY_COMPLETED || err.extensions?.reason === ERROR_CODES.CAN_NOT_LOCATE_ORDER;
      if (isWrongOrder) {
        store.dispatch(orderActions.voidOrder());
        persistor.purge().then(async () => {
          store.dispatch(userActions.resetWithoutToken())
        })
        window.location.href = '/';
      }
    });
  }

  if (isLogAllRequests()) {
    const { operationName } = operation;
    if (networkError) {
      // eslint-disable-next-line no-console
      console.warn(`NetworkError Response ID: ${operation.requestId}, request: ${operationName}, statusCode: ${networkError.statusCode}, response: ${JSON.stringify(networkError.result, null, 2)}`);
    }

    if (graphQLErrors) {
      // eslint-disable-next-line no-console
      console.warn(`GraphQLError Response ID: ${operation.requestId}, request: ${operationName}`);
    }
  }
};

const errorsLink = onError(errorsLinkCallback);
const headersLink = setContext(headersLinkCallback);

let requestIdCounter = 0;
const logRequests = new ApolloLink((operation, forward) => {
  requestIdCounter += 1;
  // eslint-disable-next-line no-param-reassign
  operation.requestId = `${Date.now()}-${requestIdCounter}`;

  const { operationName, variables } = operation;
  // eslint-disable-next-line no-console
  console.warn(`Request ID: ${operation.requestId} - ${operationName} with variables: ${JSON.stringify(variables, null, 2)}`);

  return forward(operation).map((data) => {
    // eslint-disable-next-line no-console
    console.warn(`Response ID: ${operation.requestId}, request: ${operationName}, result: ${JSON.stringify(data, null, 2)}`);
    return data;
  });
});

const linksArray = [
  retryLink,
  errorsLink,
  headersLink,
  uploadLink || httpLink
];

if (isLogAllRequests()) {
  linksArray.unshift(logRequests);
}

export const apolloConfig = {
  cache,
  link: ApolloLink.from(linksArray),

  defaultOptions: {
    mutate: {
      fetchPolicy: 'no-cache'
    },
  }
};

export const client = new ApolloClient(apolloConfig);

export const isRecaptcha = () => {
  const { useRecaptcha } = store.getState().elements;
  return (useRecaptcha === 1);
}

export const setSessionToken = (value) => {
  const { autocompleteSessionToken } = store.getState().elements;
  if (!autocompleteSessionToken) {
    store.dispatch(elementActions.setAutocompleteSessionToken(value));
  }
}

export const appboy = Appboy;
