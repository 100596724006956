import React from 'react';
import widgetStyles from '../OrderDetailsWidgets/OrderDetailsWidget.module.css';
import modalStyles from '../Modal/Modal.module.css';
import moment from 'moment';
import { orderTypeIds, timeDefaults } from '../../shared/constants/order';

export const getOrderExperienceRatingModalTitleAndSubtitle = (order, timeFormat = "MMMM Do [at] h:mmA") => {
  if (order.orderTypeId === orderTypeIds.pickup) {
    return (
      <div className={[widgetStyles.contentCell, modalStyles.titleCell, 'text-dark dark:text-white'].join(' ')}>
        <div className={modalStyles.rateModalTitle}>Rate your order from <span>{ order?.store?.name }</span></div>
        <div className={[modalStyles.rateModalSubTitle, modalStyles.rateModalSubTitleBig].join(' ')}>
          { order.deliveryTime && order.deliveryTime !== timeDefaults.asap ? moment(order.deliveryTime).format(timeFormat) : null }
        </div>
      </div>
    );
  } else {
    return (
      <div className={[widgetStyles.contentCell, modalStyles.titleCell, 'text-dark dark:text-white'].join(' ')}>
        <div className={modalStyles.rateModalTitle}>Rate your order to <span>{ order?.shippingAddress?.address1 }</span></div>
        <div className={[modalStyles.rateModalSubTitle, modalStyles.rateModalSubTitleBig].join(' ')}>
          { order?.deliveryStatus?.dropoffTime ? moment(order?.deliveryStatus?.dropoffTime).format(timeFormat) : null }
        </div>
      </div>
    );
  }
}