/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "underscore";
import { connect } from "react-redux";

import styles from "../../../pages/Product/CustomizeProduct/CustomizeProduct.module.css";
import CustomProductOption from "../../CustomProductOption/CustomProductOption";

/* CONSTANTS */
import { defaultCurrency } from "../../../shared/constants/currency";
import { OPTION_GROUP_TYPE } from "../../../shared/constants/product";

class UpsellProductItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customizeProducts: props.customizeProducts ?? {}
    };
    this.productOptionElement = React.createRef();
    props.setElement(this.productOptionElement);
    this.handleChange = this.handleChange.bind(this);
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      customizeProducts: props.customizeProducts
    });
  }

  renderCustomizeHeader = (
    option,
    actionLabel,
    action,
    buttonLabel,
    buttonAction
  ) => {
    const { header, maxOptions, minOptions, groupTitle } = option;
    const maxQuantity = maxOptions && maxOptions > 0 ? maxOptions : null;
    const minQuantity = minOptions && minOptions > 0 ? minOptions : null;
    const num = minQuantity || maxQuantity;

    let title;
    let groupDesc;
    if (!num) {
      title = header;
    } else {
      const str = `${header}s`;
      title = minQuantity
        ? `Select  (${minQuantity}) ${str}`
        : `Choose up to (${maxQuantity}) ${str}`;
    }
    if (groupTitle) {
      groupDesc = minQuantity
        ? `Select  (${minQuantity}) ${groupTitle}`
        : `Choose up to (${maxQuantity}) ${groupTitle}`;
    }

    return (
      <div className={styles.customizeSectionHeader}>
        <div>
          {
            <p className=" font-congenialBlack text-dark dark:text-white leading-7 tracking-[-0.2px]">
              {groupDesc ?? title}
            </p>
          }
          {" "}
          {actionLabel ? <span onClick={action}>{actionLabel}</span> : ""}
        </div>

        {buttonLabel ? (
          <button
            onClick={buttonAction}
            type="button"
            className={styles.headerButton}
          >
            Pick for me
          </button>
        ) : null}
      </div>
    );
  };

  /* eslint-disable no-unused-expressions */

  selectLargeOnChange = (option, largeOption) => {
    const { customizeProducts } = this.state;

    const optionRemoved =
      customizeProducts[option.id] &&
      customizeProducts[option.id].length > 0 &&
      customizeProducts[option.id].includes(largeOption.id);
    const optionAdded =
      !customizeProducts[option.id] ||
      customizeProducts[option.id].length < option.minOptions;
    const optionChanged =
      customizeProducts[option.id] &&
      !customizeProducts[option.id].includes(largeOption.id);

    if (optionRemoved) {
      customizeProducts[option.id].pop();
    } else if (optionAdded) {
      !customizeProducts[option.id]
        ? (customizeProducts[option.id] = [largeOption.id])
        : customizeProducts[option.id].push(largeOption.id);
    } else if (optionChanged) {
      customizeProducts[option.id].pop();

      customizeProducts[option.id] = [largeOption.id];
    }
    /* eslint-disable no-unused-expressions */
    this.handleChange(customizeProducts);
  };

  /* eslint-disable no-unused-expressions */

  primaryOnChangeAdd = (option, primaryOption) => {
    const { customizeProducts } = this.state;
    let optionAdded = false;

    if (option.minOptions) {
      optionAdded =
        !customizeProducts[option.id] ||
        customizeProducts[option.id].length < option.minOptions;
    }

    if (option.maxOptions) {
      optionAdded =
        !customizeProducts[option.id] ||
        customizeProducts[option.id].length < option.maxOptions;
    }

    if (optionAdded) {
      !customizeProducts[option.id]
        ? (customizeProducts[option.id] = [primaryOption.id])
        : customizeProducts[option.id].push(primaryOption.id);
    }
    /* eslint-disable no-unused-expressions */
    this.handleChange(customizeProducts);
  };

  /* eslint-disable no-unused-expressions */
  primaryOnChangeRemove = (option, primaryOption) => {
    const { customizeProducts } = this.state;

    const optionRemoved =
      customizeProducts[option.id] && customizeProducts[option.id].length > 0;

    if (optionRemoved) {
      const indexToRemove = customizeProducts[option.id].findIndex(
        el => el === primaryOption.id
      );

      if (indexToRemove !== -1) {
        customizeProducts[option.id].splice(indexToRemove, 1);

        if (customizeProducts[option.id].length <= 0) {
          delete customizeProducts[option.id];
        }
      }
      /* eslint-disable no-unused-expressions */
      this.handleChange(customizeProducts);
    }
  };

  selectSmallOnChange = (option, smallOption) => {
    const { customizeProducts } = this.state;

    const optionRemoved =
      customizeProducts[option.id] &&
      customizeProducts[option.id].includes(smallOption.id);
    const optionAdded =
      !customizeProducts[option.id] ||
      customizeProducts[option.id].length < option.minOptions;
    const optionChanged =
      customizeProducts[option.id] &&
      customizeProducts[option.id].length < option.maxOptions;

    if (optionRemoved) {
      customizeProducts[option.id].splice(
        customizeProducts[option.id].findIndex(el => el === smallOption.id),
        1
      );
    } else if (optionAdded) {
      !customizeProducts[option.id]
        ? (customizeProducts[option.id] = [smallOption.id])
        : customizeProducts[option.id].push(smallOption.id);
    } else if (optionChanged) {
      customizeProducts[option.id].push(smallOption.id);
    } else {
      customizeProducts[option.id].shift();

      customizeProducts[option.id].push(smallOption.id);
    }
    /* eslint-disable no-unused-expressions */
    this.handleChange(customizeProducts);
  };

  sizeSelectOnClick = (option, size) => {
    const { customizeProducts } = this.state;
    const hasOption = customizeProducts[option.id] &&
                      customizeProducts[option.id].length > 0 &&
                      customizeProducts[option.id].includes(size.id);
    if (hasOption) {
      customizeProducts[option.id].pop();
    } else if (
      !customizeProducts[option.id] ||
      customizeProducts[option.id].length < option.minOptions
    ) {
      customizeProducts[option.id] = [size.id];
    }
    this.handleChange(customizeProducts);
  };

  isSelected = (option, sizeOption) => {
    const { customizeProducts } = this.state;

    return customizeProducts
      ? customizeProducts[option.id] &&
          customizeProducts[option.id].includes(sizeOption.id)
      : false;
  };

  handleChange(customizeProducts) {
    const { onOptionChange } = this.props;

    this.setState({
      customizeProducts
    });

    onOptionChange(customizeProducts);
  }

  transformIceCreamOptionGroup() {
    const { options } = this.props;
    const ICE_CREAM_FLAVOR = "Ice Cream Flavor";

    const copyOptions = options.map(item => ({
      ...item,
      optionGroupType:
        item.header === ICE_CREAM_FLAVOR ? "SELECT_SMALL" : item.optionGroupType
    }));
    return copyOptions;
  }

  render() {
    const { currency, upsellIndex, customizeProducts, isCaloriesActive } = this.props;
    const productOptions = this.transformIceCreamOptionGroup();

    // const options = productOptions.filter(item => item.optionGroupType !== 'PRIMARY')
    const options = productOptions;

    return (
      <div
        ref={this.productOptionElement}
        className={`${styles.customizeSectionContainer} mx-auto`}
      >
        {options
          .filter(item => item.options.length > 0)
          .map((option, index) => {
            const { maxOptions, minOptions, optionGroupType } = option;
            const groupType = optionGroupType.toLowerCase();
            return (
              <div
                key={option.header}
                className={`productOptions--${groupType} mt-8 ${
                  upsellIndex === index ? "" : "hidden"
                }`}
                data-min={minOptions}
                data-max={maxOptions}
              >
                {this.renderCustomizeHeader(option)}
                {/* eslint-disable no-nested-ternary */}
                {option.optionGroupType === OPTION_GROUP_TYPE.PRIMARY ? (
                  <div
                    className={`${styles.customizeSectionRow} grid grid-flow-row-dense lg:grid-cols-3 md:grid-cols-4`}
                  >
                    {option.options.map(primaryOption => {
                      const displayQuantity = customizeProducts
                        ? _.countBy(customizeProducts[option.id])
                        : 0;
                      const calories =
                        isCaloriesActive && primaryOption.calories != null
                          ? `${primaryOption.calories} kCal`
                          : ``;
                      return (
                        <CustomProductOption
                          key={primaryOption.title}
                          image={primaryOption.imageSmall}
                          name={primaryOption.title}
                          onChangeAdd={() =>
                            this.primaryOnChangeAdd(option, primaryOption)}
                          onChangeRemove={() =>
                            this.primaryOnChangeRemove(option, primaryOption)}
                          quantity={displayQuantity[primaryOption.id]}
                          type="quantity"
                          price={primaryOption.price}
                          calories={calories}
                          currency={currency}
                          description={primaryOption.shortDescription}
                        />
                      );
                    })}
                  </div>
                ) : /* eslint-disable no-nested-ternary */
                option.optionGroupType === OPTION_GROUP_TYPE.SELECT_SMALL ? (
                  <div
                    id={styles.customizeSelector}
                    className={`${styles.customizeSectionRowSelector} md:grid grid-cols-2 gap-6`}
                  >
                    {option.options.map(smallOption => {
                      const calories =
                        isCaloriesActive && smallOption.calories != null
                          ? `${smallOption.calories} kCal`
                          : ``;
                      return (
                        <CustomProductOption
                          key={smallOption.title}
                          isSelected={this.isSelected(option, smallOption)}
                          onChange={() =>
                            this.selectSmallOnChange(option, smallOption)}
                          name={smallOption.title}
                          type="selector"
                          price={smallOption.price}
                          optionsCount={option.options.length}
                          calories={calories}
                          currency={currency}
                          description={smallOption.shortDescription}
                        />
                      );
                    })}
                  </div>
                ) : /* eslint-disable no-nested-ternary */
                option.optionGroupType === OPTION_GROUP_TYPE.SIZE_SELECT ? (
                  <div className={styles.listOptionWrapper}>
                    {option.options.map(size => {
                      return (
                        <div
                          key={size.title}
                          onClick={() => this.sizeSelectOnClick(option, size)}
                          className={[
                            styles.listOptionContainer,
                            this.isSelected(option, size)
                              ? styles.listOptionSelected
                              : undefined
                          ].join(" ")}
                        >
                          <p>{size.title}</p>
                        </div>
                      );
                    })}
                  </div>
                ) : option.optionGroupType === OPTION_GROUP_TYPE.SELECT_LARGE ? (
                  <div
                    className={`${styles.customizeSectionRow} grid grid-flow-row-dense lg:grid-cols-3 md:grid-cols-4`}
                  >
                    {option.options.map(largeOption => {
                      const calories =
                        isCaloriesActive && largeOption.calories != null
                          ? `${largeOption.calories} kCal`
                          : ``;
                      return (
                        <CustomProductOption
                          key={largeOption.title}
                          onChange={() =>
                            this.selectLargeOnChange(option, largeOption)}
                          isSelected={this.isSelected(option, largeOption)}
                          image={largeOption.imageSmall}
                          name={largeOption.title}
                          type="single"
                          price={largeOption.price}
                          calories={calories}
                          currency={currency}
                          description={largeOption.shortDescription}
                        />
                      );
                    })}
                  </div>
                ) : null}
              </div>
            );
          })}
      </div>
    );
  }
}

UpsellProductItem.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      optionGroupType: PropTypes.string.isRequired
    })
  ).isRequired,
  onOptionChange: PropTypes.func.isRequired,
  setElement: PropTypes.func,
  customizeProducts: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    )
  ),
  isCaloriesActive: PropTypes.bool,
  currency: PropTypes.objectOf(PropTypes.string),
  upsellIndex:PropTypes.number
};

UpsellProductItem.defaultProps = {
  customizeProducts: null,
  setElement() {},
  isCaloriesActive: false,
  currency: defaultCurrency,
  upsellIndex: 0
};

export default withRouter(connect()(UpsellProductItem));
