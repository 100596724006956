import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { routeCountryPath } from "../../shared/utilities/common";
import { store } from "../../redux/store";

import styles from "./LoyaltyReward.module.css";
import LoyaltyDeals from "./LoyaltyDeals";
import LoyaltyRedeem from "./LoyaltyRedeem";
import LoyaltyPointTracker from "./LoyaltyPointTracker";

import { PUNCHH } from "../../shared/constants/loyaltyType";
import { orderMethods } from "../../shared/constants/order";
import * as loyaltyRepo from "../../shared/repos/graphql/loyalty";
import { shippingNotAllowToLoyaltyProduct } from "../../shared/utilities/modals";

import * as loyaltyAction from '../../redux/actions/loyalty';
import * as addressActions from "../../redux/actions/address";
import * as elementsActions from "../../redux/actions/elements";

import DeliveryPickUpWidget from "../../components/DeliveryPickUpWidget/DeliveryPickUpWidget";


class LoyaltyReward extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeliveryWidget: false,
      addressType: "Delivery"
    };
  }

  async componentDidMount() {
    const { history, userToken, activeLoyalty, setRedeemableItem } = this.props;
    
    if (activeLoyalty !== PUNCHH) {
      history.push(routeCountryPath("/"));
      return;
    }

    if (userToken === null) {
      history.push(routeCountryPath("/login"));
      return;
    }

    await this.getLoyaltyPoints();
    
    setRedeemableItem({
      redeemableType: null,
      redeemableId: null
    })
  }

  getLoyaltyPoints = async () => {
    const response = await loyaltyRepo.getLoyaltyProfile();
    this.getRemainingPointBalance(response?.data?.loyaltyProfile?.points);    
  };

  getRemainingPointBalance = (points) => {
    const { order } = this.props;
    let loyaltyPoints = points;
    if (order && order?.orderRedeemables?.length > 0) {
      const usedPoints = order?.orderRedeemables?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.points;
      }, 0);

      loyaltyPoints = points - usedPoints;
    }

    store.dispatch(
      loyaltyAction.loyaltyPoints(loyaltyPoints)
    );
  }

  openDeliveryWidget = link => {
    const {
      userOrderMethod,
      setModalObject,
      userAddress,
      history,
      setRedirectUrl
    } = this.props;

    const shippingNotAllowedForLoyalty = userOrderMethod === orderMethods.shipping;
    if (shippingNotAllowedForLoyalty) {
      setModalObject(
        shippingNotAllowToLoyaltyProduct(() => {
          setRedirectUrl(link);
          this.setState({
            showDeliveryWidget: true
          });
        }, this.onCloseWidget)
      );
    } else if (!userAddress) {
      setRedirectUrl(link);
      this.setState({
        showDeliveryWidget: true
      });
    } else {
      history.push(link);
    }
  };

  onCloseWidget = () => {
    this.setState({
      showDeliveryWidget: false
    });
  };

  render() {
    const { showDeliveryWidget, addressType } = this.state;

    const { loyaltyPoints, loyaltyRedeemables, setRedeemableItem } = this.props;
    return (
      <div className={styles.pageWrapper}>
        <LoyaltyPointTracker points={loyaltyPoints} redeemables={loyaltyRedeemables} />

        <div
          className={`${styles.pageContainer} w-full mx-auto py-12 flex flex-col gap-6`}
        >
          <LoyaltyDeals
            points={loyaltyPoints}
            openDeliveryWidget={this.openDeliveryWidget}
            setRedeemableItem={setRedeemableItem}
          />

          <LoyaltyRedeem
            points={loyaltyPoints}
            openDeliveryWidget={this.openDeliveryWidget}
            setRedeemableItem={setRedeemableItem}
          />
        </div>

        <div id="deliveryPickUpWidget">
          {showDeliveryWidget ? (
            <DeliveryPickUpWidget
              onClose={this.onCloseWidget}
              onAddressType={address => {
                this.setState({
                  addressType: address
                });
              }}
              addressType={addressType}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

LoyaltyReward.propTypes = {
  history: PropTypes.shape().isRequired,
  userToken: PropTypes.string,
  userAddress: PropTypes.string,
  loyaltyPoints: PropTypes.number,
  setRedirectUrl: PropTypes.func.isRequired,
  activeLoyalty: PropTypes.string,
  loyaltyRedeemables: PropTypes.shape(),
  setModalObject: PropTypes.func.isRequired,
  userOrderMethod: PropTypes.string,
  setRedeemableItem: PropTypes.func,
  order: PropTypes.shape(),
};

LoyaltyReward.defaultProps = {
  userAddress: null,
  loyaltyPoints: null,
  activeLoyalty: null,
  loyaltyRedeemables: null,
  userOrderMethod: null,
  setRedeemableItem: () => {},
  userToken: null,
  order: null
};

export const mapStateToProps = state => {
  const { userToken, userAddress, userCart, userOrderMethod } = state.user;
  const {
    loyaltyRedeemables,
    loyaltyDeals,
    loyaltyPoints,
    activeLoyalty
  } = state.loyalty;
  return {
    userToken,
    userAddress,
    userCart,
    loyaltyRedeemables,
    loyaltyDeals,
    loyaltyPoints,
    activeLoyalty,
    userOrderMethod
  };
};

export const mapDispatchToProps = dispatch => ({
  setRedirectUrl: value => dispatch(addressActions.setRedirectUrl(value)),
  setModalObject: value => dispatch(elementsActions.setModalObject(value)),
  setRedeemableItem: value => dispatch(loyaltyAction.setRedeemableItem(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyReward);
