/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./LocationLabelSelector.module.css";
import { getLocationLabels } from "../../shared/repos/graphql/user";
import CheckBox from "../CheckBox/CheckBox";
import { getLocationLabelImageByLabelId } from "../../shared/utilities/common";
import LocationLabelSelector from "./LocationLabelSelector";

class LocationLabelSelectorWidget extends Component {

  constructor(props) {
    super(props);
    this.state = {
      label: '',
      id: null,
      selectedId: null,
      commonLabels: [],
      checkbox: false,
      initialValue: null,
    };
  }

  async componentDidMount() {
    const { defaultValue } = this.props;
    const resp = await getLocationLabels(true, false);
    const { data } = resp;
    const defaultLabelVal = this.isCommonLabel(defaultValue, data.me.locationLabels) ? defaultValue.id : -1;
    const selectedId = defaultValue === null ? null : defaultLabelVal;
    this.setState({
      commonLabels: data.me.locationLabels,
      initialValue: defaultValue,
      label: defaultValue ? defaultValue.label : '',
      id: defaultValue? defaultValue.id : null,
      selectedId,
    });
  }

  isCommonLabel = (label, commonLabels = []) => {
    return !!label ? !!commonLabels.find((commonLabel) => commonLabel.id === label.id) : false;
  }

  toggleCheckbox = () => {
    const { onChange } = this.props;
    const { checkbox, initialValue, commonLabels } = this.state;
    const val = !checkbox;
    const defaultLabelValue = this.isCommonLabel(initialValue, commonLabels) ? initialValue.id : -1;
    const selectedId = initialValue === null ? null : defaultLabelValue;
    if (!val) {
      this.setState({
        label: initialValue ? initialValue.label : '',
        id: initialValue ? initialValue.id : null,
        selectedId,
      });
      onChange(undefined);
    } else {
      onChange(initialValue);
    }
    this.setState({
      checkbox: !checkbox,
    });
  }

  onCommonLabelClick = (label) => {
    const { onChange, defaultValue } = this.props;
    const { id, label: labelStr } = label;
    const defaultLabel = defaultValue ? defaultValue.label : '';
    const defaultId = defaultValue ? defaultValue.id : null;
    this.setState({
      id: id === -1 ? defaultId : id,
      label: id === -1 ? defaultLabel : labelStr,
      selectedId: id,
    });
    onChange(id === -1 ? defaultValue : label);
  }

  onCustomLabelChange = (label) => {
    const { onChange } = this.props;
    this.setState({
      label: label ? label.label : '',
      id: label ? label.id : null,
      selectedId: -1,
    });
    onChange(label);
  }

  renderLabelItem = (label) => {
    const { selectedId } = this.state;
    const image = getLocationLabelImageByLabelId(label.id);
    return (
      <div onClick={() => {this.onCommonLabelClick(label)}} className={[styles.labelCheckoutLabelItem, selectedId === label.id ? styles.selected : null].join(' ')} key={label.id}>
        <div className={styles.labelCheckoutLabelItemLabel}>{ label.label }</div>
        <div className={styles.labelCheckoutLabelItemImage}><img className="dark:image-negative" src={image} alt={label.label} /></div>
      </div>
    );
  }

  render() {
    const { className } = this.props;
    const { checkbox, commonLabels, selectedId, initialValue } = this.state;
    return (
      <div className={[styles.labelCheckoutSelectorContainer, className].join(' ')}>
        <CheckBox
          onClick={this.toggleCheckbox}
          isChecked={checkbox}
          label="Add label to address (home, school, etc)"
        />
        { checkbox ? (
          <div className={[styles.labelCheckoutLabelsBlock].join(' ')}>
            { commonLabels.map((commonLabel) => this.renderLabelItem(commonLabel)) }
            { this.renderLabelItem({label: 'Custom', id: -1}) }
          </div>) : null }

        { checkbox && selectedId === -1
          ? <LocationLabelSelector
            value={initialValue}
            placeholder="E.g. home, work, school etc"
            inputClassName={[styles.customLabelInput, 'dark:bg-darkDry dark:text-light'].join(' ')}
            onChange={this.onCustomLabelChange} /> : null }
      </div>
    );
  }
}

LocationLabelSelectorWidget.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
  }), PropTypes.oneOf([null])]),
};

LocationLabelSelectorWidget.defaultProps = {
  className: "",
  onChange: () => {},
  defaultValue: null,
};

export default LocationLabelSelectorWidget;
