/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import appleWalletSVG from "./imgs/addToAppleWallet.svg";
import * as walletRepo from "../../shared/repos/graphql/wallet";
import styles from "./AddToAppleWallet.module.css";
import { addToWalletResponseTypes, PLATFORMS } from "../../shared/constants/common";

class AddToAppleWallet extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      errorMessage: null,
    };
  }

  handleClick = async () => {
    const {processing} = this.state;
    if (processing) {
      return;
    }
    this.setState({ processing: true });
    try {
      const result = await walletRepo.addLoyaltyCardToWallet(PLATFORMS.IOS);
      if (result.data.addLoyaltyCardToWallet.success) {
        this.setState({
          processing: false,
          errorMessage: null,
        });
        this.handleAddToAppleWallet(result.data.addLoyaltyCardToWallet);
      } else {
        this.setState({
          processing: false,
          errorMessage: result.data.addLoyaltyCardToWallet.message,
        });
      }
    } catch (error) {
      this.setState({
        errorMessage: error.message,
        processing: false,
      });
    }
  }

  handleAddToAppleWallet = (response) => {
    if (response.responseType === addToWalletResponseTypes.FILE_BASE64_ENCODED) {
      const linkSource = `data:application/vnd.apple.pkpass;base64,${response.responseData}`;
      const downloadLink = document.createElement('a');
      const fileName = 'loyaltyCard.pkpass';
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      downloadLink.remove();
    }
  }

  render() {
    const { errorMessage, processing } = this.state;
    const processngStyle = processing ? styles.processing : '';
    return (
      <div className={styles.addToWalletButtonContainer}>
          <img onClick={this.handleClick} className={[styles.addToAppleWalletButton, processngStyle].join(' ')} src={appleWalletSVG} alt="Add To Apple Wallet" />
          { errorMessage ? (<div className={styles.errorMessage}>{errorMessage}</div>) : null }
      </div>
    );
  }
}
  
export default AddToAppleWallet;