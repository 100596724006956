/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as userActions from '../../redux/actions/user';
import { connect } from 'react-redux';
import SimpleModal from '../Modal/SimpleModal';
import SmileRatingWidget from './SmileRatingWidget';
import ExitIconSVG from '../Modal/imgs/exit-icon.svg';
import { deliveryStatuses, orderExperienceSources } from '../../shared/constants/order';
import modalStyles from '../Modal/Modal.module.css';
import widgetStyles from '../OrderDetailsWidgets/OrderDetailsWidget.module.css';
import * as elementsActions from "../../redux/actions/elements";
import { getOrderExperiences } from '../../shared/repos/graphql/orderExperience';
import { getOrderExperienceBySource, isOrderExpired } from '../../shared/utilities/common';
import log from '../../shared/utilities/datadog/log';
import * as orderExperiencesActions from "../../redux/actions/orderExperiences";
import { getOrderExperienceRatingModalTitleAndSubtitle } from './renderings';

class PostOrderExperienceModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderCodeForRate: null,
      order: null,
    }
  }

  componentDidMount() {
    this.init();
  };

  async checkOrder(orderCode) {
    const { setLastCompletedOrderCode } = this.props;
    try {
      const response = await getOrderExperiences(orderCode);
      const { order } = response.data;
      this.setState({
        order,
      });
      const isOrderCompleted = order?.status?.status >= deliveryStatuses.DRIVER_RETURNED;
      const isOrderExpiredVal = isOrderExpired(order, 30, 'days');
      const isAlreadyRated = getOrderExperienceBySource(order, orderExperienceSources.APP_LAUNCH_MODAL)
        || getOrderExperienceBySource(order, orderExperienceSources.CONFIRMATION_PAGE)
        || getOrderExperienceBySource(order, orderExperienceSources.ORDER_DETAILS);
      const isOrderValidForRate = isOrderCompleted && !isOrderExpiredVal && !isAlreadyRated;
      if (isOrderValidForRate) {
        this.setState({
          orderCodeForRate: orderCode,
          order,
        });
        setLastCompletedOrderCode(null);
      } else if (isAlreadyRated || isOrderExpiredVal) {
        this.setState({
          orderCodeForRate: null,
          order: null,
        });
        setLastCompletedOrderCode(null);
      }
    } catch (error) {
      log.logError('[PostOrderExperienceModal][checkOrder] Error when check an order for rating modal', {
        orderCode,
        error,
      });
      this.setState({
        orderCodeForRate: null,
        order: null,
      });
      setLastCompletedOrderCode(null);
    }
    return;
  };

  onRatingModalClose = () => {
    const { setLastCompletedOrderCode } = this.props;
    this.setState({
      orderCodeForRate: null,
    });
    setLastCompletedOrderCode(null);
  }

  onOrderExperienceRatingSubmited = (data) => {
    const { order } = this.state;
    const { setModalObject, setLastCompletedOrderCode, setOrderExperiences } = this.props;
    const orderExperiences = [...order.orderExperiences, data];
    this.setState({
      orderCodeForRate: null,
    });
    setOrderExperiences(orderExperiences);
    setLastCompletedOrderCode(null);
    setModalObject({
      title: 'Thank you for your feedback!',
      description: 'Your suggestions help us improve the experience for all Insomniacs.',
    });
  }

  init() {
    const { lastCompletedOrderCode } = this.props;
    if (lastCompletedOrderCode) {
      this.checkOrder(lastCompletedOrderCode);
    } else {
      this.setState({
        orderCodeForRate: null,
      });
    }
  };

  render() {
    const { orderCodeForRate, order } = this.state;
    const isModalShouldBeOpened = orderCodeForRate && order;
    return isModalShouldBeOpened ? (
      <SimpleModal>
        <div className={[
          modalStyles.simpleModalContainer,
          'dark:bg-dark',
        ].join(' ')}>
          <div className={[
            widgetStyles.orderDetailsLine,
            widgetStyles.orderDetailsLineTitle,
            widgetStyles.borderBottom
          ].join(" ")}>
            { getOrderExperienceRatingModalTitleAndSubtitle(order) }
            <div className={modalStyles.closeWrapper}>
              <img className={`dark:image-negative`} onClick={this.onRatingModalClose} src={ExitIconSVG} alt="Close" />
            </div>
          </div>
        </div>
        <SmileRatingWidget
          orderCode={orderCodeForRate}
          className={modalStyles.simpleModalContainer}
          source={orderExperienceSources.APP_LAUNCH_MODAL}
          onSubmited={this.onOrderExperienceRatingSubmited}/>
      </SimpleModal>
    ) : null;
  }
}

PostOrderExperienceModal.propTypes = {
  setModalObject: PropTypes.func,
  setLastCompletedOrderCode: PropTypes.func,
  lastCompletedOrderCode: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  orderExperiences: PropTypes.arrayOf(PropTypes.shape({
    rating: PropTypes.number,
    source: PropTypes.string,
  })),
  setOrderExperiences: PropTypes.func,
};

PostOrderExperienceModal.defaultProps = {
  setLastCompletedOrderCode: () => {},
  setModalObject: () => {},
  setOrderExperiences: () => {},
  lastCompletedOrderCode: null,
  orderExperiences: [],
};

export const mapStateToProps = (state) => {
  const { lastCompletedOrderCode } = state.user;
  const { orderExperiences } = state;
  return {
    lastCompletedOrderCode,
    orderExperiences,
  };
};

export const mapDispatchToProps = (dispatch) => {
  const setLastCompletedOrderCode = (value) => dispatch(userActions.setLastCompletedOrderCode(value));
  const setModalObject = (value) => dispatch(elementsActions.setModalObject(value));
  const setOrderExperiences = (value) => dispatch(orderExperiencesActions.setOrderExperiences(value));
  return {
    setLastCompletedOrderCode,
    setModalObject,
    setOrderExperiences,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostOrderExperienceModal);
