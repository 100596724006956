/* eslint-disable import/prefer-default-export */
import TagManager from 'react-gtm-module';
import { store } from "../../redux/store";

export const gtmDataLayer = (args) => {
    const siteSettings = store?.getState()?.siteSettings;
    if (siteSettings?.dataCollectionDelay) {
        setTimeout(() => {
            TagManager.dataLayer(args);
        }, siteSettings.dataCollectionDelay);
    } else {
        TagManager.dataLayer(args);
    }
}
