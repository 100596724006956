import React from "react";
import PropTypes from "prop-types";
import _ from 'underscore';
import ReactMarkdown from "react-markdown";
import styles from "./Banner.module.css";

import {
  getContentsByKey,
  getContentValueByKey
} from "../../../shared/utilities/common";
import CmsInlineEdit from "../../Cms/CmsInlineEdit";

function Banner({ contents, openDeliveryWidget, cmsEditMode }) {
  const title = _.find(contents, {key: "title"});
  const description = _.find(contents, {key: "description"});
  const image = _.find(contents, {key: "image"});
  const mobileImage = _.find(contents, {key: "image_mobile"});
  const link = getContentsByKey(contents, "link");
  const linkNode = _.find(contents, {key: "link"});
  const linkTitle = getContentValueByKey(link, "title");
  const linkUrl = getContentValueByKey(link, "url");

  return (
    <>
      <div
        className={`${styles.bannerBackground} mx-auto flex items-center justify-around relative max-w-full`}
      >
        <div
          className=" w-[343px] md:w-[600px] h-[259px] md:h-[194px] md:pl-4 py-[72px] pr-0 flex 
      flex-col items-center md:items-start gap-3 absolute md:left-[10%] md:top-[10%] top-[35%] text-center md:text-left"
        >
          <h1 className="font-congenialBlack text-4xl leading-9 tracking-[-1px] text-white">
            <CmsInlineEdit cmsNode={title}>{title?.value}</CmsInlineEdit>
          </h1>
          <p className="font-filsonProRegular text-lg leading-5 md:leading-[22px] tracking-[-0.1px] text-white">
            <CmsInlineEdit cmsNode={description}>{description?.value}</CmsInlineEdit>
          </p>
          {linkUrl && (
            <CmsInlineEdit cmsNode={linkNode} type="list" showChilds>
              <button
                onClick={() => openDeliveryWidget(linkUrl)}
                type="button"
                className="bg-button text-lg font-filsonProMedium px-8 text-light my-4 h-[44px] rounded-[54px] "
              >
                {linkTitle}
              </button>
            </CmsInlineEdit>
          )}
        </div>
        
        {
          cmsEditMode &&
          (
            <CmsInlineEdit cmsNode={image}>
              {image?.value}
            </CmsInlineEdit>
          )
        }
        {
          !cmsEditMode &&
          <ReactMarkdown className="absolute right-0 top-[-128px] md:block hidden">{image?.value}</ReactMarkdown>
        }

        {
          cmsEditMode &&
          (
          <CmsInlineEdit cmsNode={image}>
            {mobileImage?.value}
          </CmsInlineEdit>
          )
        }

        {
          !cmsEditMode &&
          (
            <ReactMarkdown className="absolute top-[-30%] md:hidden">
              {mobileImage?.value}
            </ReactMarkdown>
          )
        }
      </div>
    </>
  );
}

Banner.propTypes = {
  contents: PropTypes.shape({}),
  openDeliveryWidget: PropTypes.func.isRequired,
  cmsEditMode: PropTypes.bool
};

Banner.defaultProps = {
  contents: null,
  cmsEditMode: false
};

export default Banner;
