import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import Loader from '../../components/Loader/Loader';
import * as cms from '../../shared/repos/cms/cms';
import cmsKeys from '../../shared/constants/cms';
import {getKeyValue} from '../../shared/utilities/cms';

import styles from './TermsAndConditions.module.css'
import CmsInlineEdit from '../../components/Cms/CmsInlineEdit';


class TermsAndConditions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            content: []
        };
    }

   componentDidMount() {
       const { path } = this.props;
       cms.getContent(path === 'cookielab' ? [cmsKeys.eventTermsConditionsPage] : [cmsKeys.termsConditionsPage], this);
    }

    componentDidUpdate(prevProps) {
        const { path } = this.props;
        if (prevProps.path !== path) {
            cms.getContent(path === 'cookielab' ? [cmsKeys.eventTermsConditionsPage] : [cmsKeys.termsConditionsPage], this);
        }
    }

    render() {
        const {content, loading} = this.state;

        if (loading) {
            return (
              <div className={styles.pageWrapper}>
                <div className={styles.pageContainer}>
                  <Loader id="loader" />
                </div>
              </div>
            );
        }
      
        if (!loading && !content.length) {
          return (
            <div className={styles.pageWrapper}>
              <div className={`${styles.pageContainer} text-dark dark:text-white`}>
                <h1>Oops, No data found for this page</h1>
              </div>
            </div>
          );
        }

        return (
          <div className={styles.pageWrapper}>
            <div className={`${styles.pageContainer} text-dark dark:text-white`}>
              {content.map(termsConditionsPage => {
                        const sections = getKeyValue(termsConditionsPage.children, 'sections', true);
                        const mainTitle = _.find(termsConditionsPage.children, {key: 'title'});
                        const mainBody = _.find(termsConditionsPage.children, {key: 'body'});
                        return (
                          <div>
                            <div className={styles.pageSection}>
                              <h1><CmsInlineEdit cmsNode={mainTitle}>{mainTitle?.value}</CmsInlineEdit></h1>
                              <p><CmsInlineEdit cmsNode={mainBody}>{mainBody?.value}</CmsInlineEdit></p>
                            </div>

                            {sections.children.map(termsConditionsSection => {
                                    const header = _.find(termsConditionsSection.children, {key: 'header'});
                                    const body = _.find(termsConditionsSection.children, {key: 'body'});
                                     return (
                                       <div>
                                         { (termsConditionsSection.children.length > 0) ? (
                                           <div className={styles.pageSection}>
                                             <h3><CmsInlineEdit cmsNode={header}>{header?.value}</CmsInlineEdit></h3>
                                             <p><CmsInlineEdit cmsNode={body}>{body?.value}</CmsInlineEdit></p>
                                           </div>
                                        ): '' }
                                       </div>
                                     )
                              })}
                          </div>
                        )
                    })}
            </div>
          </div>
        );
    }
}

TermsAndConditions.defaultProps = {
    path: 'terms'
};

TermsAndConditions.propTypes = {
    path: PropTypes.string
};

export default TermsAndConditions
