import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import ReactMarkdown from "react-markdown";
import Link from "../../../components/LinkWithCountry/LinkWithCountry";
import styles from "./CreateAccount.module.css";
import * as userActions from "../../../redux/actions/user";
import * as userRepo from "../../../shared/repos/graphql/user";

// COMPONENT
import Button from "../../../components/Button/Button";

// FORMS
import { handleFormInput, isValid } from "../../../shared/utilities/validations";
import { isRecaptcha } from "../../../shared/utilities/config";

// HELPERS
import * as tagmanagerEvents from "../../../shared/utilities/tagmanagerEvents";
import CheckBox from "../../../components/CheckBox/CheckBox";
import {
  getContentValueByKey,
  routeCountryPath
} from "../../../shared/utilities/common";
import * as cms from "../../../shared/repos/cms/cms";
import cmsKeys from "../../../shared/constants/cms";
import { getKeyValue } from "../../../shared/utilities/cms";
import subscription from "../../../shared/constants/subscription";
import { getCustomerSubscriptionsByType } from '../../../shared/utilities/subscription';
import SimpleInput from "../../../components/Input/SimpleInput";
import PhoneWidget from "../../../components/PhoneWidget/PhoneWidget";
import { Tab, Tabs } from "../../../components/Tabs/Tabs";
import OtpInput from "../Login/OtpInput";
import Welcome from "../Login/Welcome";
import ResendOtp from "../../../components/ResendOtp/ResendOtp";
import * as elementsActions from "../../../redux/actions/elements";
import {setCaptchaToken} from "../../../shared/utilities/captcha";
import captchaAction from "../../../shared/constants/captchaAction";
import { LABELS } from "../../../shared/constants/labels";

class CreateAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
      firstName: null,
      lastName: null,
      emailErrorMessage: null,
      firstNameErrorMessage: null,
      lastNameErrorMessage: null,
      phoneNumberErrorMessage: null,
      responseErrorMessage: null,
      content: [],
      subscriptionRequestCompleted: false,
      customerSubscriptions: [],
      customerSubscriptionsError: [],
      phoneNumber: null,
      continueWithOtp: false,
      otp: "",
      phoneOTPErrMessage: null,
      token: null,
      user: null,
      inviteCode: null,
    };

    this.recaptchaComponentRef = React.createRef();

    this.loginGuard();
  }

  componentDidMount() {
    cms.getContent([cmsKeys.registrationPage], this);
    const {optIn, consent} = subscription.subscriptionType;
    const { EMAIL_OPT_IN} = subscription.subscriptionKeyField;
    getCustomerSubscriptionsByType([optIn,consent]).then(sub => {
      const filtereObj = (sub || []).filter(item => (item.subscriptionTypeId === optIn && item.keyField === EMAIL_OPT_IN) || item.subscriptionTypeId === consent);
      filtereObj.sort((a, b) => {
        // Sort ascending order (0 -> 1)
        return b.isRequired - a.isRequired;
      });
      this.setState({
        customerSubscriptions: filtereObj,
        subscriptionRequestCompleted: true,
      });
    });
  }

  loginGuard = () => {
    const { userToken, history } = this.props;

    if (userToken) {
      history.push(routeCountryPath("/user/profile"));
    }
  };

  handleRegister = async () => {
    if (isRecaptcha()) {
      const { setVerificationToken, setVerificationAction } = this.props;
      await setCaptchaToken(setVerificationToken, setVerificationAction, captchaAction.signup);
    }
    this.handleSubmit();
  };

  onCompleteOtp = otp => {
    this.setState({ otp });
  };

  handleResendOtp = async() => {
    const { user } = this.state;
    await userRepo.resendCustomerVerificationTelephoneOtp(null, null, null, null, user?.id);
  }

  continueWithOtp = () => {
    const { phoneNumber, otp, loading, phoneOTPErrMessage } = this.state;
    const isDisabled = !(otp.length >= 4);
    return (
      <div className="flex gap-3 flex-col w-full">
        <OtpInput
          onComplete={this.onCompleteOtp}
          length={4}
          classInput="bg-[#2C0C37] border-2 border-darkDry text-center text-4xl"
          label={
            phoneNumber?.phone
              ? `Enter the 4-digit code sent to you at ${phoneNumber.code} ${phoneNumber.phoneFormat}`
              : null
          }
          handleSubmit={this.loginWithOtp}
        />

        {phoneOTPErrMessage && (
          <p className={styles.hasError}>{phoneOTPErrMessage}</p>
        )}

        <ResendOtp handleClick={this.handleResendOtp} />

        <div className="w-full mt-4">
          <Button
            isLoading={loading}
            disabled={isDisabled}
            customContainerStyles={`${styles.buttonContainer} ${
              isDisabled ? "!bg-disabled" : ""
            } !block w-full bg-button text-white text-center py-2 h-11`}
            handleSubmit={isDisabled ? null : this.loginWithOtp}
            label="Continue"
          />
        </div>
      </div>
    );
  };

  loginWithOtp = async () => {
    const { otp, user, content } = this.state;
    try {
      this.setState({
        loading: true
      });
      const result = await userRepo.confirmCustomerSignup(
        otp,
        user.id
      );
      const { data } = result;
      if (data?.confirmCustomerSignup?.customer?.telephoneVerified) {
        this.setState({
          user: data?.confirmCustomerSignup?.customer,
          token: data?.confirmCustomerSignup?.token
        });
      }
      this.setState({
        loading: false,
        continueWithOtp: false
      });
    } catch (e) {
      this.setState({ loading: false });
      if (!e.message) {
        this.setState({
          phoneOTPErrMessage: "Something went wrong! Please try again.",
          loading: false
        });
        return;
      }

      const msgs = _.find(content, { key: cmsKeys.registrationPage });
      const errMsg = getKeyValue(msgs ? msgs.children : [], e.message);

      this.setState({
        phoneOTPErrMessage: errMsg || e.message,
        loading: false
      });
    }
  };

  handleUserWelcome = () => {
    const { setUserId, order, setUserToken, history } = this.props;
    const { token, user, password } = this.state;
    return (
      <Welcome
        order={order}
        setUserToken={setUserToken}
        setUserId={setUserId}
        token={token}
        handleLoginWithPhone={() => {}}
        user={user}
        password={password}
        isSignUp
        history={history}
      />
    );
  };

  /**
   * Handles form submit
   */
  handleSubmit = () => {
    const {
      email,
      firstName,
      lastName,
      phoneNumber,
      content,
      customerSubscriptions,
      inviteCode,
    } = this.state;

    const code = phoneNumber?.code;
    const phone = phoneNumber?.phone;

    this.setState({
      responseErrorMessage: null,
      emailErrorMessage: null,
      firstNameErrorMessage: null,
      lastNameErrorMessage: null,
      phoneNumberErrorMessage: null,
    });
    const subscriptions = customerSubscriptions.filter(item => item.defaultValue);
    const subscriptionIds = subscriptions.map((item)=> parseInt(item.subscriptionId, 10));

    // NULL VALUE LOGIC
    if (!email || email.length === 0)
      return this.setState({
        emailErrorMessage: "Please enter an email address."
      });
    if (!firstName)
      return this.setState({
        firstNameErrorMessage: "Please enter a first name"
      });

    if (!lastName)
      return this.setState({
        lastNameErrorMessage: "Please enter a last name"
      });

    if (!phoneNumber)
      return this.setState({
        phoneNumberErrorMessage: "Please enter a phone number"
      });

    // VALUE VALIDATION LOGIC
    if (email && isValid(email, "email")) {
      if (firstName && lastName && phoneNumber) {
        if (!this.validateSubscriptions()) {
          return false;
        }
        this.setState({ loading: true });

        userRepo
          .createAccountAndLoginV2(
            email,
            firstName,
            lastName,
            code,
            phone,
            null,
            false,
            subscriptionIds,
            inviteCode
          )
          .then(response => {
            const { signupCustomerV2 } = response.data;
            const { token, customer } = signupCustomerV2;
            this.setState({
              continueWithOtp: true,
              loading: false,
              token,
              user: { ...customer, telephoneVerified: true }
            });
            tagmanagerEvents.signup("api");
            tagmanagerEvents.joinLoyalty({ email });
          })
          .catch(error => {
            content.map(registrationPage => {
              return this.setState({
                responseErrorMessage: getKeyValue(
                  registrationPage.children,
                  error.message
                ),
                loading: false
              });
            });
          });

        return true;
      }

      return this.setState({
        firstNameErrorMessage: "first name not matched"
      });
    }

    return this.setState({ emailErrorMessage: "Invalid email address " });
  };

  handleInput = (fieldName, value) => {
    this.setState({
      [fieldName]: value
    });
  };

  signIn = () => {
    const { history } = this.props;
    return history.push(routeCountryPath("/login"));
  };

  createAccountContainer = () => {
    const {
      emailErrorMessage,
      firstNameErrorMessage,
      lastNameErrorMessage,
      phoneNumberErrorMessage,
      email,
      firstName,
      lastName,
      loading,
      responseErrorMessage,
      subscriptionRequestCompleted,
      customerSubscriptions,
      customerSubscriptionsError,
      continueWithOtp,
      token,
      phoneNumber,
      inviteCode,
      content
    } = this.state;
    const isDisabled = !(phoneNumber && firstName && lastName && email && subscriptionRequestCompleted) ;
    const { referralEnabled } = this.props;

    const cmsData = _.find(content, { key: cmsKeys.registrationPage });
    const title = getContentValueByKey(cmsData?.children, "title");
    const rewards = _.find(cmsData?.children, { key: "rewards" });

    return (
      <div
        className={`${styles.pageWrapper} text-dark dark:text-white w-[343px] mx-auto mb-10`}
      >
        {!token || continueWithOtp ? (
          <div className={`${styles.pageContainer} w-[343px] flex justify-center items-center`}>
            {!continueWithOtp ? (
              <Tabs active={LABELS.SIGN_UP_TAB}>
                <Tab
                  label={LABELS.SIGN_IN_TAB}
                  onClick={this.signIn}
                  className="text-white"
                />
                <Tab label={LABELS.SIGN_UP_TAB} className="text-white">
                  <h1 className="!font-filsonProRegular !text-base !leading-5 !text-white mb-3">
                    { title }
                  </h1>
                  {rewards && rewards?.children.map((reward, index) => (
                    <div key={index} className="flex items-center mb-4">
                      <div className="mr-4 flex items-center justify-center w-6 h-6">
                        <ReactMarkdown className="w-full h-full">
                          {getContentValueByKey(reward.children, "image")}
                        </ReactMarkdown>
                      </div>
                      <h1 className="!font-filsonProRegular !text-base !leading-5 !text-white">
                        {getContentValueByKey(reward.children, "title")}
                      </h1>
                    </div>
                  ))}
                  <div className="mb-3">
                    <PhoneWidget
                      onInput={this.handleInput}
                      fieldName="phoneNumber"
                      errorMessage={phoneNumberErrorMessage}
                    />
                  </div>
                  <SimpleInput
                    handleInput={e => handleFormInput(e, this, "firstName")}
                    placeholder="First name *"
                    value={firstName}
                    type="firstName"
                    label="Last name *"
                    errorMessage={firstNameErrorMessage}
                    fieldContainerClass="border-[1px] border-darkElevationPrimary rounded-[40px]"
                    fieldClass={`!text-white w-full bg-transparent outline-none h-11 ${styles.inputLightModeOverride}`}
                  />
                  <SimpleInput
                    handleInput={e => handleFormInput(e, this, "lastName")}
                    placeholder="Last name *"
                    value={lastName}
                    type="firstName"
                    label="Last name"
                    errorMessage={lastNameErrorMessage}
                    fieldContainerClass="border-[1px] border-darkElevationPrimary rounded-[40px]"
                    fieldClass={`!text-white w-full bg-transparent outline-none h-11 ${styles.inputLightModeOverride}`}
                  />
                  <SimpleInput
                    handleInput={e =>
                      handleFormInput(e, this, "email", "email")}
                    placeholder="Email address *"
                    value={email}
                    label="Email address"
                    errorMessage={emailErrorMessage}
                    fieldContainerClass="border-[1px] border-darkElevationPrimary rounded-[40px]"
                    fieldClass={`!text-white w-full bg-transparent outline-none h-11 ${styles.inputLightModeOverride}`}
                  />
                  { referralEnabled ? (
                    <SimpleInput
                      handleInput={e =>
                        handleFormInput(e, this, "inviteCode")}
                      placeholder="Referral code (optional)"
                      value={inviteCode}
                      label="Referral code (optional)"
                      fieldContainerClass="border-[1px] border-darkElevationPrimary rounded-[40px]"
                      fieldClass="!text-white w-full bg-transparent outline-none h-11 inputLightModeOverride"
                    />
                  ) : null }
                  {customerSubscriptions.map((item, index) => (
                    <div key={item.id} className={styles.registerCheckbox}>
                      <CheckBox
                        onClick={() => this.toggleCheckbox(index)}
                        isChecked={item.defaultValue}
                        label={<div className='text-white' dangerouslySetInnerHTML={{__html: item.description }} />}
                        name={`subscription-${item.subscriptionId}`}
                      />
                      {customerSubscriptionsError[index] && (
                        <p className={styles.hasError}>
                          You must agree to `
                          {item.name}
                        </p>
                      )}
                    </div>
                  ))}
                  {responseErrorMessage && (
                    <p className={styles.hasError}>{responseErrorMessage}</p>
                  )}
                  <div className={styles.actionsContainer}>
                    <Button
                      isLoading={loading}
                      disabled={isDisabled}
                      handleSubmit={
                        isDisabled ? null : this.handleRegister
                      }
                      customContainerStyles={`
                        ${styles.buttonContainer}
                        ${isDisabled ? "!bg-disabled" : ""}
                        !block w-full bg-button text-white text-center py-2 h-11
                      `}
                      label="Create Account"
                    />
                  </div>
                  {/* SSO CONTAINER */}
                  <div className={styles.ssoContainer}>
                    <p
                      className={`${styles.privacyTermsCopy} text-darkElevationSecondary`}
                    >
                      <Link to="/privacy-policy">Privacy Policy</Link>
                      &nbsp; & &nbsp;
                      <Link to="/terms">Terms and Conditions</Link>
                    </p>
                  </div>
                </Tab>
              </Tabs>
            ) : (
              <div className="my-10">{this.continueWithOtp()}</div>
            )}
          </div>
        ) : (
          <div className={`${styles.pageContainer} w-[343px] flex justify-center items-center`}>
            {this.handleUserWelcome()}
          </div>
        )}
      </div>
    );
  };

  toggleCheckbox = (index) => {
    const { customerSubscriptions} = this.state;
    customerSubscriptions[index].defaultValue = !customerSubscriptions[index].defaultValue;
    let newSubscriptionRequestCompleted;

    if(this.validateSubscriptions()){
      newSubscriptionRequestCompleted = true;
    } else {
      newSubscriptionRequestCompleted = false
    };
    this.setState({
      customerSubscriptions,
      subscriptionRequestCompleted: newSubscriptionRequestCompleted
    });
  }

  validateSubscriptions = () => {
    const { customerSubscriptions } = this.state;
    const customerSubscriptionsError = customerSubscriptions.map(item => !item.defaultValue && item.isRequired);

    this.setState({ customerSubscriptionsError });

    // Return true if no errors
    return customerSubscriptionsError.every(error => !error);
  };

  render() {
    return <this.createAccountContainer />;
  }
}

CreateAccount.propTypes = {
  setUserId: PropTypes.func.isRequired,
  setUserToken: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  userToken: PropTypes.string,
  order: PropTypes.shape({
    code: PropTypes.string
  }),
  setVerificationToken: PropTypes.func.isRequired,
  setVerificationAction: PropTypes.func.isRequired,
  referralEnabled: PropTypes.bool,
};

CreateAccount.defaultProps = {
  userToken: null,
  order: null,
  referralEnabled: false,
};

const mapStateToProps = state => {
  const { userToken } = state.user;
  const { order, loyalty } = state;
  return {
    userToken,
    order,
    referralEnabled: loyalty.referralEnabled,
  };
};

export const mapDispatchToProps = dispatch => ({
  setUserToken: value => dispatch(userActions.setUserToken(value)),
  setUserId: value => dispatch(userActions.setUserId(value)),
  setVerificationToken: value =>
    dispatch(elementsActions.setVerificationToken(value)),
  setVerificationAction: value =>
    dispatch(elementsActions.setVerificationAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
