const initialState = {
  showBigOrderWarning: false,
  previousBigOrderWarningId: null,
};

export default (state = initialState, { type, value }) => {
  switch (type) {
    case 'SHOW_BIG_ORDER_WARNING':
      return { ...state, showBigOrderWarning: true };
    case 'HIDE_BIG_ORDER_WARNING':
      return { ...state, showBigOrderWarning: false };
    case 'SET_PREVIOUS_BIG_ORDER_WARNING_ID':
      return { ...state, previousBigOrderWarningId: value };
    default:
      return state;
  }
};
