import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import Link from "../../LinkWithCountry/LinkWithCountry";
import {
  getContentsByKey,
  getContentValueByKey
} from "../../../shared/utilities/common";
import CmsInlineEdit from "../../Cms/CmsInlineEdit";

function Promotions({ contents }) {
  const getActionButton = (url, title) => {
    if (/(http(s?)):\/\//i.test(url)) {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <button
            type="button"
            className="text-dark dark:text-white h-[54px] px-8 bg-transparent font-filsonProMedium border-solid border border-dark dark:border-darkBorder rounded-[54px]"
          >
            {title}
          </button>
        </a>
      );
    }
    return (
      <Link to={url}>
        <button
          type="button"
          className="text-dark dark:text-white h-[54px] px-8 bg-transparent font-filsonProMedium border-solid border border-dark dark:border-darkBorder rounded-[54px]"
        >
          {title}
        </button>
      </Link>
    );
  };

  const getRowsData = () => {
    return contents?.map((item, index) => {
      const even = index % 2;
      const title = getContentValueByKey(item.children, "title");
      const description = getContentValueByKey(item.children, "description");
      const image = getContentValueByKey(item.children, "image");
      const links = getContentsByKey(item.children, "links");
      return (
        <div className="md:grid grid-cols-2 md:gap-8 lg:gap-0 lg:max-w-screen-desktop lg:mx-auto">
          <div className={`${even ? "md:hidden block" : "block"}`}>
            <ReactMarkdown className="md:w-full">{image}</ReactMarkdown>
          </div>
          <div className="self-center md:mt-0 mt-8">
            <div className="flex flex-col gap-4 m-auto lg:w-3/5 lg:items-start items-center">
              <h1 className="text-dark dark:text-white font-black font-congenialBlack text-4xl leading-9 tracking-tighter">
                {title}
              </h1>

              <p className="text-dark dark:text-white font-filsonProRegular font-normal leading-5 tracking-tighter">
                <ReactMarkdown>{description}</ReactMarkdown>
              </p>

              <div className=" flex flex-row lg:flex-col gap-4 ">
                {links.map(link => {
                  const linkTitle = getContentValueByKey(link.children, "title");
                  const linkUrl = getContentValueByKey(link.children, "url");
                  return getActionButton(linkUrl, linkTitle);
                })}
              </div>
            </div>
          </div>

          <div className={`${even ? "md:block hidden" : "hidden"}`}>
            <ReactMarkdown className="md:w-full">{image}</ReactMarkdown>
          </div>
        </div>
      );
    });
  };

  return (
    <CmsInlineEdit type="list" cmsNode={{children: contents}} showChilds>
      <div className="md:grid grid-flow-row flex flex-col gap-8 w-full lg:px-0 px-4 mt-9">
        {getRowsData()}
      </div>
    </CmsInlineEdit>
  );
}

Promotions.propTypes = {
  contents: PropTypes.shape({}).isRequired
};

export default Promotions;
