/* eslint-disable import/prefer-default-export */
import { CMS_API_QUERY, CREATE_CMS_DATA, UPDATE_CMS_DATA, UPLOAD_CMS_FILE, DELETE_CMS_DATA } from '../../../graphql/cms';
import { client } from '../../utilities/config';

export const getContent = (keys, localeId) => {
    return client.query({
        query: CMS_API_QUERY,
        variables: { 
            keys,
            localeId,
        }
    });
};

/**
 * @param {Object} data {
 *   cmsKeyId: Int!
 * }
 */
export const deleteCmsData = data => {
    return client.mutate({
        mutation: DELETE_CMS_DATA,
        variables: data
    });
};

/**
 * @param {Object} data {
 *   parentCmsKeyId: Int
 *   key: String
 *   value: String
 * }
 */
export const createCmsData = data => {
    return client.mutate({
        mutation: CREATE_CMS_DATA,
        variables: data
    });
};

/**
 * @param {Object} data {
 *     cmsKeyValueId: Int!,
 *     value: String,
 * }
 */
export const updateCmsData = data => {
    return client.mutate({
        mutation: UPDATE_CMS_DATA,
        variables: data
    });
};

/**
 * @param {Object} data {
 *     file: Upload!
 * }
 */
export const uploadCmsFile = data => {
    return client.mutate({
        mutation: UPLOAD_CMS_FILE,
        variables: data
    });
};
