/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const CMS_API_QUERY = gql`
    query CMS_API_QUERY($keys: [String!], $localeId: Int!) {
        cmsData(where: { keys: $keys, localeId: $localeId }) {
            key
            value
            cmsKeyId
            cmsKeyValueId
            parentCmsKeyId
            parentCmsKeyValueId
            children {
                key
                value
                cmsKeyId
                cmsKeyValueId
                parentCmsKeyId
                parentCmsKeyValueId
                children {
                    key
                    value
                    cmsKeyId
                    cmsKeyValueId
                    parentCmsKeyId
                    parentCmsKeyValueId
                    children {
                        key
                        value
                        cmsKeyId
                        cmsKeyValueId
                        parentCmsKeyId
                        parentCmsKeyValueId
                        children {
                            key
                            value
                            cmsKeyId
                            cmsKeyValueId
                            parentCmsKeyId
                            parentCmsKeyValueId
                            children {
                                key
                                value
                                cmsKeyId
                                cmsKeyValueId
                                parentCmsKeyId
                                parentCmsKeyValueId
                                children{
                                    key
                                    value
                                    cmsKeyId
                                    cmsKeyValueId
                                    parentCmsKeyId
                                    parentCmsKeyValueId
                                    children{
                                        key
                                        value
                                        cmsKeyId
                                        cmsKeyValueId
                                        parentCmsKeyId
                                        parentCmsKeyValueId
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const DELETE_CMS_DATA = gql`
  mutation deleteCmsData(
    $data: DeleteCmsInputData!
  ) {
    deleteCmsData(data: $data)
  }
`;

export const CREATE_CMS_DATA = gql`
  mutation createCmsData(
    $data: CreateCmsInputData!
  ) {
    createCmsData(data: $data) {
      key
      value
      cmsKeyId
      cmsKeyValueId
      parentCmsKeyId
      parentCmsKeyValueId
      children {
        key
        value
        cmsKeyId
        cmsKeyValueId
        parentCmsKeyId
        parentCmsKeyValueId
      }
    }
  }
`;

export const UPDATE_CMS_DATA = gql`
  mutation updateCmsData(
    $data: CmsKeyValueData!
  ) {
    updateCmsData(data: $data)
  }
`;

export const UPLOAD_CMS_FILE = gql`
  mutation uploadCmsFile(
    $data: CmsUploadFile!
  ) {
    uploadCmsFile(data: $data)
  }
`;
