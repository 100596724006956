/* eslint-disable import/prefer-default-export */
import gql from "graphql-tag";

export const GET_CUSTOMER_NOTIFICATIONS = gql`
  query GET_CUSTOMER_NOTIFICATIONS($type: String!) {
    getNotifications(type: $type) {
      id
      message
      isRead
      readAt
      createdAt
    }
  }
`;

export const READ_CUSTOMER_NOTIFICATIONS = gql`
  mutation READ_CUSTOMER_NOTIFICATIONS($ids: [Int!]) {
    readNotifications(ids: $ids)
  }
`;
