/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import googleWalletSVG from "./imgs/addToGoogleWallet.svg";
import styles from "./AddToGoogleWallet.module.css";
import * as walletRepo from "../../shared/repos/graphql/wallet";
import { addToWalletResponseTypes, PLATFORMS } from "../../shared/constants/common";

class AddToGoogleWallet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      errorMessage: null,
    };
  }

  handleClick = async () => {
    const {processing} = this.state;
    if (processing) {
      return;
    }
    this.setState({ processing: true });
    try {
      const result = await walletRepo.addLoyaltyCardToWallet(PLATFORMS.ANDROID);
      if (result.data.addLoyaltyCardToWallet.success) {
        this.setState({
          processing: false,
          errorMessage: null,
        });
        this.handleAddToGoogleWallet(result.data.addLoyaltyCardToWallet);
      } else {
        this.setState({
          processing: false,
          errorMessage: result.data.addLoyaltyCardToWallet.message,
        });
      }
    } catch (error) {
      this.setState({
        errorMessage: error.message,
        processing: false,
      });
    }
  }

  handleAddToGoogleWallet = (response) => {
    if (response.responseType === addToWalletResponseTypes.URL) {
      window.open(response.responseData, '_blank');
    }
  }

  render() {
    const { errorMessage, processing } = this.state;
    const processngStyle = processing ? styles.processing : '';
    return (
      <div className={styles.addToWalletButtonContainer}>
          <img onClick={this.handleClick} className={[styles.addToGoogleWalletButton, processngStyle].join(' ')} src={googleWalletSVG} alt="Add To Google Wallet" />
          { errorMessage ? (<div className={styles.errorMessage}>{errorMessage}</div>) : null }
      </div>
    );
  }

}
  
export default AddToGoogleWallet;