import { PRESETS_QUERY } from "../../../graphql/presets";
import { client } from "../../utilities/config";

/* eslint-disable import/prefer-default-export */
export const getPresets = (productId, storeId, includePersonalizedPreset) => {
  return client.query({
    fetchPolicy: "no-cache",
    query: PRESETS_QUERY,
    variables: { productId, storeId, includePersonalizedPreset }
  });
};
