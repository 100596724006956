import React from 'react';

import PropTypes from 'prop-types'
import styles from './Loader.module.css';

const loadingIcon = require('./imgs/loading-icon.png');

const Loader = ({ height, className }) => (
  <div className={[styles.loaderWrapper, className].join(' ')}>
    <img style={{ height }} alt='loader-icon' src={loadingIcon} />
  </div>
)

Loader.propTypes = {
  height: PropTypes.number,
  className: PropTypes.string
}

Loader.defaultProps = {
  height: 50,
  className: ''
}

export default Loader;
