/* eslint-disable import/prefer-default-export */
import gql from "graphql-tag";

export const LOYALTY_PROFILE = gql`
  query LOYALTY_PROFILE {
    loyaltyProfile {
      points
      qrCode
    }
  }
`;

export const LOYALTY_DEALS = gql`
  query LOYALTY_DEALS {
    loyaltyDeals {
      id
      type
      points
      amount
      expiredAt
      title
      description
      challengeId
      product {
        id
        hasAssortments
        options {
          id
        }
        title
        productImageSmall
      }
    }
  }
`;

export const LOYALTY_METRICS = gql`
  query LOYALTY_METRICS {
    loyaltyMetrics {
      type
      title
      value
    }
  }
`;

export const LOYALTY_REDEEMABLES = gql`
  query LOYALTY_REDEEMABLES {
    loyaltyRedeemables {
      id
      title
      points
      items {
        id
        type
        points
        amount
        title
        description
        product {
          id
          hasAssortments
          options {
            id
          }
          title
          productImageSmall
        }
      }
    }
  }
`;
