const initialState = [];

export default(state = initialState, { type, value }) => {
    switch (type) {
        case 'SET_ORDER_EXPERIENCES':
            return value || state;
        default:
            return state;
    }
}
