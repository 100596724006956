/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./SavedLocations.module.css";
import { getPaginatedSavedLocations } from "../../shared/repos/graphql/user";
import Loader from "../Loader/Loader";
import ddLog from '../../shared/utilities/datadog/log';
import SavedLocationItem from "./SavedLocationItem";

class SavedLocationList extends Component {

  DEFAULT_LIMIT = 3;

  constructor(props) {
    super(props);
    this.state = {
        locations: [],
        isShowMoreAvailable: true,
        showMoreLoader: false,
        pagination: {
          limit: this.DEFAULT_LIMIT,
          skip: 0,
          totalCount: 0,
        }
    };
  }

  componentDidMount() {
    const { paginatedLocations } = this.props;
    const { locations, pagination } = paginatedLocations;
    this.setState({
      locations: locations,
      isShowMoreAvailable: !this.isLastPage(pagination),
      pagination: pagination,
    });
  }

  componentDidUpdate(prevProps) {
    this.onUpdateComponent(prevProps);
  }

  onUpdateComponent = (prevProps) => {
    const { paginatedLocations } = this.props;
    const prevLocations = JSON.stringify(prevProps.paginatedLocations);
    const currentLocations = JSON.stringify(paginatedLocations);
    if (prevLocations !== currentLocations) {
      const { locations, pagination } = paginatedLocations;
      this.setState({
        locations: locations,
        isShowMoreAvailable: !this.isLastPage(pagination),
        pagination: pagination,
      });
    }
  }

  isLastPage = (pagination) => {
    const { limit, skip, totalCount } = pagination;
    return limit + skip >= totalCount;
  }

  onShowMoreClick = async (event) => {
    const { locations, showMoreLoader } = this.state;
    event.stopPropagation();
    if (showMoreLoader) {
      return;
    }
    this.setState({
      showMoreLoader: true,
    });
    try {
      const result = await getPaginatedSavedLocations({
        savedLocationsPagination: {
          limit: this.DEFAULT_LIMIT,
          offset: locations.length,
        }
      });
      const isLastPage = this.isLastPage(result.data.me.savedLocations.pagination);
      this.setState({
        locations: [...locations, ...result.data.me.savedLocations.locations],
        isShowMoreAvailable: !isLastPage,
        showMoreLoader: false,
        pagination: result.data.me.savedLocations.pagination,
      });
      const element = document.querySelector('#modalDeliveryPickupWidget');
      if (element) {
        element.scrollTo({
          top: element.scrollHeight,
          behavior: 'smooth'
        });
      }
    } catch (e) {
      ddLog.logError(e);
      this.setState({
        showMoreLoader: false,
      });
    }
  }

  onShowLessClick = () => {
    const { paginatedLocations } = this.props;
    const { locations, pagination } = paginatedLocations;
    this.setState({
      locations: locations,
      isShowMoreAvailable: !this.isLastPage(pagination),
      pagination: pagination,
    });
  }

  renderShowLessOrMoreButton() {
    const { locations, isShowMoreAvailable, showMoreLoader } = this.state;
    if (isShowMoreAvailable) {
      return (
        <div className={[styles.showMore, 'showMoreSavedAddresses'].join(' ')}>
          <span onClick={this.onShowMoreClick} role="button">Show More { showMoreLoader ? <Loader height={18}/> : null }</span>
        </div>
      );
    } else if (isShowMoreAvailable === false && locations.length > this.DEFAULT_LIMIT) {
      return (
        <div className={[styles.showMore, 'showMoreSavedAddresses'].join(' ')}>
          <span onClick={this.onShowLessClick} role="button">Show Less</span>
        </div>
      );
    }
    return null;
  }

  render() {
    const { className, onEditLocationClick, onLocationClick } = this.props;
    const { locations } = this.state;
    return (
      <div className={[styles.savedAddresses, className].join(' ')}>
        { locations.map(location => <SavedLocationItem
            location={location}
            onEditClick={onEditLocationClick}
            className={['dark:bg-dark border-[1px] dark:border-darkBorder', styles.mb20].join(' ')}
            key={location.id}
            onClick={onLocationClick}
          />) }
        { this.renderShowLessOrMoreButton() }
      </div>
    );
  }
}

SavedLocationList.propTypes = {
  className: PropTypes.string,
  paginatedLocations: PropTypes.shape({
    locations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      displayString: PropTypes.string,
      sourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      source: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
      locationData: PropTypes.shape({
        comment: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
        isContactless: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
      }),
      addressDetails: PropTypes.shape({
        address2: PropTypes.string,
        lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
        lng: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      }),
      locationLabel: PropTypes.oneOfType([
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          label: PropTypes.string,
        }),
        PropTypes.oneOf([null]),
      ]),
    })),
    pagination: PropTypes.shape({
      limit: PropTypes.number,
      skip: PropTypes.number,
      totalCount: PropTypes.number,
    }),
  }).isRequired,
  onEditLocationClick: PropTypes.func,
  onLocationClick: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
};

SavedLocationList.defaultProps = {
  className: "",
  onEditLocationClick: () => {},
  onLocationClick: null,
};

export default SavedLocationList;
