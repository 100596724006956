/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

// REDUX
import { connect } from 'react-redux';
import * as userActions from '../../redux/actions/user';

// COMPONENTS
import WidgetItem from '../WidgetItem/WidgetItem';
import CheckBox from '../CheckBox/CheckBox';

import styles from './OrderReviewWidget.module.css';

// CONSTANTS
import { orderTypeIds } from '../../shared/constants/order';
import {giftCard} from "../../shared/constants/giftCard";
import endpoints from "../../shared/constants/endpoints";
import { routeCountryPath } from '../../shared/utilities/common';
import { PUNCHH_TYPE } from '../../shared/constants/loyaltyType';
import * as loyaltyAction from '../../redux/actions/loyalty';

const giftBoxId = 283;
const deleteIcon = require("./imgs/delete.svg");
const PencilIcon = require("./imgs/pencil.svg");

class OrderReviewWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexShown: 2
    };
  }


    /**
     * Handles state logic to show all products
     * not initially rendered
     */
    showAllItems = () => {

      const { indexShown } = this.state;
      const {item} = this.props;
      const isBox = item.hasAssortments;
      const isCustom = item.productOptions && item.productOptions.length > 0;
      
      const itemsLeftToRender = isCustom
        ? item.productOptions.length - 1 - indexShown
        : isBox
        ? item.boxOptions.length - 1 - indexShown
        : 0;

      const fullItemsIndex = itemsLeftToRender + indexShown;
      this.setState({ indexShown: fullItemsIndex });
    };


    /**
     * Handles order modification routing
     * based on product object values
     */
    handlelOrderModification = () => {
      const { history, order, setRedeemableItem} = this.props;
      const { productOptions, id, product } = this.props.item; // eslint-disable-line
      const isRedeemable = order?.orderRedeemables && order.orderRedeemables.length > 0;

      if (isRedeemable) {
        const orderRedeemable = order.orderRedeemables.find(p => {
          return p.order_product_id === id
        })

        if (orderRedeemable) {
          setRedeemableItem({
            redeemableId: orderRedeemable.external_id,
            redeemableType: orderRedeemable?.redemption_group === "redeemable"  //eslint-disable-line
            ? PUNCHH_TYPE.REDEEMABLE
            : PUNCHH_TYPE.DEALS
          })
        }
      }
     
      const productType = product.hasAssortments 
          ? 'box'
          : (productOptions && productOptions.length > 0)
          ? 'customize'
          : 'single';
      const idGiftCardProduct = parseInt(product.id, 10) === giftCard.productId;
      if ( idGiftCardProduct ) {
        const designOption = productOptions.filter(item =>
            item.productOption.label === giftCard.optionLabels.design
        );

        const updateGift = endpoints.cookieMagicGiftCardProductUpdate.replace(':id', designOption[0].value);
        const updateGiftPath = updateGift.replace(':poid', id, {updateCard: true});
        history.push(routeCountryPath(updateGiftPath), {updateCard: true});
      } else {
        history.push(routeCountryPath(`/checkout/view-order/products/${productType}/${product.id}/${id}`), {isCartUpdate: true});
      }

    };

  orderReviewWidgetContainer = () => {
    const { indexShown } = this.state;
    const {
      item,
      addGiftBox,
      giftBoxPrice,
      onGiftClick,
      isGift,
      itemPrice,
      itemTitle,
      userCartId,
      removeItemMutation,
      amount,
      order,
      unavailableProducts,
      currency,
    } = this.props;
    const isBox = item.hasAssortments;
    const isCustom = item.productOptions && item.productOptions.length > 0;
    const isGiftCardProduct = parseInt(item.product.id, 10) === giftCard.productId;
    const isCustomWithoutGift = isCustom && item && !isGiftCardProduct;
    const isCustomWithGift = isCustom && isGiftCardProduct;
    const itemsLeftToRender = isCustom
      ? item.productOptions.length - 1 - indexShown
      : isBox
        ? item.boxOptions.length - 1 - indexShown
        : 0;
    const isDoughProOrder = order ? parseInt(order.orderTypeId, 10) === orderTypeIds.doughPro : false;
    
    const getOptionPrice = (itemOption) => {
      if (itemOption.productOption) {
        if (itemOption.productOption.price > 0) {
          return itemOption.productOption.price.toFixed(2)
        }
      }
      return 0;
    }
    return (
      <div
        className={`
        ${styles.orderReviewWidgetWrapper} 
        bg-white dark:bg-dark md:px-8 md:py-10 border-[1px] border-lightDry dark:border-darkBorder
        rounded-[20px]
        `}
      >
        <div
          className={` flex flex-col gap-4 ${styles.orderReviewWidgetContainer}`}
        >
          {/* COMPONENT HEADER */}
          <div
            className={`${styles.widgetHeader} flex justify-between items-center`}
          >
            <p className="text-dark dark:text-white w-full">{itemTitle}</p>
            <span
              onClick={() => {
                removeItemMutation({
                  variables: { orderCode: userCartId, data: [item.id] }
                });
              }}
              className={`hidden md:block`}
            >
              Remove
            </span>

            <div className={`${styles.widgetFooter} block md:hidden !w-full`}>
              <div className="flex !flex-row gap-0 justify-between items-center w-full">
                <h3 className="!mb-0">
                  {amount}
                  <span>&#8226;</span>
                  {itemPrice}
                </h3>
                {!isDoughProOrder ? (
                  <span
                    onClick={() => {
                      this.handlelOrderModification();
                    }}
                  >
                    {" "}
                    <img
                      src={PencilIcon}
                      alt="delete-icon"
                      className="!top-0 !mr-0"
                    />
                  </span>
                ) : null}
                <span
                  onClick={() => {
                    removeItemMutation({
                      variables: { orderCode: userCartId, data: [item.id] }
                    });
                  }}
                >
                  <img src={deleteIcon} alt="delete-icon"  className="!top-0 !mr-0"/>
                </span>
              </div>
            </div>
          </div>
          {addGiftBox && giftBoxPrice ? (
            <div className={styles.giftBoxContainer}>
              <CheckBox
                onClick={onGiftClick}
                isChecked={isGift}
                label={`Make it a gift box + $${giftBoxPrice}`}
              />
            </div>
          ) : null}
          {/* COMPONENT BODY */}
          <div className={styles.widgetBody}>
            {isBox && item
              ? item.boxOptions.map((boxProduct, index) => (
                  <WidgetItem
                    key={boxProduct.id + index}
                    title={boxProduct.title}
                    price={boxProduct.price}
                    id={boxProduct.id}
                    unavailableProducts={unavailableProducts}
                    currency={currency}
                  />
                ))
              : undefined}
            {isCustomWithoutGift ? (
              item.productOptions
                .filter(
                  productOption =>
                    parseInt(productOption.optionIdProductId, 10) !== giftBoxId
                )
                .map((customProduct, index) => (
                  <WidgetItem
                    key={customProduct.id + index}
                    title={customProduct.title}
                    type={
                      customProduct.options && customProduct.categories
                        ? customProduct.categories[0].title
                        : ""
                    }
                    amount={customProduct.quantity}
                    price={getOptionPrice(customProduct)}
                    unavailableProducts={unavailableProducts}
                    id={customProduct.options.id}
                    currency={currency}
                  />
                ))
            ) : isCustomWithGift ? (
              <WidgetItem
                id={item.product.id}
                title={item.product.title}
                price={item.price}
                currency={currency}
              />
            ) : (
              undefined
            )}

            {indexShown <= 2 && item.length > 2 && (isBox || isCustom) ? (
              <p
                onClick={() => {
                  this.showAllItems();
                }}
              >
                {`+ ${itemsLeftToRender} more items`}
              </p>
            ) : null}
          </div>
          {/* COMPONENT FOOTER */}
          <div
            className={`${styles.widgetFooter} text-dark dark:text-white hidden md:flex justify-between`}
          >
            <div />
            <div>
              <h3 style={{ textAlign: "right" }}>
                {amount}
                <span>&#8226;</span>
                {itemPrice}
                {/* {isBox || isCustom
                  ? itemPrice
                  : item.length * itemPrice} */}
              </h3>
              {!isDoughProOrder ? (
                <span
                  onClick={() => {
                    this.handlelOrderModification();
                  }}
                >
                  Modify Order
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return <this.orderReviewWidgetContainer />;
  }
}

OrderReviewWidget.propTypes = {
  order: PropTypes.shape({
    orderTypeId: PropTypes.number
  }),
  product: PropTypes.shape.isRequired,
  addGiftBox: PropTypes.bool,
  giftBoxPrice: PropTypes.number,
  isGift: PropTypes.bool,
  onGiftClick: PropTypes.func,
  item: PropTypes.shape({}).isRequired,
  itemPrice: PropTypes.oneOfType( [
    PropTypes.number, 
    PropTypes.string
   ]).isRequired,
  itemTitle: PropTypes.string.isRequired,  
  removeItemMutation: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  unavailableProducts: PropTypes.arrayOf(PropTypes.string)
};

OrderReviewWidget.defaultProps = {
  order: null,
  addGiftBox: false,
  giftBoxPrice: null,
  isGift: false,
  onGiftClick: null,
  unavailableProducts: []
};

export const mapStateToProps = (state) => {
  const { userCart, userCartId } = state.user;
  const { currency } = state.currency;
  return { userCart, userCartId, currency, order: state.order };
};

export const mapDispatchToProps = (dispatch) => ({
  setUserCart: (value) => dispatch(userActions.setUserCart(value)),
  setRedeemableItem: value => dispatch(loyaltyAction.setRedeemableItem(value)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderReviewWidget)
);
