/* eslint-disable import/prefer-default-export */
import gql from "graphql-tag";

export const GET_CHALLENGE = gql`
  query GET_CHALLENGE {
    getChallenge {
        id
        title
        description
        explanation
        rewardImage
        rewardCompleteImage
        customerLevel
        actionCountRequired
        learnMoreText
        endDateTime
        rewardProduct {
          id
          title
          hasAssortments
          options {
            id
            title
          }
        }
        customerAward {
          id
          isRedeemed
        }
    }
  }
`;