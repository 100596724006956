/* eslint-disable import/prefer-default-export */
import showdown from 'showdown';
import * as cmsRepo from '../graphql/cms';
import * as locales from '../../constants/locales';
import { store } from '../../../redux/store';

export const getContent = async (keys, componentOrCallback, localeId = locales.defaultId) => {

    const { country } = store.getState();

    /* eslint-disable */
    const locale = country?.selectedCountry?.locale_id !== undefined ? country?.selectedCountry?.locale_id : localeId;
    /* eslint-enable */

    if (typeof componentOrCallback === 'function') {
      // For functional components
      componentOrCallback({ content: [] });
      const response = await cmsRepo.getContent(keys, locale);
      componentOrCallback({ loading: false, content: response.data.cmsData });
    } else {
      // For class-based components
      componentOrCallback.setState({ content: [] });
      const response = await cmsRepo.getContent(keys, locale);
      componentOrCallback.setState({ loading: false, content: response.data.cmsData });
    }
};

export const getValueByKeyFromCmsContent = (key, cmsContent) => {
    const content = cmsContent || [];
    const result = content.find(item => item.key === key);
    const converter = new showdown.Converter();
    let returnedValue = '';
    if(result) {
        returnedValue = converter.makeHtml(result.value);
    }
    return returnedValue;
};

export const getValueByKey = (key, component) => {
    const { content } = component.state;
    return getValueByKeyFromCmsContent(key, content);
};
