/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./SavedLocations.module.css";
import PencilSVG from "./imgs/pencil.svg";
import { CONTACTLESS_TEXT, HAND_IT_TO_ME_TEXT } from "../../shared/constants/order";
import { getLocationLabelImageByLabelId } from "../../shared/utilities/common";

class SavedLocationItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: true,
    };
  }

  getLocationString = (location) => {
    return location.locationLabel
      ? location.displayString
      : location.displayString.replace(location.addressDetails.address1 + ',', "");
  }

  getIsContactLessString = (isContactless) => {
    if (isContactless === null) {
      return '';
    }
    return isContactless ? CONTACTLESS_TEXT : HAND_IT_TO_ME_TEXT;
  }

  renderLabelContent(location) {
    return location.locationLabel ? (
      <div className={styles.labelBlock}>
        <span className={styles.labelText}>{location.locationLabel.label}</span>
      </div>
    ) : (
      <div className={styles.labelBlock}>
        <span className={styles.labelText}>{location.addressDetails.address1}</span>
      </div>
    );
  }

  render() {
    const { className, location, onClick, onEditClick } = this.props;
    const { isCollapsed } = this.state;
    const img = getLocationLabelImageByLabelId(location.locationLabel?.id);
    return (
      <div role={onClick ? "button" : null}
        onClick={onClick ? () => { onClick(location); } : () => {}}
        className={[className, styles.savedAddressItem, onClick ? styles.clickable : null].join(' ')}>

        <div className={styles.icon}><img className="dark:image-negative" src={img} alt="" /></div>
        <div className={styles.addressContent}>
          { this.renderLabelContent(location) }
          <div className={styles.addressText}> { this.getLocationString(location) } </div>
          { isCollapsed ? null : (
            <div className={styles.lastOrderInfoBlock}>
              <div className={styles.lastOrderInfoItem}>
                <div className={styles.lastOrderInfoTitle}>Delivery Notes</div>
                <div className={styles.lastOrderInfoText}>{ location.locationData.comment }</div>
              </div>
              <div className={styles.lastOrderInfoItem}>
                <div className={styles.lastOrderInfoTitle}>Delivery Preference</div>
                <div className={styles.lastOrderInfoText}>{ this.getIsContactLessString(location.locationData.isContactless) }</div>
              </div>
            </div>
          )}
        </div>
        <div className={[styles.options].join(' ')}>
          <img role="button" src={PencilSVG} className="dark:image-negative" onClick={(e) => {e.stopPropagation(); onEditClick(location);}} title="Edit Address" alt="Edit" />
        </div>
      </div>
    );
  }
}

SavedLocationItem.propTypes = {
  className: PropTypes.string,
  location: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    displayString: PropTypes.string,
    sourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    source: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    locationData: PropTypes.shape({
      comment: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      isContactless: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
    }),
    addressDetails: PropTypes.shape({
      address2: PropTypes.string,
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      lng: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
    }),
    locationLabel: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.string,
      }),
      PropTypes.oneOf([null]),
    ]),
  }).isRequired,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  onEditClick: PropTypes.func,
};

SavedLocationItem.defaultProps = {
  className: "",
  onClick: null,
  onEditClick: () => {},
};

export default SavedLocationItem;
