import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import _ from 'underscore';
import {
  getContentsByKey,
  getContentValueByKey
} from "../../../shared/utilities/common";
import styles from "./Offerings.module.css";
import CmsInlineEdit from "../../Cms/CmsInlineEdit";

function Offerings({ contents, openDeliveryWidget }) {
  const title = _.find(contents, {key: "title"});
  const description = _.find(contents, {key: "description"});
  const offers = _.find(contents, {key: "offers"})?.children || [];

  const getBottomSection = item => {
    const heading = getContentValueByKey(item.children, "heading");
    const image = getContentValueByKey(item.children, "image");
    const link = getContentsByKey(item.children, "link");
    const linkTitle = getContentValueByKey(link, "title");
    const linkUrl = getContentValueByKey(link, "url");
    return (
      <div className="col-span-1 grid grid-cols-3 lg:flex flex-col gap-3 items-center justify-center">
        <div
          id="bottomDiv"
          onClick={() => openDeliveryWidget(linkUrl)}
          onKeyDown={() => openDeliveryWidget(linkUrl)}
          role="button"
          tabIndex={0}
          className="col-span-3 grid grid-cols-3 lg:flex flex-col lg:gap-3 items-center justify-center"
        >
          <ReactMarkdown className={`${styles.productImage} col-span-1 `}>
            {image}
          </ReactMarkdown>
          <h3 className="col-span-2 my-4 font-black text-4xl leading-7 tracking-[-0.2px] text-dark dark:text-white">
            {heading}
          </h3>
        </div>

        <div className="lg:flex items-center justify-center relative h-[54px] hidden">
          {linkUrl && linkTitle && (
            <button
              onClick={() => openDeliveryWidget(linkUrl)}
              type="button"
              className="bg-button text-light hidden lg:block w-[132px] h-[54px] rounded-[54px] text-center font-filsonProMedium"
            >
              {linkTitle}
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="w-full px-4 lg:p-0 m-0">
      <div className="max-w-screen-desktop py-8 m-auto flex flex-col gap-2">
        <h1 className="font-congenialBlack text-4xl leading-9 tracking-[-1px] text-dark dark:text-white">
          <CmsInlineEdit cmsNode={title}>{title?.value}</CmsInlineEdit>
        </h1>
        <p className="font-filsonProRegular text-lg leading-5 md:leading-[22px] tracking-[-0.1px] text-darkElevationPrimary dark:text-darkElevationSecondary mt-2 mb-8">
          <CmsInlineEdit cmsNode={description}>{description?.value}</CmsInlineEdit>
        </p>
        <CmsInlineEdit type="list" cmsNode={{children: offers}} showChilds>
          <div className="lg:grid grid-cols-3 place-content-center place-items-center 2xl:gap-36 gap-8">
            {offers?.map(item => {
              return getBottomSection(item);
            })}
          </div>
        </CmsInlineEdit>
      </div>
    </div>
  );
}

Offerings.propTypes = {
  contents: PropTypes.shape({}),
  openDeliveryWidget: PropTypes.func
};

Offerings.defaultProps = {
  contents: null,
  openDeliveryWidget: null
};

export default Offerings;
