/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./SavedLocations.module.css";
import BackSVG from "./imgs/back.svg";
import CurrentLocationSVG from "./imgs/currentLocation.svg";
import UnlabeledAddressSVG from "./imgs/unlabeledaddress.svg";
import LocationSearchInput from "../Input/LocationSearchInput";
import { CONTACTLESS_TEXT, HAND_IT_TO_ME_TEXT } from "../../shared/constants/order";
import TagSelector from "../OrderExperience/TagSelector";
import { PROVIDERS } from "../../shared/constants/common";
import { LOCATION_TYPES } from "../../shared/constants/locations";
import Loader from "../Loader/Loader";
import ddLog from '../../shared/utilities/datadog/log';
import LocationLabelSelector from "../LocationLabelSelector/LocationLabelSelector";
import { deleteLocation, saveLocation } from "../../shared/repos/graphql/user";

class SavedLocationForm extends Component {

  isContactLessTagSelectorValues = [
    {
      id: 0,
      title: HAND_IT_TO_ME_TEXT,
    },
    {
      id: 1,
      title: CONTACTLESS_TEXT,
    },
  ];

  blankLocation = {
    id: null,
    sourceId: null,
    displayString: '',
    source: {
      id: PROVIDERS.INSOMNIA_COOKIES,
    },
    locationData: {
      comment: '',
      isContactless: null,
    },
    addressDetails: {
      address2: '',
      lat: null,
      lng: null,
      dormId: null,
    },
    locationLabel: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      editLocation: this.blankLocation,
      minimumStep: 0,
      currentStep: 1,
      locationSearchData: [],
      clLat: null,
      clLng: null,
      currentLocation: null,
      commentLength: 0,
      commentMaxLength: 150,
      isProcessing: false,
      error: null,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    try {
      navigator.geolocation.getCurrentPosition(this.handleCurrentPosition, this.handleCurrentPositionError);
    } catch (e) {
      this.handleCurrentPositionError(e);
    }

    const edit = {
      ...this.blankLocation,
      id: location?.id || null,
      locationLabel: location?.locationLabel ? { id: location.locationLabel.id, label: location.locationLabel.label } : null,
      sourceId: location?.sourceId || null,
      displayString: location?.displayString || '',
      source: {
        id: location?.source?.id || PROVIDERS.INSOMNIA_COOKIES,
      },
      locationData: {
        comment: location?.locationData?.comment || '',
        isContactless: location?.locationData?.isContactless === null ? null : +location?.locationData?.isContactless,
      },
      addressDetails: {
        address2: location?.addressDetails?.address2 || '',
        lat: location?.addressDetails?.lat || null,
        lng: location?.addressDetails?.lng || null,
        dormId: location?.addressDetails?.dormId || null,
      },
    }
    const minStep = edit.id ? 2 : 1;
    this.setState({
      editLocation: edit,
      minimumStep: minStep,
      currentStep: minStep,
      commentLength: edit?.locationData?.comment ? edit.locationData.comment.length : 0,
    });
  }

  handleCurrentPosition = (position) => {
    const { coords } = position;
    if (coords && coords.latitude && coords.longitude) {
      this.setState({
        clLat: coords.latitude,
        clLng: coords.longitude,
      });
    }
  }

  onHandleCurrentPositionGeocode = (locations) => {
    if (locations && locations.length > 0) {
      this.setState({
        currentLocation: locations[0],
      });
    }
  }

  getStepTitle = () => {
    const { currentStep, editLocation } = this.state;
    switch (currentStep) {
      case 1:
        return `Set ${editLocation?.locationLabel?.label || ''} Address`;
      case 2:
        return "Edit Address";
      default:
        return "Edit Address";
    }
  }

  handleCurrentPositionError = () => {
    this.setState({
      clLat: null,
      clLng: null,
      currentLocation: null,
    });
  }

  goBack = () => {
    const { currentStep, minimumStep } = this.state;
    const { onClose } = this.props;
    if (currentStep <= minimumStep) {
      this.setState({
        editLocation: this.blankLocation,
        locationSearchData: [],
      });
      onClose();
    } else {
      this.setState({
        currentStep: currentStep - 1,
      });
    }
  }

  getProviderAndExternalId = (location) => {
    const locationType = location.type?.id || LOCATION_TYPES.ADDRESS;
    switch (locationType) {
      case LOCATION_TYPES.ADDRESS:
        return {
          providerId: PROVIDERS.GOOGLE_MAP,
          externalId: location.externalId || null,
        };
      case LOCATION_TYPES.DORM:
        return {
          providerId: PROVIDERS.INSOMNIA_DORMS,
          externalId: location.addressDetails.dormId.toString(),
        };
      default:
        return {
          providerId: PROVIDERS.GOOGLE_MAP,
          externalId: location.externalId,
        };
    }
  }

  selectLocation = (location) => {
    const { editLocation, currentStep } = this.state;
    const providerAndExternalId = this.getProviderAndExternalId(location);
    const mutatedLocation = {
      ...editLocation,
      displayString: location.displayString || location?.address1 || '',
      addressDetails: {
        ...editLocation.addressDetails,
        address2: location.addressDetails?.address2 || '',
        lat: location.lat || location?.addressDetails?.lat || null,
        lng: location.lng || location?.addressDetails?.lng || null,
      },
      sourceId: providerAndExternalId.externalId,
      source: {
        id: providerAndExternalId.providerId,
      }
    };
    this.setState({
      editLocation: mutatedLocation,
      currentStep: currentStep + 1,
      locationSearchData: [],
    });
  }

  getAddressAndLabel = (editLocation) => {
    let label = editLocation?.locationLabel?.label;
    let address = editLocation?.displayString;
    if (!label) {
      try {
        const address1Array = address.split(",");
        if (address1Array.length > 1) {
          label = address1Array[0];
          address = editLocation?.displayString.replace(label + ",", "");
        }
      } catch (e) {
        ddLog.logError(e);
      }
    }
    return { label, address };
  }

  submit = async () => {
    const { onSubmited } = this.props;
    const { editLocation, isProcessing } = this.state;
    if (isProcessing) {
      return;
    }
    this.setState({
      isProcessing: true,
      error: null,
    });
    try {
      let isContactlessBool = null;
      if (editLocation?.locationData?.isContactless !== undefined && editLocation?.locationData?.isContactless !== null) {
        isContactlessBool = editLocation.locationData.isContactless === 1;
      };
      const locationData = {...editLocation.locationData, isContactless: isContactlessBool};
      const result = await saveLocation({...editLocation, locationData: locationData});
      this.setState({
        isProcessing: false,
      });
      await onSubmited(result);
    } catch (e) {
      ddLog.logError(e);
      this.setState({
        isProcessing: false,
        error: 'Error while saving address. Please try again later.',
      });
    }
  }

  onChangeAddress2 = (e) => {
    const { editLocation } = this.state;
    const mutatedLocation = {
      ...editLocation,
      addressDetails: {
        ...editLocation.addressDetails,
        address2: e.target.value,
      }
    };
    this.setState({
      editLocation: mutatedLocation,
    });
  }

  onCommentChange = (e) => {
    const { editLocation, commentLength, commentMaxLength } = this.state;
    const val = e.target.value.substring(0, commentMaxLength);
    const mutatedAddress = {
      ...editLocation,
      locationData: {
        ...editLocation.locationData,
        comment: val,
      },
    };
    this.setState({
      editLocation: commentLength <= commentMaxLength ? mutatedAddress : editLocation,
      commentLength: val.length,
    });
  };

  onChangeIsContactLess = (selected) => {
    const { editLocation } = this.state;
    const mutatedLocation = {
      ...editLocation,
      locationData: {
        ...editLocation.locationData,
        isContactless: selected.length > 0 ? selected[0] : null,
      },
    };
    this.setState({
      editLocation: mutatedLocation,
    });
  };

  onLabelChange = (label) => {
    const { editLocation } = this.state;
    const mutatedLocation = {
      ...editLocation,
      locationLabel: label,
    };
    this.setState({
      editLocation: mutatedLocation,
    });
  };

  onDeleteLocation = async () => {
    const { editLocation, isProcessing } = this.state;
    const { onDeleted } = this.props;
    if (isProcessing) {
      return;
    }
    this.setState({
      isProcessing: true,
    });
    try {
      const result = await deleteLocation({id: editLocation.id, sourceId: editLocation.sourceId, source: editLocation.source});
      const { data } = result;
      this.setState({
        isProcessing: false,
        error: data.deleteLocation.success ? null : data.deleteLocation.message,
      });
      await onDeleted(data.deleteLocation);
    } catch (e) {
      this.setState({
        isProcessing: false,
        error: 'Error while deleting address. Please try again later.',
      });
      ddLog.logError(e);
    }
  }

  renderLocationItem = (location) => {
    const { address1, externalId, id } = location;
    const address = address1.split(",")[0];
    const address2 = address1.replace(address + ',', "");
    return (
      <div key={externalId || id} className={[styles.locationItem, 'savedAddressFormContentlocationItem'].join(' ')} onClick={() => this.selectLocation(location)}>
        <div className={styles.locationItemIcon}><img className="dark:image-negative" src={UnlabeledAddressSVG} alt="" /></div>
        <div className={styles.locationItemContent}>
          <div className={styles.locationItemAddress}>{address}</div>
          <div className={styles.locationItemAddress2}>{address2}</div>
        </div>
        <div className={styles.locationItemAction}>
          <img src={BackSVG} className="dark:image-negative" alt=">" />
        </div>
      </div>
    );
  }

  renderCurrentLocation = () => {
    const { currentLocation, clLat, clLng } = this.state;
    const address = currentLocation?.address1;
    const cLocation = { ...currentLocation, lat: clLat, lng: clLng };
    return (
      <div className={[styles.locationItem, 'savedAddressFormContentlocationItem'].join(' ')} onClick={() => this.selectLocation(cLocation)}>
        <div className={styles.locationItemIcon}><img className="dark:image-negative" src={CurrentLocationSVG} alt="" /></div>
        <div className={styles.locationItemContent}>
          <div className={styles.locationItemAddress}>Use current location</div>
          <div className={styles.locationItemAddress2}>{address}</div>
        </div>
        <div className={styles.locationItemAction}>
          <img className="dark:image-negative" src={BackSVG} alt=">" />
        </div>
      </div>
    );
  }

  renderCurrentLocationSection = () => {
    const { clLat, clLng, currentLocation, locationSearchData } = this.state;
    if (clLat !== null && clLng !== null) {
      return (
        <div className={styles.currentPositionBlock}>
          <LocationSearchInput className={styles.hiddenInput} lat={clLat} lng={clLng} onChange={this.onHandleCurrentPositionGeocode} />
          { currentLocation && locationSearchData.length === 0 ? this.renderCurrentLocation() : null }
        </div>
      );
    }
    return null;
  }

  renderStep1 = () => {
    const { locationSearchData } = this.state;
    return (
      <div className={styles.savedAddressFormContent}>
        <LocationSearchInput className="dark:text-dark" onChange={(locations) => {this.setState({locationSearchData: locations})}} />
        { this.renderCurrentLocationSection() }
        { locationSearchData.map(location => this.renderLocationItem(location)) }
      </div>
    );
  }

  renderStep2 = () => {
    const { editLocation, commentLength, commentMaxLength, error } = this.state;
    const titleData = this.getAddressAndLabel(editLocation);
    const isDeleteAvailable = editLocation.id !== null;
    let isContactless = [];
    if (editLocation?.locationData?.isContactless !== undefined && editLocation?.locationData?.isContactless !== null) {
      isContactless = [editLocation.locationData.isContactless];
    }
    return (
      <div className={styles.savedAddressFormContent}>
        <div className={[styles.addressInfo, 'mb-12'].join(' ')}>
          <div className={[styles.addressInfoLabel, 'mb-3'].join(' ')}>Address Label</div>
          <LocationLabelSelector placeholder="E.g. home, work, school etc" inputClassName={[styles.savedAddressFormInput, 'dark:text-dark'].join(' ')} value={editLocation.locationLabel} onChange={this.onLabelChange} />
        </div>
        <div className={styles.addressInfo}>
          <div className={styles.addressInfoLabel}>{titleData.label}</div>
          <div className={styles.addressInfoAddress}>{titleData.address}</div>
        </div>
        <input type="text" className={[styles.savedAddressFormInput, 'mb-8 dark:text-dark'].join(' ')} placeholder="Apt, Suite, Unit, Dorm" value={editLocation.addressDetails.address2} onChange={this.onChangeAddress2} />
        <div className={[styles.addressInfo, 'mb-8'].join(' ')}>
          <div className={[styles.addressInfoLabel, 'mb-3'].join(' ')}>Delivery Notes</div>
          <TagSelector isMultiple={false} className={[styles.tagSelector, 'mb-2'].join(' ')} selected={isContactless} metatags={this.isContactLessTagSelectorValues} onChange={this.onChangeIsContactLess} />
          <textarea className={[styles.savedAddressFormInput, 'dark:text-dark'].join(' ')} placeholder="e.g. Call upon arrival, Security code, or other delivery instructions" value={editLocation.locationData.comment} onChange={this.onCommentChange} />
          <div className={styles.charCounter}>{commentLength}/{commentMaxLength} characters</div>
        </div>
        <div className={styles.btnSection}>
          { isDeleteAvailable ? (<div onClick={this.onDeleteLocation} role="button" className={styles.deleteBtn}>Delete</div>) : null }
          <button
            type="button"
            className={`bg-dark dark:bg-button text-white flex items-center justify-center ${styles.submitBtn}`}
            onClick={ this.submit }
          >Save Address</button>
        </div>
        { error ? (<div className={styles.errorMessage}>{ error }</div>) : null }
      </div>
    );
  }

  renderStep = () => {
    const { currentStep } = this.state;
    switch (currentStep) {
      case 1:
        return this.renderStep1();
      case 2:
        return this.renderStep2();
      default:
        return this.renderStep2();
    }
  }

  render() {
    const { className } = this.props;
    const { isProcessing } = this.state;
    return (
      <div className={[className].join(' ')}>
        <div className={styles.savedAddressFormTitle}>
          <div className={styles.goToStep} onClick={this.goBack}><img role="link" className="dark:image-negative" src={BackSVG} alt="Go Back" /></div>
          <div className={styles.formTitle}>{ this.getStepTitle() }</div>
          <div className={styles.actionBlock}></div>
        </div>
        { this.renderStep() }
        { isProcessing ? (
          <div className={styles.loaderContainer}>
            <div className={styles.loaderBackground}></div>
            <Loader />
          </div>) : null }
      </div>
    );
  }
}

SavedLocationForm.propTypes = {
  className: PropTypes.string,
  location: PropTypes.oneOfType([PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf([null])]),
    displayString: PropTypes.string,
    sourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    source: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    locationData: PropTypes.shape({
      comment: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      isContactless: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
    }),
    addressDetails: PropTypes.shape({
      address2: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      lat: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      lng: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      dormId: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf([null])]),
    }),
    locationLabel: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf([null])]),
        label: PropTypes.string,
      }),
      PropTypes.oneOf([null]),
    ]),
  }), PropTypes.oneOf([null])]),
  onClose: PropTypes.func,
  onSubmited: PropTypes.func,
  onDeleted: PropTypes.func,
};

SavedLocationForm.defaultProps = {
  className: "",
  location: null,
  onClose: () => {},
  onSubmited: () => {},
  onDeleted: () => {},
};

export default SavedLocationForm;
