/* eslint-disable react/forbid-prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./OrderDetailsWidget.module.css";
import { CONTACTLESS_TEXT, HAND_IT_TO_ME_TEXT, orderDetailsWidgetModes } from "../../shared/constants/order";
import { getOrderScheduledDateString } from "../../shared/utilities/orders";
import PhoneInputWidget from "../PhoneInputWidget/PhoneInputWidget";
import DeliveryTimeWidget from "../DeliveryTimeWidget/DeliveryTimeWidget";
import Input from "../Input/Input";
import provider from "../../shared/constants/provider";

class OrderDetailsDelivery extends Component {
  setPhoneNumber = e => {
    const { onChange, editOrderData } = this.props;
    const telephone = e.telephone ? e.telephone : null;
    onChange({
      ...editOrderData,
      telephone
    });
  };

  updateDeliveryTime = deliveryTime => {
    const { onChange, editOrderData } = this.props;
    onChange({
      ...editOrderData,
      deliveryTime
    });
  };

  editInput = (e, field) => {
    const { onChange, editOrderData } = this.props;
    const { value } = e.target;
    const result = {};
    result[field] = value;
    onChange({
      ...editOrderData,
      ...result
    });
  };

  setContactlessDelivery = async (value) => {
    const { onChange, editOrderData } = this.props;
    const result = {};
    result.isContactlessDelivery = value;
    onChange({
      ...editOrderData,
      ...result
    });
  }

  renderViewDeliveryTime = () => {
    const { order } = this.props;

    return (
      <div className={styles.orderDetailsLine}>
        <div className={styles.iconCell} />
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Delivery Time</div>
          <div className={styles.orderDetailsSubtitle}>
            {getOrderScheduledDateString(order)}
          </div>
        </div>
        <div className={styles.buttonCell} />
      </div>
    );
  };

  renderEditDeliveryTime = () => {
    const { order, editOrderErrors } = this.props;
    return (
      <div className={styles.orderDetailsLine}>
        <div className={styles.iconCell} />
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Delivery Time</div>
          <div className={styles.orderDetailsSubtitle}>
            <DeliveryTimeWidget
              className={`${styles.deliveryTimeWidget} selectDeliveryTime border-b-2 border-lightDry dark:border-darkDry text-dark dark:text-white`}
              orderCode={order.code}
              customOrder={order}
              justReturnMode
              forceExpanded
              forceDisableExpand
              onDeliveryTimeUpdated={this.updateDeliveryTime}
              component={this}
            />
            {editOrderErrors.DELIVERY_TIME_ERROR ? (
              <div className={`${styles.formError}`}>
                {editOrderErrors.DELIVERY_TIME_ERROR}
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.buttonCell} />
      </div>
    );
  };

  renderViewPhone = () => {
    const { order } = this.props;

    return (
      <div className={styles.orderDetailsLine}>
        <div className={styles.iconCell} />
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Phone number</div>
          <div className={styles.orderDetailsSubtitle}>
            {order?.recipient?.phone}
          </div>
        </div>
        <div className={styles.buttonCell} />
      </div>
    );
  };

  renderDeliveryAddressView = () => {
    const { order } = this.props;
    const { shippingAddress } = order;
    const address = `${shippingAddress.address1}, ${shippingAddress.city}, ${shippingAddress.state}, ${shippingAddress.postcode}`;

    return (
      <div className={styles.orderDetailsLine}>
        <div className={styles.iconCell} />
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Delivery Address</div>
          <div className={styles.orderDetailsSubtitle}>{address}</div>
        </div>
        <div className={styles.buttonCell} />
      </div>
    );
  };

  renderAddress2View = () => {
    const { order } = this.props;
    const address2 = order.shippingAddress2;

    return (
      <div className={styles.orderDetailsLine}>
        <div className={styles.iconCell} />
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Building / Apt / Dorm</div>
          <div className={styles.orderDetailsSubtitle}>{address2}</div>
        </div>
        <div className={styles.buttonCell} />
      </div>
    );
  };

  renderEditAddress2 = () => {
    const { editOrderData } = this.props;

    return (
      <div className={styles.orderDetailsLine}>
        <div className={styles.iconCell} />
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Building / Apt / Dorm</div>
          <div className={styles.orderDetailsSubtitle}>
            <Input
              customContainerStyles={styles.inputField}
              handleInput={e => this.editInput(e, "shippingAddress2")}
              placeholder=" "
              value={editOrderData.shippingAddress2}
            />
          </div>
        </div>
        <div className={styles.buttonCell} />
      </div>
    );
  };

  renderIsContactlessView = () => {
    const { order } = this.props;
    return (
      <div className={styles.orderDetailsLine}>
        <div className={styles.iconCell} />
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Delivery Preferences</div>
          <div className={styles.orderDetailsSubtitle}>
            {order.isContactlessDelivery
              ? CONTACTLESS_TEXT
              : HAND_IT_TO_ME_TEXT}
          </div>
        </div>
        <div className={styles.buttonCell} />
      </div>
    );
  };

  renderEditIsContactlessView = () => {
    const { editOrderData } = this.props;

    if(!editOrderData.showContactless) {
      return null;
    }

    return (
      <div className={styles.orderDetailsLine}>
        <div className={styles.iconCell} />
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Delivery Preferences</div>
          <div className={styles.orderDetailsSubtitle}>
            <p className="flex gap-2">
              <input
                type="radio"
                name="isContactlessDelivery"
                id="handIttoMe"
                value="No"
                checked={!editOrderData?.isContactlessDelivery}
                onChange={() => this.setContactlessDelivery(false)}
              />
              {/* eslint-disable-next-line */}
              <label htmlFor="handIttoMe">{HAND_IT_TO_ME_TEXT}</label>
            </p>
            <p className="flex gap-2">
              <input
                type="radio"
                name="isContactlessDelivery"
                id="leaveItAtDoor"
                value="Yes"
                checked={editOrderData?.isContactlessDelivery}
                onChange={() => this.setContactlessDelivery(true)}
              />
              {/* eslint-disable-next-line */}
              <label htmlFor="leaveItAtDoor">{CONTACTLESS_TEXT}</label>
            </p>
          </div>
        </div>
        <div className={styles.buttonCell} />
      </div>
    );
  };

  renderDeliveryNotesView = () => {
    const { order } = this.props;
    return (
      <div className={styles.orderDetailsLine}>
        <div className={styles.iconCell} />
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Delivery Notes</div>
          <div className={styles.orderDetailsSubtitle}>{order.comment}</div>
        </div>
        <div className={styles.buttonCell} />
      </div>
    );
  };

  renderEditDeliveryNotes = () => {
    const { editOrderData } = this.props;

    return (
      <div className={styles.orderDetailsLine}>
        <div className={styles.iconCell} />
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Delivery Notes</div>
          <div className={styles.orderDetailsSubtitle}>
            <Input
              customContainerStyles={styles.inputField}
              handleInput={e => this.editInput(e, "comment")}
              placeholder=" "
              value={editOrderData.comment}
            />
          </div>
        </div>
        <div className={styles.buttonCell} />
      </div>
    );
  };

  renderEditPhone = () => {
    const { order, editOrderErrors } = this.props;
    return (
      <div className={styles.orderDetailsLine}>
        <div className={styles.iconCell} />
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>Phone number</div>
          <div className={styles.orderDetailsSubtitle}>
            <PhoneInputWidget
              className={styles.phoneInput}
              customSelectStyles={styles.phoneInputSelect}
              customInputStyles={styles.phoneInputInput}
              onInput={e => this.setPhoneNumber(e)}
              label="Phone number"
              placeholder=""
              telephone={order.recipient.phone}
              isNumberFormat
            />
            {editOrderErrors.TELEPHONE_VALIDATION_ERROR ? (
              <div className={`${styles.formError}`}>
                {editOrderErrors.TELEPHONE_VALIDATION_ERROR}
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.buttonCell} />
      </div>
    );
  };

  renderGiftRecipientView = () => {
    const { order } = this.props;

    let recipientData = "";
    if (order.recipient) {
      recipientData = `${order.recipient?.firstName} ${order.recipient?.lastName}, ${order.recipient?.phone}`;
    }

    const isRecipientAndSenderEquals =
      order.recipient?.phone === order.sender?.phone &&
      order.recipient?.firstName === order.sender?.firstName &&
      order.recipient?.lastName === order.sender?.lastName;

    return recipientData && !isRecipientAndSenderEquals ? (
      <div className={styles.orderDetailsLine}>
        <div className={styles.iconCell} />
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>
            Gift Recipient Contact Information
          </div>
          <div className={styles.orderDetailsSubtitle}>{recipientData}</div>
        </div>
        <div className={styles.buttonCell} />
      </div>
    ) : null;
  };

  renderOrderSenderView = () => {
    const { order } = this.props;

    let senderData = "";
    if (order.sender) {
      senderData = `${order.sender?.firstName} ${order.sender?.lastName}, ${order.sender?.phone}, ${order.sender?.email}`;
    }

    return senderData ? (
      <div className={styles.orderDetailsLine}>
        <div className={styles.iconCell} />
        <div className={styles.contentCell}>
          <div className={styles.orderDetailsTitle}>
            Your Contact Information
          </div>
          <div className={styles.orderDetailsSubtitle}>{senderData}</div>
        </div>
        <div className={styles.buttonCell} />
      </div>
    ) : null;
  };

  renderView = () => {
    return (
      <div>
        {this.renderViewDeliveryTime()}
        {this.renderDeliveryAddressView()}
        {this.renderAddress2View()}
        {this.renderGiftRecipientView()}
        {this.renderOrderSenderView()}
        {this.renderIsContactlessView()}
        {this.renderDeliveryNotesView()}
        {this.renderViewPhone()}
      </div>
    );
  };

  renderEdit = () => {
    const { order } = this.props;
    return (
      <div>
        {!order?.deliveryStatus?.deliveryServiceDeliveryId || 
        parseInt (order?.deliveryStatus?.deliveryServiceDeliveryId, 10) === provider.insomnia
          ? this.renderEditDeliveryTime()
          : this.renderViewDeliveryTime()}        
        {this.renderDeliveryAddressView()}
        {this.renderEditAddress2()}
        {this.renderGiftRecipientView()}
        {this.renderOrderSenderView()}
        {this.renderEditIsContactlessView()}
        {this.renderEditDeliveryNotes()}
        {this.renderEditPhone()}
      </div>
    );
  };

  render() {
    const { mode } = this.props;

    return mode === orderDetailsWidgetModes.view
      ? this.renderView()
      : this.renderEdit();
  }
}

OrderDetailsDelivery.propTypes = {
  order: PropTypes.object.isRequired,
  mode: PropTypes.string,
  onChange: PropTypes.func,
  editOrderErrors: PropTypes.shape({
    TELEPHONE_VALIDATION_ERROR: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    DELIVERY_TIME_ERROR: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    COMMON_VALIDATION_ERROR: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  }),
  editOrderData: PropTypes.shape({
    showContactless: PropTypes.bool,
    isContactlessDelivery: PropTypes.bool,
    includeSpoon: PropTypes.bool,
    deliveryTime: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    telephone: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    comment: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    shippingAddress2: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  }).isRequired,
};
  
OrderDetailsDelivery.defaultProps = {
  mode: orderDetailsWidgetModes.view,
  editOrderErrors: {
    TELEPHONE_VALIDATION_ERROR: null,
    DELIVERY_TIME_ERROR: null,
    COMMON_VALIDATION_ERROR: null,
  },
  onChange: () => {},
};
  
export default OrderDetailsDelivery;