/* eslint-disable react/prop-types */

import React, { Component }from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from '../ReviewOrder.module.css';
import CheckBox from '../../../components/CheckBox/CheckBox';
import CheckoutContainer from "../../../components/CheckOutContainer/CheckoutContainer";
import * as checkoutActions from "../../../redux/actions/checkout";

export class PickupInstructions extends Component {

    constructor(props) {
        super(props);

        const { order } = this.props;

        this.state = {
            includeSpoon: order.includeSpoon,
            pickupInstructionsOpen: true,
        }
    }

    onExpandDeliveryNotes = (expand) => {
        this.setState({ pickupInstructionsOpen: expand });
    }

    toggleIncludeSpoon = () => {
        const { includeSpoon } = this.state;
        const { checkout, checkoutUpdated } = this.props;

        this.setState({
            includeSpoon: !includeSpoon
        });

        checkout.includeSpoon = !includeSpoon;
        checkoutUpdated(checkout);
    }

    render () {
        const { pickupInstructionsOpen, includeSpoon } = this.state;
        const { checkout } = this.props;
  
        return (
          <CheckoutContainer
            header="Pickup Preferences"
            subHeader="Set your preferences for pickup"
            subHeaderClass={styles.deliveryNotesSubTitle}
            onExpand={val => this.onExpandDeliveryNotes(val)}
            expanded={pickupInstructionsOpen}
            className={styles.minHeight}
          >
            <p
              className={[
                styles.checkoutSectionHeader,
                styles.sectionHeader,
                "text-dark dark:text-white"
              ].join(" ")}
            >
              Pickup Preferences
            </p>
            <div
              className={`${styles.floatRight} w-full flex`}
            >
              <CheckBox
                onClick={() => this.toggleIncludeSpoon()}
                isChecked={checkout.includeSpoon || includeSpoon}
                label="Include Spoon"
              />
            </div>
          </CheckoutContainer>
        );
    };
}

PickupInstructions.defaultProps = {
    checkout: {},
};

PickupInstructions.propTypes = {
    order: PropTypes.shape().isRequired,
    checkout: PropTypes.shape(),
    checkoutUpdated: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
    return {
        order: state.order,
        checkout: state.checkout
    }
}

export const mapDispatchToProps = (dispatch) => ({
    checkoutUpdated: (value) => dispatch(checkoutActions.checkoutUpdated(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PickupInstructions);