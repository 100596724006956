/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./LocationLabelWidget.module.css";
import { getLocationLabelImageByLabelId } from "../../shared/utilities/common";

class LocationLabelItem extends Component {

  constructor(props) {
    super(props);
    this.state = {
        
    };
  }

  onLabelClick = (label) => {
    const { onLabelClick } = this.props;
    if (onLabelClick) {
      onLabelClick(label);
    }
  }

  onSetAddressClick = (label) => {
    const { onSetAddressClick } = this.props;
    if (onSetAddressClick) {
      onSetAddressClick(label);
    }
  }

  renderSetAddressButton = (label) => {
    const { onSetAddressClick } = this.props;
    return (
      <span role={onSetAddressClick ? "button" : null} className={[styles.setAddress, onSetAddressClick ? styles.clickable : null].join(' ')} onClick={(e) => { e.stopPropagation(); this.onSetAddressClick(label); }}>Set Address</span>
    );
  }

  render = () => {
    const { onLabelClick, label, className } = this.props;
    const image = getLocationLabelImageByLabelId(label.id);
    return (
      <div role={onLabelClick ? "button" : null} className={[className, styles.label, onLabelClick ? styles.clickable : null].join(' ')} onClick={() => { this.onLabelClick(label) }}>
        <div className={styles.labelImage}>
          <img className="dark:image-negative" src={image} alt={label.label} />
        </div>
        <div className={styles.labelTextBlock}>
          <div className={styles.labelName}>{ label.label }</div>
          <div className={styles.labelAddress}>
            { label.locations.length > 0 ? label.locations[0].displayString : this.renderSetAddressButton(label) }
          </div>
        </div>
      </div>
    );
  }
}

LocationLabelItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      displayString: PropTypes.string,
    })),
  }).isRequired,
  onLabelClick: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
  onSetAddressClick: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
};

LocationLabelItem.defaultProps = {
  className: "",
  onLabelClick: null,
  onSetAddressClick: null,
};

export default LocationLabelItem;
