/* eslint-disable no-restricted-globals */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import Loader from '../../components/Loader/Loader';
import OrderTrackingWidget from "../../components/OrderTrackingWidget/OrderTrackingWidget";
import styles from './OrderDetails.module.css';
import widgetStyles from '../../components/OrderDetailsWidgets/OrderDetailsWidget.module.css';
import * as orderRepo from '../../shared/repos/graphql/order';
import * as elementsActions from "../../redux/actions/elements";
import * as orderExperiencesActions from "../../redux/actions/orderExperiences";

import { deliveryStatuses, orderExperienceSources, orderTrackingWidgetModes, orderTypeIds } from '../../shared/constants/order';
import OrderTotalsWidget from '../../components/OrderTotalsWidget/OrderTotalsWidget';
import OrderDetailsWidget from '../../components/OrderDetailsWidgets/OrderDetailsWidget';
import DropoffImageWidget from '../../components/OrderDetailsWidgets/DropoffImageWidget';
import { getOrderExperienceBySource, isMobileScreen, isOrderExpired, routeCountryPath } from '../../shared/utilities/common';
import SmileRating from '../../components/OrderExperience/SmileRating';
import SimpleModal from '../../components/Modal/SimpleModal';
import ExitIconSVG from '../../components/Modal/imgs/exit-icon.svg';
import modalStyles from '../../components/Modal/Modal.module.css';
import SmileRatingWidget from '../../components/OrderExperience/SmileRatingWidget';
import { getOrderExperienceRatingModalTitleAndSubtitle } from '../../components/OrderExperience/renderings';

class OrderDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            showRatingModal: false,
            postOrderRating: null,
            order: {}
        };
    }

    componentDidMount() {
        this.getOrder();
    }

    orderInProgress = () => {
        const { order } = this.state;

        if (order.isCancelled || order.isRefunded || order.isStale) {
          return false;
        }

        return order.status?.status < deliveryStatuses.DRIVER_RETURNED;
    }

    getOrder = async () => {
        const { match, setOrderExperiences } = this.props;

        const response = await orderRepo.getOrder(match.params.id);
        const { order } = response.data;
        const postOrderRating = getOrderExperienceBySource(order, orderExperienceSources.ORDER_DETAILS) || null;
        setOrderExperiences(order.orderExperiences || []);
        this.setState({
            loading: false,
            order: response.data.order,
            postOrderRating,
        });
    };

    onExperienceRatingChange = (rating) => {
      const { postOrderRating } = this.state;
      const updatetRating = { ...postOrderRating, rating };
      this.setState({
        postOrderRating: updatetRating,
        showRatingModal: true,
      });
    }

    renderYourExperienceRating = () => {
      const { order, postOrderRating } = this.state;
      const { orderExperiences } = this.props;
      const isExpired = isOrderExpired(order);
      const globalOrderExperiences = { orderExperiences: orderExperiences || [] };
      const isRateOrderAvailable = order.status?.status >= deliveryStatuses.DRIVER_RETURNED
        && !isExpired
        && !getOrderExperienceBySource(order, orderExperienceSources.APP_LAUNCH_MODAL)
        && !getOrderExperienceBySource(order, orderExperienceSources.CONFIRMATION_PAGE)
        && !getOrderExperienceBySource(order, orderExperienceSources.ORDER_DETAILS)
        && !getOrderExperienceBySource(globalOrderExperiences, orderExperienceSources.ORDER_DETAILS)
        && !getOrderExperienceBySource(globalOrderExperiences, orderExperienceSources.CONFIRMATION_PAGE)
        && !getOrderExperienceBySource(globalOrderExperiences, orderExperienceSources.APP_LAUNCH_MODAL);
      return isRateOrderAvailable ? (
        <div className={[
          widgetStyles.orderDetailsLineTitle,
          widgetStyles.borderBottom
        ].join(" ")}>
          <div className={widgetStyles.contentCell}>
            <div className={widgetStyles.orderDetailsTitle}>Rate your experience</div>
            <div className={widgetStyles.orderDetailsSubtitle}>
              How was your experience with Insomnia?
            </div>
          </div>
          <SmileRating rating={postOrderRating?.rating} onRatingChange={this.onExperienceRatingChange} />
        </div>
      ) : null;
    }

    goToHelpPage = () => {
      const { history } = this.props
      const { order } = this.state;
      const path = routeCountryPath(`/user/order-help/${order.code}`);
      history.push(path);
    }

    renderNeedHelpSection = () => {
      const { isFeedbackFormsEnabled } = this.props;
      const { order } = this.state;
      const isExpired = isOrderExpired(order);
      const isHelpButtonAvailable = order.status?.status >= deliveryStatuses.DRIVER_RETURNED
        && !isExpired
        && isFeedbackFormsEnabled === true;
      return isHelpButtonAvailable ? (
        <div className={[
          widgetStyles.orderDetailsLine,
          widgetStyles.orderDetailsLineTitle,
          widgetStyles.orderDetailsWithoutSubtitle,
          widgetStyles.borderBottom,
        ].join(" ")}>
          <div className={widgetStyles.contentCell}>
            <div className={widgetStyles.orderDetailsTitle}>Need help with this order?</div>
          </div>
          <div className={[widgetStyles.buttonCell].join(' ')}>
            <button type="button"
              className={[widgetStyles.helpButton, `bg-dark dark:bg-button text-white py-4 flex items-center justify-center`].join(' ')}
              onClick={ this.goToHelpPage }
              >Help</button>
          </div>
        </div>
      ) : null;
    }

    onOrderExperienceRatingSubmited = (data) => {
      const { setModalObject } = this.props;
      const { order } = this.state;
      let { orderExperiences } = order;
      orderExperiences = orderExperiences || [];

      this.setState({
        showRatingModal: false,
      });
      setModalObject({
        title: 'Thank you for your feedback!',
        description: 'Your suggestions help us improve the experience for all Insomniacs.',
      });
      this.setState({
        postOrderRating: data,
        order: {...order, orderExperiences: [...orderExperiences, data]},
      });
    }

    onRatingModalClose = () => {
      const { order, postOrderRating } = this.state;
      const postOrderRatingFromOrder = getOrderExperienceBySource(order, orderExperienceSources.ORDER_DETAILS) || null;
      this.setState({
        postOrderRating: {...postOrderRating, rating: postOrderRatingFromOrder?.rating || null},
        showRatingModal: false,
      });
    }

    renderRatingModal = () => {
      const { showRatingModal, postOrderRating, order } = this.state;
      return showRatingModal ? (
        <SimpleModal>
          <div className={[
            modalStyles.simpleModalContainer,
            'dark:bg-dark',
          ].join(' ')}>
            <div className={[
              widgetStyles.orderDetailsLine,
              widgetStyles.orderDetailsLineTitle,
              widgetStyles.borderBottom
            ].join(" ")}>
              { getOrderExperienceRatingModalTitleAndSubtitle(order) }
              <div className={modalStyles.closeWrapper}>
                <img className={`dark:image-negative`} onClick={this.onRatingModalClose} src={ExitIconSVG} alt="Close" />
              </div>
            </div>
          </div>
          <SmileRatingWidget
            defaultData={postOrderRating}
            orderCode={order.code}
            className={modalStyles.simpleModalContainer}
            source={orderExperienceSources.ORDER_DETAILS}
            onSubmited={this.onOrderExperienceRatingSubmited}/>
        </SimpleModal>
      ) : null;
    }

    handleOnOrderUpdated = (updatedOrder) => {
      const { setOrderExperiences } = this.props;
      const {order} = this.state;
      const resultOrder = {
        ...order,
        ...updatedOrder,
      };
      setOrderExperiences(resultOrder.orderExperiences || []);
      this.setState({
        order: resultOrder,
      });
    }

    renderOrderSubHeader = () => {
        const { order } = this.state;
        const { orderTypeId, store, shippingAddress } = order;

        if (order.isCancelled) {
          return (<p>Cancelled</p>);
        }

        if (order.isRefunded) {
          return (<p>Refunded</p>);
        }

        const subHeaderByOrderTypeId = {};

        subHeaderByOrderTypeId[orderTypeIds.delivery] = () => {
          if (this.orderInProgress()) {
            return null;
          }

          return (
            <p>
              Delivered
              <span>
              to
                {' '}
                {shippingAddress.address1}
              </span>
            </p>
          );
        }

        subHeaderByOrderTypeId[orderTypeIds.pickup] = () => {
          if (this.orderInProgress()) {
            return null;
          }

          return (
            <p>
              Picked up
              <span>
                at
                {' '}
                {store.address}
              </span>
            </p>
          );
        }

        subHeaderByOrderTypeId[orderTypeIds.shipping] = () => (
          <p>
            { this.orderInProgress() ? 'Ship' : 'Shipped' }
            <span>
              to
              {' '}
              {shippingAddress.address1}
            </span>
          </p>
        );

        subHeaderByOrderTypeId[orderTypeIds.eGiftCard] = () => (
          <p>
            Gift card
          </p>
        )

        return subHeaderByOrderTypeId[orderTypeId]();
    };

    renderTrackingWidget = () => {
      const { order } = this.state;

      return (
        <div className={styles.trackingMapContainer}>
          <div className={styles.trackingMap}>
            <OrderTrackingWidget order={order} mode={orderTrackingWidgetModes.orderDetails} internalAutoupdate>
              { this.renderYourExperienceRating() }
              { this.renderNeedHelpSection() }
              <OrderDetailsWidget order={order} onOrderUpdated={this.handleOnOrderUpdated} />
            </OrderTrackingWidget>
          </div>
          <div className={styles.orderTotals}>
            { isMobileScreen() ? null : <DropoffImageWidget order={order} />}
            <OrderTotalsWidget order={order} />
          </div>
        </div>
      );
    }

    render() {
        const { loading, order } = this.state;

        if (loading) {
            return (
              <div className={styles.loaderWrapper}>
                <Loader />
              </div>
            );
        }

        return (
          <div className={`${styles.pageWrapper} text-dark dark:text-white mb-16`}>
            <div className={`${styles.pageContainer} max-w-screen-desktop`}>
              <h1>Order Details</h1>

              {this.renderOrderSubHeader()}
              <span>
                Order #
                {order.id}
              </span>
              { isMobileScreen() ? <DropoffImageWidget order={order} /> : null }
              { this.renderTrackingWidget() }
              { this.renderRatingModal() }
            </div>
          </div>
        );
    }
}

OrderDetails.propTypes = {
  setModalObject: PropTypes.func,
  setOrderExperiences: PropTypes.func,
  isFeedbackFormsEnabled: PropTypes.bool,
  orderExperiences: PropTypes.arrayOf(PropTypes.shape({
    rating: PropTypes.number,
    source: PropTypes.string,
  })),
};

OrderDetails.defaultProps = {
  setModalObject: () => {},
  setOrderExperiences: () => {},
  isFeedbackFormsEnabled: false,
  orderExperiences: [],
};

export const mapDispatchToProps = dispatch => ({
  setModalObject: value => dispatch(elementsActions.setModalObject(value)),
  setOrderExperiences: value => dispatch(orderExperiencesActions.setOrderExperiences(value)),
});

export const mapStateToProps = (state) => {
  const { isFeedbackFormsEnabled } = state.siteSettings;
  const { orderExperiences } = state;

  return {
    isFeedbackFormsEnabled,
    orderExperiences: orderExperiences || [],
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDetails));
