/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import * as _ from "underscore";
import endpoints from '../constants/endpoints';
import {orderMethods} from "../constants/order";
// eslint-disable-next-line import/no-cycle
import HeaderSection from '../../components/Header/HeaderSection';
// eslint-disable-next-line import/no-cycle
import FooterSection from '../../components/Footer/FooterSection';
import styles from  "../../pages/Product/CustomizeProduct/CustomizeProduct.module.css";

const darkBackground = require('../../pages/PageContainer/imgs/dark-bg.svg');
const igIcon = require('../../components/Footer/imgs/ig-icon.png');
const fbIcon = require('../../components/Footer/imgs/fb-icon.png');
const ttIcon = require('../../components/Footer/imgs/tiktok-icon.png');

export const returnUserOrderMethod = userOrderMethod => {
  let copy;
  switch (userOrderMethod) {
  case 'DELIVERY':
    copy = 'Delivering to';
    break;
  case 'SHIPPING':
    copy = 'Shipping to';
    break;
  case 'PICKUP':
    copy = 'Picking up from';
    break;
  case orderMethods.eGiftCard:
    copy = 'Email Gift Card';
    break;
  default:
    copy = 'Delivering to';
  }
  return copy;
};

export const renderBackground = () => {
  const {pathname} = window.location;
  const background =
        pathname === '/create-account' ||
        pathname.includes('/login') ||
        pathname.includes('/reset-password') ||
        pathname.includes('/darkBackground') ||
        pathname.includes('/terms') ||
        pathname.includes(endpoints.cookielabTerms) ||
        pathname.includes(endpoints.locations) ||
        pathname.includes('/user/order-tracker') ||
        pathname.includes('/privacy-policy') ||
        pathname.includes('/faq') ||
        pathname.includes('/accessibility') ||
        pathname.includes('/about') ||
        pathname.includes('/countries') ||
        pathname.includes('/allergen-and-nutrition-info') ||
        new RegExp(`^(/([a-z]{2})?)?${endpoints.createAccount}$`).test(pathname) ||
        new RegExp(`^(/([a-z]{2})?)?${endpoints.doughProFreeCookiePage}$`).test(pathname) ||
        new RegExp(`^(/([a-z]{2})?)?${endpoints.doughProPage}$`).test(pathname) ||
        new RegExp(`^(/([a-z]{2})?)?${endpoints.doughProTermsConditions}$`).test(pathname)
          ? darkBackground
          : null;

  if (pathname.includes('/404')) {
    return null;
  }

  return <img alt="background" src={background} className='absolute top-0 max-w-none' />;
};

export const returnTheme = () => {
  const {pathname} = window.location;
  const theme =
        pathname.includes('/create-account') ||
        pathname.includes('/login') ||
        pathname.includes('/reset-password') ||
        pathname.includes('/change-password') ||
        pathname.includes('/terms') ||
        pathname.includes(endpoints.cookielabTerms) ||
        pathname.includes(endpoints.locations) ||
        pathname.includes('/user/order-tracker') ||
        pathname.includes('/user/order-tracker-step') ||
        pathname.includes('/user/profile') ||
        pathname.includes('/checkout/gift') ||
        pathname.includes('/checkout/confirmation') ||
        pathname.includes('/privacy-policy') ||
        pathname.includes('/faq') ||
        pathname.includes('/accessibility') ||
        pathname.includes('/about') ||
        pathname.includes('/loyalty') ||
        pathname.includes('/events') ||
        pathname.includes('/menu') ||
        pathname === '/' || /^\/[a-z]{2}\/?$/.test(pathname) ||
        pathname.includes('/products') ||
        pathname.includes('/shop-gift-cards') ||
        pathname.includes('/countries') ||
        pathname.includes('/allergen-and-nutrition-info') ||
        pathname.includes(endpoints.doughProFreeCookiePage) ||
        pathname === endpoints.doughProPage ||
        pathname === endpoints.doughProTermsConditions ||
        pathname === endpoints.cookieMagicGiftCardPurchase ||
        new RegExp(`^[/][a-z]{2}\\${endpoints.doughProPage}$`).test(pathname) ||
        new RegExp(`^[/][a-z]{2}\\${endpoints.doughProTermsConditions}$`).test(pathname) ||
        new RegExp(`^[/][a-z]{2}\\${endpoints.cookieMagicGiftCardPurchase}$`).test(pathname)
          ? 'dark'
          : 'light';
  return theme;
};

export const renderGlobalHeader = (pathname, topHeaderOffsetDivHeight, hasError) => {
  return (
    <HeaderSection
      pathname={pathname}
      theme={returnTheme(pathname)}
      hasError={hasError}
      topHeaderOffsetDivHeight={topHeaderOffsetDivHeight}
    />
  );
};

export const renderGlobalFooter = (pathname) => {
  if (!pathname.includes(endpoints.locations) && !pathname.includes('/404'))
    return <FooterSection pathname={pathname} />;
  return null;
};

export const getOptionTotal = (product, customizeProducts) => {
  let price = 0;
  if (customizeProducts) {
    const keys = Object.keys(customizeProducts);
    keys.forEach(key => {
      const productOptId = parseInt(key, 10);
      // eslint-disable-next-line
      product.options?.forEach(option => {
        const optionId = parseInt(option.id, 10);
        if (optionId === productOptId) {
          customizeProducts[key].forEach(custom => {
            option.options.forEach(opt => {
              if (opt.id === custom) {
                price += opt.price
              }
            });
          })
        }
      })
    })
  }
  return price;
};

export const getTotalPrice = (item) => {
  let totals = item.product.price || item.price;
  if (item.productOptions) {
    item.productOptions.forEach(option => {
      if (option.productOption) {
        if (option.productOption.price > 0) {
          totals += option.productOption.price * option.quantity;
        }
      }
    });
  }
  return totals;
};

export const getBarcodeId = (id) => {
  return `;${id}?`;
};

export const getOrderTotals = (item) => {
  let totals = item.price;
  if (totals) {
    if (item.productOptions) {
      item.productOptions.forEach(option => {
        if (option.optionPrice && option.optionPrice > 0) {
          totals += option.optionPrice;
        }
      });
    }
    return totals;
  }

  return getTotalPrice(item);

}

export const getSelectedOptions = (product, selectedItem) => {
  let opt1 = null;
  if (selectedItem) {
    // lets predefine the state due to preseelection
    for (let optionsOg = 0; optionsOg < product.options.length; optionsOg += 1) {
      const selection = [];
      for (let cnt = 0; cnt < selectedItem.productOptions.length; cnt += 1) {
        if (selectedItem.productOptions[cnt].groupOptionId === parseInt(product.options[optionsOg].id, 10)) {
          for (let pdOp = 0; pdOp < product.options[optionsOg].options.length; pdOp += 1) {
            if (product.options[optionsOg].options[pdOp].id === selectedItem.productOptions[cnt].options.id) {
              for (let count = 0; count < selectedItem.productOptions[cnt].quantity; count += 1) {
                selection.push(selectedItem.productOptions[cnt].options.id);
              }
            }
          }
        }
      }
      if (opt1 == null) {
        opt1 = {};
      }
      opt1[product.options[optionsOg].id] = selection;
    }
  }
  return opt1;
};

export const checkSelectedOptions = (product, selectedOptions) => {
  const options = {};
  const optionsPopulated = selectedOptions && !_.isEmpty(selectedOptions);
  if (optionsPopulated) {
    Object.entries(selectedOptions).forEach(([key, values]) => {
      product.options.forEach(option => {
        option.options.forEach(opt => {
          values.forEach(id => {
            if (id === opt.id && option.id === key) {
              if (options[option.id]) {
                options[option.id].push(id);
              } else {
                options[option.id] = [id];
              }
            }
          })
        })
      })
    });
  }
  return options;
};

export const getBoxProducts = (product, selectedOptions) => {
  const opts = product.options.filter(option => {
    return ['PRIMARY', 'SELECT_LARGE', 'SELECT_SMALL'].includes(option.optionGroupType);
  }).map(item => item.options.map((e) => ({
    ...e,
    optional: item.minOptions < 1
  }))).reduce((a, b) => a.concat(b));

  const boxProducts = {};

  Object.values(selectedOptions).forEach((value) => {
    value.forEach(optionId => {
      const selectedProduct = opts.find(item => item.id === optionId) || null;
      if (!selectedProduct) {
        return;
      }
      if (boxProducts[optionId]) {
        boxProducts[optionId].push(selectedProduct);
      } else {
        boxProducts[optionId] = [selectedProduct];
      }
    });
  });
  return boxProducts;
};

export const renderSocialLinksSection = (renderClassName, settings = {tiktokLink: '', instagramLink: '', facebookLink: ''}) => {
  return (
    <div className={renderClassName}>
      {settings.tiktokLink && settings.tiktokLink !== '' ?
        (
          <a
            href={settings.tiktokLink}
            target="_blank"
            rel="noopener noreferrer"
            aria-label='TikTok'
          >
            <img alt="TikTok" src={ttIcon} />
          </a>
) : null}
      {settings.instagramLink && settings.instagramLink !== '' ?
        (
          <a
            href={settings.instagramLink}
            target="_blank"
            rel="noopener noreferrer"
            aria-label='Instagram'
          >
            <img alt="Instagram" src={igIcon} />
          </a>
) : null}
      {settings.facebookLink && settings.facebookLink !== '' ?
        (
          <a
            href={settings.facebookLink}
            target="_blank"
            rel="noopener noreferrer"
            aria-label='Facebook'
          >
            <img alt="Facebook" src={fbIcon} />
          </a>
) : null}
    </div>
  )
};

export const renderLoyaltyPrice = (component) => {
  const { currency, loyalty } = component.props;
  const { product } = component.state;
  return Boolean(loyalty) && product.price > 0 ? (
    <span className={`${styles.loyaltyPrice}`}>
      {currency.symbol}
      {parseFloat(product.price).toFixed(2)}
    </span>
  ) : null;
}