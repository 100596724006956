/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// REDUX

import ProductsCategoryFilter from '../ProductsCategoryFilter/ProductsCategoryFilter';

// HELPERS
import { MOBILE_SIZE } from "../../shared/constants/size";

import styles from './ProductsHeader.module.css';
import stylesHeroBanner from '../ContentCards/Carousel/Templates/HeroBanner/HeroPromoContentCardTemplate.module.css';
import * as elementsActions from "../../redux/actions/elements";
import DeliveryPickUpWidget from "../DeliveryPickUpWidget/DeliveryPickUpWidget";

// CONSTANTS
import { modalBox } from '../../shared/constants/refs';
import ContentCardCarousel from "../ContentCards/Carousel/ContentCardCarousel";
import * as contentCardConstants from "../../shared/constants/contentCards";
import HeroBannerIntro from "../ContentCards/Carousel/Templates/HeroBannerIntro/HeroBannerIntro";


const REDUCE_TIMEOUT_OFFSET = 500;

class ProductsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chosenItem: 'Boxes',
      isMobile: false,
      choseItemToggle: false,
    };
    this.productMenuContainerRef = React.createRef();
  }

  componentDidMount() {
    this.updateSize();
    window.addEventListener('resize', this.updateSize);
  }

  componentDidUpdate(prevProps, prevState) {

    const { setProductCategory } = this.props;
    const { choseItemToggle } = this.state;

    if (choseItemToggle === prevState.choseItemToggle) {
      setProductCategory("");
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize);
  }

  updateSize = () => {
    const width = window.innerWidth;
    if(width <= MOBILE_SIZE.width){
      this.setState(
        { isMobile: true }
      );
    }else{
      this.setState(
        { isMobile: false }
      );
    }
  }

  mobileToggleWidget = e => {
    const { toggleHeaderWidget } = this.props;

    e.stopPropagation();
    toggleHeaderWidget();
  }

  scrollToProducts = () =>{
    if(this.productMenuContainerRef.current){
      const menuContainer = this.productMenuContainerRef.current;
      const top = menuContainer.offsetHeight - 50;
      window.scrollTo({ top: top, left: 0, behavior: 'smooth'}); // eslint-disable-line
    }
  }

  productsMenuContainer = () => {
    const {
      showHeaderWidget,
      shouldDisplayIntroBanner,
      contentCardRefreshDelay,
      floatMenuHide,
      openDeliveryWidget
    } = this.props;
    
    const {
      isMobile,
      chosenItem,
      choseItemToggle
    } = this.state;

    const cardCarousel = (
      <ContentCardCarousel
        templates={[contentCardConstants.HERO_PROMO_TEMPLATE, contentCardConstants.HERO_INTRO_PROMO_TEMPLATE]}
        carouselControlStyles={stylesHeroBanner}
        delay={contentCardRefreshDelay+REDUCE_TIMEOUT_OFFSET}
        openDeliveryWidget={openDeliveryWidget}
      />
    );

    const heroBannerIntro = (
      <HeroBannerIntro />
    );

    const bannerContent = shouldDisplayIntroBanner ? heroBannerIntro : cardCarousel;

    return (
      <div ref={this.productMenuContainerRef} className='flex flex-col gap-9 w-full border-0 rounded-none bg-transparent'>
        <div className={styles.heroBannerContainer}>
          {bannerContent}
        </div>
        {/* <div className={styles.productsHeaderContainer} /> */}
        {/* BANNER CONTAINER */}
        <div className={`${styles.productHeaderBanner} ${floatMenuHide ? 'hidden' : 'block'}`}>

          {/* MOBILE ADDRESS FINDER */}          
          <div className={`${showHeaderWidget && isMobile ? null : styles.categoriesWrapper} 
            max-w-screen-desktop border-0 rounded-none bg-transparent mx-auto md:px-0 px-4`}
          >
            {!isMobile || (!showHeaderWidget && isMobile) ? (
              <ProductsCategoryFilter
                onChange={(product) => this.setState({ chosenItem: product , choseItemToggle: !choseItemToggle})}
                chosenItem={chosenItem}
              />
            ) : null}
          </div>
        </div>
        {showHeaderWidget && isMobile ? (
          <div className={styles.widgetWrapper} ref={modalBox}>
            <DeliveryPickUpWidget />
          </div>
        ) : null}
      </div>
    );
  };

  render() {
    return <this.productsMenuContainer />;
  }
}

ProductsMenu.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  selectedStore: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  toggleHeaderWidget: PropTypes.func,
  showHeaderWidget: PropTypes.bool,
  setProductCategory: PropTypes.func.isRequired,
  shouldDisplayIntroBanner: PropTypes.bool.isRequired,
  contentCardRefreshDelay: PropTypes.number.isRequired,
  floatMenuHide:PropTypes.string,
  openDeliveryWidget: PropTypes.func
};

ProductsMenu.defaultProps = {
  selectedStore:  null,
  toggleHeaderWidget: null,
  showHeaderWidget: null,
  floatMenuHide:null,
  openDeliveryWidget: null
};

export const mapStateToProps = (state) => {
  const {
    showHeaderWidget,
  } = state.elements;
  const {
    userOrderMethod,
    userAddress,
    selectedStore,
    userToken,
    orderCompleted
  } = state.user;

  const {
    shouldDisplayIntroBanner,
    contentCardRefreshDelay,
  } = state.braze;
  return {
    showHeaderWidget,
    userOrderMethod,
    userAddress,
    selectedStore,
    userToken,
    orderCompleted,
    shouldDisplayIntroBanner,
    contentCardRefreshDelay,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  toggleHeaderWidget: () => dispatch(elementsActions.toggleHeaderWidget()),
  setProductCategory: (value) => dispatch(elementsActions.setProductCategory(value))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsMenu));
