import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Collections from "../../components/Homepage/Collections/Collections";
import Delivery from "../../components/Homepage/Delivery/Delivery";
import Offerings from "../../components/Homepage/Offerings/Offerings";
import Promotions from "../../components/Homepage/Promotions/Promotions";
import ActiveOrders from "../../components/ActiveOrders/ActiveOrders";

import ProductsHeader from "../../components/ProductsHeader/ProductsHeader";
import endpoints from "../../shared/constants/endpoints";
import * as addressActions from "../../redux/actions/address";
import * as cms from "../../shared/repos/cms/cms";
import cmsKeys from "../../shared/constants/cms";
import Loader from "../../components/Loader/Loader";
import { getContentsByKey } from "../../shared/utilities/common";

import styles from "./Homepage.module.css";
import Banner from "../../components/Homepage/Banner/Banner";

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: [],
      loading: true,
      showDeliveryWidget: false
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    cms.getContent([cmsKeys.homePage], this);
  };

  openDeliveryWidget = (hash = null) => {
    const { userAddress, history, setRedirectUrl } = this.props;
    setRedirectUrl(hash);
    if (!userAddress) {
      this.setState({
        showDeliveryWidget: true
      });
    } else {
      const url = hash ? `${endpoints.menu}${hash}` : endpoints.menu;
      
      history.push(url);
    }
  };

  setShowDeliveryWidget = (show = false) => {
    this.setState({
      showDeliveryWidget: show
    });
  };

  render() {
    const { content, loading, showDeliveryWidget } = this.state;
    const { userToken, cmsEditMode } = this.props;

    if (!content.length && !loading) {
      return <Loader />;
    }

    const components = content[0]?.children;
    const collections = getContentsByKey(components, "collections");
    const banner = getContentsByKey(components, "banner");
    const offers = getContentsByKey(components, "offers");
    const promotions = getContentsByKey(components, "promotions");

    return (
      <div
        className={`
        w-full flex flex-col gap-8 items-center pb-36 mx-auto dark:bg-gradient-bl dark:bg-gradient-to-br dark:from-darkBlack dark:to-darkBlack
        `}
      >
        {userToken && (
          <div className={styles.activeOrdersWrapper}>
            <ActiveOrders />
          </div>
        )}
        <div className="dark:bg-dark w-full">
          <div className="lg:max-w-screen-desktop w-full flex flex-col gap-5  p-0 border-0 mx-auto">
            <Delivery
              showWidget={showDeliveryWidget}
              setShowDeliveryWidget={this.setShowDeliveryWidget}
            />
            <div className="w-full">
              <ProductsHeader
                floatMenuHide="true"
                openDeliveryWidget={this.openDeliveryWidget}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col items-center">
          <div className="w-full dark:bg-dark p-0 m-0 border-0 flex flex-col gap-11">
            <Collections
              contents={collections}
              openDeliveryWidget={this.openDeliveryWidget}
            />
            <div className="w-full dark:bg-gradient-to-b dark:from-darkBlack dark:to-dark bg-white border-0">
              <Banner
                contents={banner}
                openDeliveryWidget={this.openDeliveryWidget}
                cmsEditMode={cmsEditMode}
              />
              <Offerings
                openDeliveryWidget={this.openDeliveryWidget}
                contents={offers}
              />
            </div>
          </div>
          <Promotions contents={promotions} />
        </div>
      </div>
    );
  }
}

Homepage.propTypes = {
  userAddress: PropTypes.shape({}),
  userToken: PropTypes.string || null,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  setRedirectUrl: PropTypes.string,
  selectedCountry: PropTypes.shape({}),
  cmsEditMode: PropTypes.bool
};

Homepage.defaultProps = {
  userAddress: null,
  userToken: null,
  setRedirectUrl: "",
  selectedCountry: null,
  cmsEditMode: false
};

export const mapStateToProps = state => {
  const { userAddress, userToken, cmsEditMode } = state.user;
  const { selectedCountry } = state.country;
  return { userAddress, userToken, selectedCountry, cmsEditMode };
};

export const mapDispatchToProps = dispatch => ({
  setRedirectUrl: value => dispatch(addressActions.setRedirectUrl(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
