import React, { Component } from "react";
import PropTypes from "prop-types";
import { isMobile, isTablet } from "react-device-detect";

import styles from "./LoyaltyReward.module.css";

import * as loyaltyRepo from "../../shared/repos/graphql/loyalty";
import * as notificationRepo from "../../shared/repos/graphql/customerNotifications";
import CookieChallenge from "./CookieChallenge";

const mapPin = require("./imgs/map-pin.svg");

class LoyaltyPointTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loyaltyMetrics: [],
      notifications: [],
      hasChallenge: false,
      isDragging: false, // To track whether dragging is active
      startX: 0, // To track the initial X position
      activeBtn: 'left',
      marginLeft: 45, // Initial margin-left value
    };
    this.handleResize = this.handleResize.bind(this);
    this.handleLeftButtonClick = this.handleLeftButtonClick.bind(this);
    this.handleRightButtonClick = this.handleRightButtonClick.bind(this);
  }


  async componentDidMount() {
    await this.getLoyaltyMetrics();
    await this.getNotifications();
    await this.readNotifications();
    this.handleResize(); // Initial check
    window.addEventListener('resize', this.handleResize);
  }

  // Remove event listener when the component is unmounted
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getNotifications = async () => {
    const notifications = await notificationRepo.getNotifications("campaign");

    this.setState({
      notifications: notifications?.data?.getNotifications
    });
  };

  readNotifications = async () => {
    const { notifications } = this.state;
    if(notifications?.length > 0) {
      const notificationIds = notifications.map(n => parseInt(n.id, 10));

      await notificationRepo.readNotifications(notificationIds);
    }
  };

  getLoyaltyMetrics = async () => {
    const response = await loyaltyRepo.getLoyaltyMetrics();
    this.setState({
      loyaltyMetrics: response.data.loyaltyMetrics
    });
  };

  handleChallengeLoaded = (hasChallenge) => {
    this.setState({ hasChallenge });
    this.getMarginValue();
  };

  getMarginValue = () => {
    const { hasChallenge } = this.state;
    if (!isMobile || !hasChallenge) return 0;
    return isTablet ? 45 : 90;
  };

  handleButtonClick = (direction) => {
    const marginLeft = direction === "left" ? this.getMarginValue() : -this.getMarginValue();
    this.setState({ marginLeft, activeBtn: direction });
  };

  handleLeftButtonClick = () => this.handleButtonClick("left");

  handleRightButtonClick = () => this.handleButtonClick("right");

  handleDragStart = (e) => {
    this.setState({
      isDragging: true,
      startX: e.type === 'touchstart' ? e.touches[0].clientX : e.clientX,
    });
  };

  handleDragMove = (e) => {
    const { isDragging, startX } = this.state;
    if (!isDragging) return;

    const clientX = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;
    const deltaX = clientX - startX;

    if (Math.abs(deltaX) > 5) {
      const direction = deltaX > 0 ? "left" : "right";
      this.handleButtonClick(direction);
    }
  };

  handleDragEnd = () => {
    this.setState({ isDragging: false });
  };

  handleResize = () => {
    this.setState({ marginLeft: this.getMarginValue() });
  };

  render() {
    const { points, redeemables } = this.props;
    const { loyaltyMetrics, marginLeft, activeBtn, notifications, hasChallenge } = this.state;

    const noRedeemables = !redeemables || redeemables.length <= 0;

    if (noRedeemables) {
      return null;
    }

    let pointsProgress = 0;
    const barProgress = 327 / redeemables.length / 2;

    if (points > redeemables[redeemables.length - 1].points) {
      pointsProgress = 326;
    } else {
      redeemables.forEach((item, index) => {
        const level = index + 1;
        if (!pointsProgress) {
          const userHasPointsLessThenLvlPoints =
            points > 0 && points <= item.points;
          if (userHasPointsLessThenLvlPoints) {
            if (index === 0) {
              pointsProgress = (points / item.points) * barProgress;
            } else {
              pointsProgress =
                ((points - (item.points - redeemables[0].points * level)) /
                  redeemables[0].points) *
                barProgress +
                barProgress * index;
            }
          }
        }
      });
    }

    const notificationMessage = notifications?.length > 0 ? `+${(notifications?.length * 100)} for Referral` : '';

    return (
      <div className={`${styles.topContainer} py-4`}>
        <p
          className="lg:hidden w-[375px] h-[84px] text-white
              py-5 px-4 font-filsonProBold font-bold text-2xl leading-6 tracking-[-0.3px]"
        >
          Rewards
        </p>
        <div
          role="none"
          className="overflow-hidden"
          onMouseMove={this.handleDragMove} // Track mouse movement
          onMouseUp={this.handleDragEnd} // Stop dragging on mouse up
          onMouseLeave={this.handleDragEnd} // Stop dragging if mouse leaves the area
          onTouchMove={this.handleDragMove} // Track touch movement
          onTouchEnd={this.handleDragEnd} // Stop dragging on touch end
          onTouchCancel={this.handleDragEnd} // Stop dragging on touch cancel
        >
          <div
            className="flex justify-center items-start gap-2 lg:ml-0 transition-all duration-300 ease-in-out lg:transition-none"
            style={{ marginLeft: `${marginLeft}%` }}
            role="none"
            onMouseDown={marginLeft !== 0 && this.handleDragStart}
            onTouchStart={marginLeft !== 0 && this.handleDragStart}
          >
            <CookieChallenge onChallengeLoaded={this.handleChallengeLoaded} />
            <div className="flex flex-col justify-center items-center">
              <div className={`${styles.loyaltyPoints} bg-cover relative`}>
                <div className="font-congenialBlack text-4xl leading-9 tracking-[-1px] text-white p-5">
                  <div className="lg:flex">
                    <span className="hidden lg:block mr-2">You have</span>
                    <span>
                      {points}
                      <small className="!font-filsonProRegular text-xl leading-[22px] tracking-[-0.3px]">
                        &nbsp;pts
                      </small>
                    </span>
                  </div>

                  <p className="!text-[#B4A5BB] !leading-5 !text-base !font-[425] !font-filsonProRegular lg:hidden">
                    Rewards Points
                  </p>
                </div>
                <div>
                  <p className="text-dryPink">{notificationMessage}</p>
                </div>
                <div className="flex items-center flex-col justify-center px-2 pb-3 mt-8 absolute">
                  <div className="mb-2 w-[327px]">
                    <img
                      src={mapPin}
                      alt={points}
                      style={{ marginLeft: `${pointsProgress - 4}px` }}
                    />
                  </div>
                  <div className="w-[327px] border-2 border-disabled rounded-[40px]">
                    <div
                      className="border-2 border-glow transition-all w-0 duration-500 ease-in-out"
                      style={{ width: `${pointsProgress}px` }}
                    />
                  </div>
                  <div className="flex justify-between items-center relative top-[-9px]  w-[327px]">
                    {redeemables.map(item => (
                      <p
                        className={` ${points >= item.points ? "bg-glow" : "bg-dark"
                          }
                        w-[14px] h-[14px] rounded-full 'border-disabled' border-2 mx-auto`}
                      />
                    ))}
                  </div>
                  <div
                    className="flex justify-between items-center
            absolute mt-11 w-[327px] text-white font-filsonProRegular font-[425] text-base leading-5"
                  >
                    {redeemables.map(item => (
                      <p className="mx-auto">{item.points}</p>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex gap-2 mt-5">
                {loyaltyMetrics.length > 0 &&
                  loyaltyMetrics.map(metrics => {
                    return (
                      <div className={styles.loyaltyPointsCards}>
                        <p>{metrics.value}</p>
                        <p>{metrics.title}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          {hasChallenge ?? (
            <div className="flex justify-center items-center space-x-2 mt-5 lg:hidden">
              <button type="button" className={`w-5 h-5 rounded-full ${activeBtn === 'left' ? 'bg-white' : 'bg-gray-400'}`} onClick={this.handleLeftButtonClick}>{ }</button>
              <button type="button" className={`w-5 h-5 rounded-full ${activeBtn === 'right' ? 'bg-white' : 'bg-gray-400'}`} onClick={this.handleRightButtonClick}>{ }</button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

LoyaltyPointTracker.propTypes = {
  points: PropTypes.number,
  redeemables: PropTypes.shape()
};

LoyaltyPointTracker.defaultProps = {
  points: null,
  redeemables: null
};

export default LoyaltyPointTracker;
