import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import _ from "underscore";
import { hideBigOrderWarning } from "../../redux/actions/alert";
import Modal from "../Modal/Modal";
import * as cms from "../../shared/repos/cms/cms";
import cmsKeys from "../../shared/constants/cms";

const BigOrderWarningModal = () => {
  const [content, setContent] = useState([]);

  const dispatch = useDispatch();
  const showBigOrderWarning = useSelector(state => state.alert.showBigOrderWarning);

  useEffect(() => {
    const fetchContent = async () => {
      await cms.getContent(cmsKeys.bigOrderWarning, (newState) => {
        setContent(newState.content);
      });
    };

    fetchContent();
  }, []);

  if (!showBigOrderWarning) return null;

  const bigOrderWarningTitle = _.find(content, {key: cmsKeys.bigOrderWarning.title });
  const bigOrderWarningDescription = _.find(content, {key: cmsKeys.bigOrderWarning.description });

  return (
    <Modal
      title={bigOrderWarningTitle?.value}
      titleCMSNode={bigOrderWarningTitle}
      description={bigOrderWarningDescription?.value}
      descriptionCMSNode={bigOrderWarningDescription}
      action1Label='Continue'
      action1={() => dispatch(hideBigOrderWarning())}
      cmsPreEdit={() => dispatch(hideBigOrderWarning())}
      exit={false}
    />
  );
};

export default BigOrderWarningModal;
